import {
  AddOutlined,
  PageDS,
  Spacer,
  TabMenu,
  ToastContainer,
  triggerToast,
} from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AnalyticsVehiclesTab } from "./analyticsVehiclesTab";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  driverPlanningsSlice,
  selectDriverPlanningsStatus,
} from "../trips/driverPlanningsSlice";
import { ImportDriverPlanningsModal } from "../trips/importDriverPlanningsModal";
import { HistoryTab } from "../trips/historyTab";

export const Fleet = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams();

  const [selectedTab, setSelectedTab] = useState<string>(
    params.tab ?? "analytics",
  );
  const driverPlanningsStatus = useAppSelector(selectDriverPlanningsStatus);
  const [isModalPlanningVisible, setIsModalPlanningVisible] = useState(false);

  const tabs = [
    {
      name: "analytics",
      title: t("fleet.tab.analytics"),
    },
    {
      name: "history",
      title: t("fleet.tab.tripsHistory"),
    },
  ];

  const cta = {
    title: t("trips.cta"),
    action: () => {
      setIsModalPlanningVisible(true);
    },
    leftIcon: <AddOutlined />,
  };

  useEffect(() => {
    if (driverPlanningsStatus === "success") {
      triggerToast(t("trips.importPlanning.success") || "", "valid");
    } else if (driverPlanningsStatus === "failed") {
      triggerToast(t("trips.importPlanning.failure") || "", "error");
    }
    dispatch(driverPlanningsSlice.actions.resetPlanningUploadStatus());
  }, [dispatch, driverPlanningsStatus, t]);

  useEffect(() => {
    if (selectedTab) {
      navigate(
        `/home/fleet/${selectedTab}${
          params.id !== undefined ? `/${params.id}` : ""
        }`,
      );
    }
  }, [navigate, params.id, selectedTab]);

  return (
    <>
      <ImportDriverPlanningsModal
        visible={isModalPlanningVisible}
        onCloseModal={() => {
          setIsModalPlanningVisible(false);
        }}
      />
      {!isModalPlanningVisible && (
        <PageDS
          title={t("fleet.title")}
          toaster={<ToastContainer />}
          cta={selectedTab === "history" ? cta : undefined}
          topElement={
            <>
              <Spacer y={1} />
              <TabMenu
                tabs={tabs}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
              />
              <Spacer y={0.5} />
            </>
          }
        >
          <AnalyticsVehiclesTab />
          <HistoryTab />
        </PageDS>
      )}
    </>
  );
};
