import { Outlet, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  SideBar,
  CreditCardOutlined,
  HomeOutlined,
  SyncAltOutlined,
  DirectionsCarFilledOutlined,
  GppGoodOutlined,
  PeopleOutlined,
  AccountBalanceWalletOutlined,
  WifiOutlined,
} from "@qivia/ui";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  loadCompanyAsync,
  removeToken,
  selectAllCompanies,
  selectIsTokenDecoded,
  selectManager,
  removeCurrentCompany,
  removeCompany,
  selectCompany,
  getCurrentCompany,
  selectCurrentCompanyUuid,
  selectCurrentCompanyStatus,
  selectCompanyStatus,
} from "./homeSlice";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { selectVehiclesCreatedStatus } from "./vehicles/vehiclesSlice";
import {
  driversAccessRequestsListAsync,
  selectDriverAccessRequestsList,
  selectDriverAccessRequestsListStatus,
} from "./drivers/driversSlice";
import { selectRuleStatus } from "./rules/rulesSlice";
import { selectCardCreationStatus } from "./cards/cardsSlice";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import { stripeSlice } from "../stripe/stripeSlice";
import { sepaDebitSlice } from "./billings/sepaDebitSlice";
import { selectBadgesCreationStatus } from "./badges/badgesSlice";

export const Home = () => {
  const manager = useAppSelector(selectManager);
  const isTokenDecoded = useAppSelector(selectIsTokenDecoded);
  const location = useLocation();
  const initialSelectedRoute = location.pathname.split("/")[2]
    ? location.pathname.split("/")[2]
    : "";
  const [selectedRoute, setSelectedRoute] = useState<string | undefined>(
    initialSelectedRoute,
  );
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const company = useAppSelector(selectCompany);
  const companyStatus = useAppSelector(selectCompanyStatus);
  const currentCompany = useAppSelector(selectCurrentCompanyUuid);
  const currentCompanyStatus = useAppSelector(selectCurrentCompanyStatus);

  useEffect(() => {
    if (initialSelectedRoute !== selectedRoute) {
      setSelectedRoute(initialSelectedRoute);
    }
  }, [initialSelectedRoute, location, selectedRoute]);

  useEffect(() => {
    if (
      initialSelectedRoute === "rule" ||
      initialSelectedRoute === "rule_creation"
    ) {
      setSelectedRoute("rules");
    }
  }, [initialSelectedRoute, location, selectedRoute]);

  useEffect(() => {
    if (isTokenDecoded && !manager) {
      navigate("/login");
    }
  }, [manager, navigate, isTokenDecoded]);

  useEffect(() => {
    if (!company && companyStatus === "idle") {
      dispatch(getCurrentCompany());
    }
  }, [company, companyStatus, dispatch]);

  useEffect(() => {
    if (currentCompanyStatus === "success" && currentCompany) {
      void dispatch(loadCompanyAsync({ uuid: currentCompany }));
    }
  }, [currentCompany, currentCompanyStatus, dispatch, navigate]);

  const onChangeRoute = useCallback(
    (route: string) => {
      setSelectedRoute(route);
      navigate(route);
    },
    [navigate],
  );

  const onChangeCompany = useCallback(
    (companyUuid: string) => {
      void dispatch(loadCompanyAsync({ uuid: companyUuid }));
    },
    [dispatch],
  );

  const onClickCompanyCreation = useCallback(() => {
    dispatch(stripeSlice.actions.resetStripeToken());
    dispatch(removeCompany());
    dispatch(sepaDebitSlice.actions.setConnectSepaMandateSigned(null));
    dispatch(sepaDebitSlice.actions.setPlatformSepaMandateSigned(null));
    navigate("/new_company");
  }, [dispatch, navigate]);

  const logout = useCallback(() => {
    dispatch(removeToken());
    dispatch(removeCurrentCompany());
    navigate("/logout");
  }, [dispatch, navigate]);

  useEffect(() => {
    if (manager && manager.status !== "ACTIVE") {
      logout();
    }
  }, [logout, manager]);

  const vehiclesCreationStatus = useAppSelector(selectVehiclesCreatedStatus);
  const driversAccessRequestsList = useAppSelector(
    selectDriverAccessRequestsList,
  );
  const driversAccessRequestsListStatus = useAppSelector(
    selectDriverAccessRequestsListStatus,
  );
  const ruleCreationStatus = useAppSelector(selectRuleStatus);
  const cardCreationStatus = useAppSelector(selectCardCreationStatus);
  const badgeCreationStatus = useAppSelector(selectBadgesCreationStatus);
  const companies = useAppSelector(selectAllCompanies);

  const [driverAccessRequestCreation, setDriverAccessRequestCreation] =
    useState(false);
  const [vehicleCreation, setVehicleCreation] = useState(false);
  const [ruleCreation, setRuleCreation] = useState(false);
  const [cardCreation, setCardCreation] = useState(false);
  const [badgeCreation, setBadgeCreation] = useState(false);

  useEffect(() => {
    if (selectedRoute && company) {
      void dispatch(driversAccessRequestsListAsync(company.uuid));
    }
  }, [dispatch, selectedRoute, company]);

  useEffect(() => {
    if (driversAccessRequestsListStatus !== "success") return;
    setDriverAccessRequestCreation(driversAccessRequestsList.length > 0);
  }, [driversAccessRequestsList.length, driversAccessRequestsListStatus]);

  useEffect(() => {
    if (ruleCreationStatus === "success" && selectedRoute === "") {
      setRuleCreation(true);
    }
  }, [ruleCreationStatus, selectedRoute]);

  useEffect(() => {
    if (cardCreationStatus === "success" && selectedRoute === "") {
      setCardCreation(true);
    }
  }, [cardCreationStatus, selectedRoute]);

  useEffect(() => {
    if (badgeCreationStatus === "success" && selectedRoute === "") {
      setBadgeCreation(true);
    }
  }, [badgeCreationStatus, selectedRoute]);

  useEffect(() => {
    if (vehiclesCreationStatus === "success" && selectedRoute === "") {
      setVehicleCreation(true);
    }
  }, [selectedRoute, vehiclesCreationStatus]);

  useEffect(() => {
    switch (selectedRoute) {
      case "cards":
        setCardCreation(false);
        return;
      case "drivers":
        setDriverAccessRequestCreation(false);
        return;
      case "vehicles":
        setVehicleCreation(false);
        return;
      case "rules":
        setRuleCreation(false);
        return;
    }
  }, [selectedRoute]);

  const props = {
    sections: [
      {
        route: "",
        iconSrc: <HomeOutlined />,
        title: t("home.menu.home"),
      },
      {
        route: "transactions",
        iconSrc: <SyncAltOutlined />,
        title: t("home.menu.transactions"),
      },
      {
        route: "cards",
        iconSrc: <CreditCardOutlined />,
        newCreation: cardCreation,
        title: t("home.menu.cards"),
      },
      {
        route: "badges",
        iconSrc: <WifiOutlined />,
        newCreation: badgeCreation,
        title: t("home.menu.badges"),
      },
      {
        route: "drivers",
        iconSrc: <PeopleOutlined />,
        newCreation: driverAccessRequestCreation,
        title: t("home.menu.drivers"),
      },
      {
        route: "vehicles",
        iconSrc: <DirectionsCarFilledOutlined />,
        newCreation: vehicleCreation,
        title: t("home.menu.vehicles"),
      },
      {
        route: "fleet",
        iconSrc: <DirectionsCarFilledOutlined />,
        title: t("home.menu.fleet"),
      },
      {
        route: "rules",
        iconSrc: <GppGoodOutlined />,
        newCreation: ruleCreation,
        title: t("home.menu.rules"),
      },
      {
        route: "billings",
        iconSrc: <AccountBalanceWalletOutlined />,
        title: t("home.menu.billings"),
      },
    ],
    route: "users",
    selectedRoute,
    onChangeRoute,
    logout,
    companies,
    currentCompany: company?.name ?? "",
    onChangeCompany,
    onClickCompanyCreation,
    hasAnalyticsAccess: company?.hasAnalytics ?? false,
    canOrderBadges: company?.canOrderBadges ?? false,
  };

  return (
    <StyledContainer>
      <SideBar {...props} />
      <Outlet />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  height: 100%;
  background-color: ${colors["colors/accent/900"]};
`;
