import { Spacer, TextCapitalized } from "@qivia/ui";
import { typographies } from "@qivia/ui/src/styles/figmaTypographies";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

export const EmptyChart = () => {
  const { t } = useTranslation();

  return (
    <StyledEmptyCard>
      <Spacer y={1} />
      <StyledCardTitle>
        <TextCapitalized>{t("dashboard.charts.title.empty")}</TextCapitalized>
      </StyledCardTitle>
    </StyledEmptyCard>
  );
};

const StyledCardTitle = styled.div`
  ${typographies["Body/M"]}
`;
const StyledEmptyCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
