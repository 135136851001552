import {
  dateFormatterDayMonthLongYearAndHourMinute2Digits,
  dateFormatterDayMonthYearLong2Digits,
} from "@qivia/ui";

export const formatCurrency = (value: number) =>
  hasBadNumberValue(value)
    ? ""
    : new Intl.NumberFormat("fr-FR", {
        style: "currency",
        currency: "EUR",
      }).format(value);
export const formatPercent = (value: number) =>
  hasBadNumberValue(value / 100)
    ? ""
    : new Intl.NumberFormat("fr-FR", {
        style: "percent",
      }).format(value / 100);
export const formatMileage = (value: number) => {
  return hasBadNumberValue(value)
    ? ""
    : new Intl.NumberFormat("fr-FR", {
        style: "unit",
        unit: "kilometer",
      }).format(value);
};
export const formatHour = (value: number) => {
  if (hasBadNumberValue(value)) return "";

  const isNegative = value < 0;
  const hours = Math.floor(Math.abs(value));
  const minutes = Math.round((Math.abs(value) - hours) * 60);

  const hoursFormatted = hours
    ? new Intl.NumberFormat("fr-FR", {
        style: "unit",
        unit: "hour",
      }).format(hours)
    : "";

  return (
    (isNegative ? "-" : "") +
    hoursFormatted +
    new Intl.NumberFormat("fr-FR", {
      style: "unit",
      unit: "minute",
    }).format(minutes)
  );
};
export const formatLiterPerOneHundredMileage = (value: number) => {
  return hasBadNumberValue(value)
    ? ""
    : new Intl.NumberFormat("fr-FR", {
        maximumFractionDigits: 2,
      }).format(value) + " l/100 km";
};
export const formatCarbonEmissionPerKilometer = (value: number) => {
  return hasBadNumberValue(value)
    ? ""
    : new Intl.NumberFormat("fr-FR", {
        style: "unit",
        unit: "gram-per-kilometer",
        maximumFractionDigits: 0,
      }).format(value);
};
export const formatMonth = (value: number) =>
  hasBadNumberValue(value)
    ? ""
    : new Intl.NumberFormat("fr-FR", {
        style: "unit",
        unit: "month",
        unitDisplay: "long",
      }).format(value);

export const hasBadNumberValue = (value: number) => {
  return !value || isNaN(value);
};

export const formatDate = (value: string | number | Date) =>
  value instanceof Date && !isNaN(value?.getTime())
    ? dateFormatterDayMonthYearLong2Digits(value)
    : "";

export const formatDateTime = (value: string | number | Date) =>
  value instanceof Date && !isNaN(value?.getTime())
    ? dateFormatterDayMonthLongYearAndHourMinute2Digits(value)
    : "";
export const formatDateHourMinute = (value: string | number | Date) =>
  value instanceof Date && !isNaN(value?.getTime())
    ? new Intl.DateTimeFormat("fr-FR", {
        hour: "numeric",
        minute: "numeric",
      })
        .format(value)
        .replace(":", "h")
    : "";

export const getDate = (value: string) => {
  if (!value) return null;
  const [day, month, year] = value.split("/");
  return new Date(`${month}/${day}/${year}`);
};

export const getDateTime = (value: string) => {
  if (!value) return value;
  const [day, month, rest] = value.split("/");
  const [year, restHours] = rest.split(" à ");
  const [hour, minute] = restHours.split("h");

  return new Date(
    parseInt(year),
    parseInt(month) - 1,
    parseInt(day),
    parseInt(hour),
    parseInt(minute),
  );
};
