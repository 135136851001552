import {
  ToastContainer,
  LaunchPage,
  sortByDateTime,
  PageDS,
  TableTagBadge,
  AddOutlined,
  triggerToast,
  MaterialUiTable,
  usePersistMuiTableState,
  MaterialUiColumnsProps,
  Spacer,
  Tag,
} from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectCompany } from "./../homeSlice";
import {
  badgesListAsync,
  selectBadgesCreationStatus,
  selectBadgesList,
  selectBadgesListStatus,
  selectBadgesOrderedNumber,
  selectBadgeUpdateStatus,
  selectReplacementBadgeStatus,
} from "./badgesSlice";
import { BadgesList, BadgesListDisplayed, BadgeStatus } from "./badgesAPI";
import { BadgeSidePanel } from "./SidePanel";
import { useNavigate } from "react-router-dom";
import { AddBadgeModal } from "./modals/addBadgeModal";
import { listAllVehiclesInDbAsync } from "../vehicles/vehiclesSlice";
import { MandateUnsignedModal } from "./modals/mandateUnsignedModal";
import { AddMultipeBadgesModal } from "./modals/addMulitpleBadgesModal";
import { addressAsync } from "../cards/cardsSlice";
import { documentDownloadXlsxAsync } from "../../../libSlice/downloadFileXslx";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import styled from "styled-components";
import Car from "@qivia/ui/src/designSystem/assets/Car.png";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import { selectIsStripePlatformMandateSigned } from "../billings/sepaDebitSlice";

export const Badges = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isCreateBadgeModalVisible, setIsCreateBadgeModalVisible] =
    useState(false);
  const [isModalUnsignedModalVisible, setIsModalUnsignedModalVisible] =
    useState(false);
  const [isMultipleBadgesModalVisible, setIsMultipleBadgesModalVisible] =
    useState(false);

  const [listDisplayed, setListDisplayed] = useState<
    BadgesListDisplayed[] | null
  >(null);
  const company = useAppSelector(selectCompany);
  const badgesListInit = useAppSelector(selectBadgesList);
  const badgesListStatus = useAppSelector(selectBadgesListStatus);
  const createBadgesStatus = useAppSelector(selectBadgesCreationStatus);
  const updateBadgeStatus = useAppSelector(selectBadgeUpdateStatus);
  const replacementBadgeStatus = useAppSelector(selectReplacementBadgeStatus);
  const badgesCreationStatus = useAppSelector(selectBadgesCreationStatus);
  const badgesOrderedNumber = useAppSelector(selectBadgesOrderedNumber);
  const isStripePlatformMandateSigned = useAppSelector(
    selectIsStripePlatformMandateSigned,
  );
  const pageName = "badges";
  const apiRef = useGridApiRef();
  const key = "customers-grid";

  const aggregation = {
    model: {
      number: "size",
      status: "size",
      reference: "size",
      registrationNumber: "size",
    },
  };
  const dataGridState = usePersistMuiTableState(
    apiRef,
    key,
    pageName,
    aggregation,
  );

  const findKeysToTranslate = useCallback(
    (key: keyof BadgesList, value: string) => {
      switch (key) {
        case "status":
          return t(`badges.status.${value}`);
        default:
          return value;
      }
    },
    [t],
  );

  const keysToTranslate = useMemo(
    () => ["status"] as (keyof BadgesListDisplayed)[],
    [],
  );

  const badgesList: BadgesList[] = useMemo(() => {
    const rowsWithId = badgesListInit.map((av) => {
      let newDict: BadgesList & { id: string } = {
        ...av,
        id: av.uuid,
      };
      Object.entries(av).forEach(([key, value]) => {
        if (keysToTranslate.includes(key as keyof BadgesListDisplayed)) {
          newDict = {
            ...newDict,
            [key]: value
              ? findKeysToTranslate(key as keyof BadgesList, value as string)
              : "",
          };
        }
      });
      return newDict;
    });
    return sortByDateTime(rowsWithId, "desc");
  }, [badgesListInit, findKeysToTranslate, keysToTranslate]);

  useEffect(() => {
    if (company && !company.canOrderBadges) {
      navigate(`/home`);
    }
  }, [company, navigate]);

  useEffect(() => {
    if (company) {
      void dispatch(badgesListAsync(company.uuid));
      void dispatch(listAllVehiclesInDbAsync({ companyUuid: company.uuid }));
      void dispatch(addressAsync(company.uuid));
    }
  }, [
    dispatch,
    company,
    createBadgesStatus,
    updateBadgeStatus,
    replacementBadgeStatus,
  ]);

  useEffect(() => {
    if (badgesList && badgesListStatus === "success") {
      const badgesDateTimeSorted = sortByDateTime(badgesList, "desc");
      setListDisplayed(badgesDateTimeSorted);
    }
  }, [badgesList, badgesListStatus]);

  useEffect(() => {
    if (badgesCreationStatus === "success" && badgesOrderedNumber) {
      badgesOrderedNumber === 1
        ? triggerToast(t("badges.creation.success.singular") || "", "valid")
        : triggerToast(
            t("badges.creation.success.plural", {
              number: badgesOrderedNumber,
            }) || "",
            "valid",
          );
    } else if (badgesCreationStatus === "failed") {
      triggerToast(t("badges.creation.failure") || "", "error");
    }
  }, [badgesCreationStatus, badgesOrderedNumber, t]);

  const sidePanelAction = useCallback(
    (props: Partial<BadgesList>) => {
      navigate(`/home/badges/${props.uuid}`);
    },
    [navigate],
  );

  const searchBarProps = {
    values: badgesList,
    setFilterValues: setListDisplayed,
    keysToIgnore: ["uuid" as const, "date" as const, "vehicleUuid" as const],
  };

  const downloadViewAction = useCallback(
    (file: Buffer | undefined) => {
      if (!file) return;
      void dispatch(
        documentDownloadXlsxAsync({
          buffer: file,
          fileName: "QIVIA_" + t("badges.title") + ".xlsx",
        }),
      );
    },
    [t, dispatch],
  );

  const columnsDisplayed: {
    [index in keyof BadgesListDisplayed]: MaterialUiColumnsProps;
  } = useMemo(() => {
    return {
      number: {
        type: "string",
      },
      status: {
        type: "string",
        valueFormatter: (value: BadgeStatus) =>
          value && (
            <StyledRow>
              <TableTagBadge status={value} />
            </StyledRow>
          ),
      },
      reference: {
        type: "string",
        width: 250,
        valueFormatter: (value) =>
          value && (
            <StyledRow>
              <div style={{ width: "2rem" }}>
                <img
                  src={Car}
                  style={{ width: "100%", filter: "grayscale(100%)" }}
                  alt="Car"
                />
              </div>
              <Spacer x={0.5} />
              <Tag
                textColor={colors["colors/text/black"]}
                backgroundColor={
                  colors["colors/surfaces/background/background_level0"]
                }
                text={value}
                borderColor={colors["colors/borders/cells/cells"]}
              />
            </StyledRow>
          ),
      },
      registrationNumber: {
        type: "string",
      },
    };
  }, []);

  const hasMandatRequirements = useMemo(() => {
    if (!company) return false;

    const hasQontoMandate =
      company.providerOnboardingStatus === "FINISHED" &&
      company.externalIban &&
      company.qontoMandateSigned;

    const hasStripePlatformMandate =
      company.externalIban &&
      company.canActivatePlatformSepaDebit &&
      (company.platformSepaMandateSigned || isStripePlatformMandateSigned);

    return hasQontoMandate || hasStripePlatformMandate;
  }, [company, isStripePlatformMandateSigned]);

  if (!listDisplayed) return <LaunchPage hasBorderRadius={true} />;

  const cta = {
    title: t("badges.cta"),
    action: () => {
      hasMandatRequirements
        ? setIsCreateBadgeModalVisible(true)
        : setIsModalUnsignedModalVisible(true);
    },
    width: 12,
    leftIcon: <AddOutlined />,
  };

  const cta2 = {
    title: t("badges.cta2"),
    action: () => {
      hasMandatRequirements
        ? setIsMultipleBadgesModalVisible(true)
        : setIsModalUnsignedModalVisible(true);
    },
    width: 12,
    leftIcon: <AddOutlined />,
  };

  if (!company) {
    return;
  }

  return (
    <>
      {isMultipleBadgesModalVisible ? (
        <PageDS
          title={t("badges.multipleBadges.title")}
          titleElement={{
            closeButtonAction: () => setIsMultipleBadgesModalVisible(false),
          }}
        >
          <AddMultipeBadgesModal
            onCloseModal={() => {
              setIsMultipleBadgesModalVisible(false);
            }}
          />
        </PageDS>
      ) : (
        <PageDS
          title={t("badges.title")}
          //isEmptyTable={listDisplayed.length === 0}
          toaster={<ToastContainer />}
          cta={cta}
          cta2={cta2}
        >
          <MaterialUiTable<keyof BadgesListDisplayed, BadgesListDisplayed>
            rows={listDisplayed}
            columns={columnsDisplayed}
            pageName={pageName}
            sidePanelAction={sidePanelAction}
            dataGridState={dataGridState}
            searchBar={searchBarProps}
            apiRef={apiRef}
            downloadViewAction={downloadViewAction}
            cta={cta}
            cta2={cta2}
          />
          <BadgeSidePanel />
          <AddBadgeModal
            visible={isCreateBadgeModalVisible}
            onCloseModal={() => {
              setIsCreateBadgeModalVisible(false);
            }}
          />
          <MandateUnsignedModal
            company={company}
            visible={isModalUnsignedModalVisible}
            onCloseModal={() => {
              setIsModalUnsignedModalVisible(false);
            }}
          />
        </PageDS>
      )}
    </>
  );
};

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;
