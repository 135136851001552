import styled from "styled-components";
import { colors } from "../../styles/figmaColors";

export interface ProgressbarProps {
  percentage: number;
  showLabel?: boolean;
}

export const Progressbar = (props: ProgressbarProps) => {
  const { percentage } = props;

  return <StyledLine $percentage={percentage} />;
};

interface StyledProgressbarArgs {
  $percentage: number;
}

const StyledLine = styled.div<StyledProgressbarArgs>`
  width: 100%;
  transition: 0.25s;
  border-bottom: 0.25rem solid ${colors["colors/borders/button/primary"]};
  border-radius: 2rem;
  border-image: linear-gradient(
      to right,
      ${colors["colors/accent/500"]} ${({ $percentage }) => $percentage}%,
      ${colors["colors/borders/button/primary"]}
        ${({ $percentage }) => $percentage}%
    )
    5;
`;
