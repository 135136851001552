import { isAfter, isBefore } from "date-fns";
import { AnalyticsVehicles, DriversHistory } from "../fleet/fleetApi";
import {
  DriverPlanningByDay,
  Trips,
  TripsAndPlanningsHistory,
  TripsByDayApi,
  TripsDetails,
  TripsDetailsWithDriver,
} from "./tripsApi";

export const getTotalDistance = (trips: TripsDetails[]) => {
  const result: number = trips
    .filter(({ distance }) => !!distance)
    .reduce((acc, { distance }) => acc + distance!, 0);

  return result;
};

export const getTotalSecondsDuration = (trips: TripsDetails[]) =>
  Number(
    trips
      .filter((trip) => !!trip.secondsDuration)
      .reduce((acc, trip) => acc + trip.secondsDuration!, 0)
      .toFixed(2),
  );

export const getTotalHoursDuration = (trips: TripsDetails[]) =>
  Number((getTotalSecondsDuration(trips) / 3600).toFixed(2));

export const getDistanceVariation = (
  trips: Trips,
  plannings: DriverPlanningByDay,
) => {
  const tripDistance = trips.totalDistanceInKm;
  const planningDistance = plannings.expectedMileagePerDay;

  return tripDistance - planningDistance;
};

export const getDistanceVariationPercentage = (
  trips: Trips,
  plannings: DriverPlanningByDay,
) => {
  const tripDistance = trips.totalDistanceInKm;
  const planningDistance = plannings.expectedMileagePerDay;

  const variation = Number(
    ((tripDistance - planningDistance) / planningDistance).toFixed(2),
  );

  return variation * 100;
};

export const getDurationVariation = (
  trips: Trips,
  plannings: DriverPlanningByDay,
) => {
  const tripSecondsDuration = getTotalSecondsDuration(trips.trips);
  const planningSecondsDuration = plannings.expectedDurationPerDay;

  return Number(
    ((tripSecondsDuration - planningSecondsDuration) / 3600).toFixed(2),
  );
};

export const getDurationVariationPercentage = (
  trips: Trips,
  plannings: DriverPlanningByDay,
) => {
  const tripSecondsDuration = getTotalSecondsDuration(trips.trips);
  const planningSecondsDuration = plannings.expectedDurationPerDay;

  const variation = Number(
    (
      (tripSecondsDuration - planningSecondsDuration) /
      planningSecondsDuration
    ).toFixed(2),
  );

  return variation * 100;
};

export const getStartDate = (trips: TripsDetails[]) => {
  const startDateTime = trips
    .map((trip) => new Date(trip.startDate).getTime())
    .reduce((acc, time) => Math.min(acc, time), Number.MAX_SAFE_INTEGER);
  if (startDateTime === Number.MAX_SAFE_INTEGER) return null;
  return new Date(startDateTime).toISOString();
};

export const getEndDate = (trips: TripsDetails[]) => {
  const endDateTime = trips
    .map((trip) => new Date(trip.endDate).getTime())
    .reduce((acc, time) => Math.max(acc, time), Number.MIN_SAFE_INTEGER);

  if (endDateTime === Number.MIN_SAFE_INTEGER) return null;
  return new Date(endDateTime).toISOString();
};

export const getTripsAndPlanningsHistory = (payload: {
  trips: Trips[];
  plannings: DriverPlanningByDay[];
}): TripsAndPlanningsHistory[] => {
  return payload.trips.map((t) => {
    const associatedPlanning = payload.plannings.find(
      (p) =>
        p.driverUuid === t.driverUuid &&
        p.date.slice(0, 10) === t.date.slice(0, 10),
    );
    return {
      ...t,
      openDetails: "",
      distanceVariation: associatedPlanning
        ? getDistanceVariation(t, associatedPlanning)
        : null,
      distanceVariationPercent: associatedPlanning
        ? getDistanceVariationPercentage(t, associatedPlanning)
        : null,
      driveTimeVariation: associatedPlanning
        ? getDurationVariation(t, associatedPlanning)
        : null,
      driveTimeVariationPercent: associatedPlanning
        ? getDurationVariationPercentage(t, associatedPlanning)
        : null,
    };
  });
};

export const getTripsDetailsWithDrivers = (
  trips: TripsDetails[],
  driverHistoryList: DriversHistory[],
): TripsDetailsWithDriver[] => {
  return trips.map((trip) => {
    let driver = driverHistoryList.length === 1 ? driverHistoryList[0] : null;

    for (let i = driverHistoryList.length - 1; i >= 0; i--) {
      const currDriverStart = driverHistoryList[i].startOfUsage;
      const nextDriverStart =
        i > 0 ? driverHistoryList[i - 1].startOfUsage : null;

      const isTripAfterDriverStart = isAfter(trip.startDate, currDriverStart);
      const isTripDateBeforeNextDriverStart =
        nextDriverStart && isBefore(trip.startDate, nextDriverStart);

      if (
        isTripAfterDriverStart &&
        (!nextDriverStart || isTripDateBeforeNextDriverStart)
      ) {
        driver = driverHistoryList[i];
        break;
      }
      driver = null;
    }

    const driverFullName = driver?.driverInfo.firstName.concat(
      " ",
      driver.driverInfo.lastName,
    );

    return {
      ...trip,
      driver: driverFullName ?? null,
      driverUuid: driver?.driverUuid ?? null,
    };
  });
};

export const getTripsDetailsGroupedByDrivers = (
  tripsWithDriver: TripsDetailsWithDriver[],
) => {
  const tripsByDayVehicleDriverDict = tripsWithDriver.reduce(
    (acc, twd) => ({
      ...acc,
      [twd.driver ?? ""]: [...(acc[twd.driver ?? ""] ?? []), twd],
    }),
    {} as Record<string, TripsDetailsWithDriver[]>,
  );
  return Object.values(tripsByDayVehicleDriverDict);
};

export const getDriverHistoryGroupByVehicle = (
  driverHistory: DriversHistory[],
) =>
  driverHistory.reduce(
    (acc, dh) => ({
      ...acc,
      [dh.vehicleUuid]: acc[dh.vehicleUuid]
        ? [...acc[dh.vehicleUuid], dh]
        : [dh],
    }),
    {} as Record<string, DriversHistory[]>,
  );

export const getAnalyticsVehiclesGroupByVehicle = (
  vehicles: AnalyticsVehicles[],
) =>
  vehicles.reduce(
    (acc, v) => ({
      ...acc,
      [v.uuid]: v,
    }),
    {} as Record<string, AnalyticsVehicles>,
  );

export const getRowsDetails = (
  data: TripsByDayApi,
  trips: TripsDetailsWithDriver[],
  vehicles: AnalyticsVehicles,
) => ({
  ...data,
  trips,
  count: trips.length,
  driverName: trips[0]?.driver ?? null,
  driverUuid: trips[0]?.driverUuid ?? null,
  reference: vehicles.reference?.toLocaleUpperCase() ?? null,
  registrationNumber: vehicles.registrationNumber,
  totalDistanceInKm: Math.max(
    (data.maxOdometerValue || 0) - (data.minOdometerValue || 0),
    getTotalDistance(trips),
  ),
  hoursDuration: getTotalHoursDuration(trips),
  usageStartDate: getStartDate(trips),
  usageEndDate: getEndDate(trips),
  vehicleModel: [vehicles.marque, vehicles.modele].filter((d) => d).join(" "),
});

export const getLastDriver = (trips: TripsDetailsWithDriver[]) =>
  trips.length > 0 ? trips[trips.length - 1].driver : null;
