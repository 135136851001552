import {
  ButtonDS,
  dateFormatterDayMonthLongYearAndHourMinute2Digits,
  dateFormatterDayMonthYearLong2Digits,
  KeyboardArrowDownOutlined,
  KeyboardArrowUptOutlined,
  LaunchPage,
  MaterialUiColumnsProps,
  MaterialUiTable,
  sortByDateTime,
  Spacer,
  Tag,
  DayTripDetails,
  usePersistMuiTableState,
} from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectCompany } from "../homeSlice";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  planningsAsync,
  selectPlanningsByDayList,
  selectPlanningsByDayStatus,
  selectTripsByDayList,
  selectTripsByDayStatus,
  tripsAsync,
} from "./tripsSlice";
import {
  TripsAndPlanningsHistoryDisplayed,
  TripsAndPlanningsHistory,
} from "./tripsApi";
import {
  GRID_AGGREGATION_ROOT_FOOTER_ROW_ID,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import styled from "styled-components";
import Car from "@qivia/ui/src/designSystem/assets/Car.png";
import { documentDownloadXlsxAsync } from "../../../libSlice/downloadFileXslx";
import {
  formatDate,
  formatDateHourMinute,
  formatHour,
  formatMileage,
  formatPercent,
  getDate,
  getDateTime,
} from "./../../../libSlice/materialUiFormats";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import {
  analyticVehiclesAsync,
  driversHistoryAsync,
  selectAnalyticsVehiclesList,
  selectAnalyticsVehiclesListStatus,
  selectDriversHistoryList,
  selectDriversHistoryListStatus,
} from "../fleet/fleetSlice";
import { getTripsAndPlanningsHistory } from "./lib";
import { typographies } from "@qivia/ui/src/styles/figmaTypographies";

export const HistoryTab = () => {
  const { t } = useTranslation();
  const company = useAppSelector(selectCompany);
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useAppDispatch();
  const analyticsVehicleList = useAppSelector(selectAnalyticsVehiclesList);
  const analyticsVehicleListStatus = useAppSelector(
    selectAnalyticsVehiclesListStatus,
  );
  const driversHistoryList = useAppSelector(selectDriversHistoryList);
  const driversHistoryListStatus = useAppSelector(
    selectDriversHistoryListStatus,
  );
  const tripsListInit = useAppSelector(selectTripsByDayList);
  const tripsListStatus = useAppSelector(selectTripsByDayStatus);
  const planningsListInit = useAppSelector(selectPlanningsByDayList);
  const planningsListStatus = useAppSelector(selectPlanningsByDayStatus);
  const [listDisplayed, setListDisplayed] = useState<
    TripsAndPlanningsHistoryDisplayed[] | null
  >(null);
  const [currentTripDetailsId, setCurrentTripDetailsUuid] = useState<
    string | null
  >(null);

  const pageName = "trips.history";
  useEffect(() => {
    if (company && !company.hasAnalytics) {
      navigate(`/home`);
    }
  }, [company, navigate]);

  useEffect(() => {
    if (
      company &&
      analyticsVehicleList &&
      analyticsVehicleList.length > 0 &&
      analyticsVehicleListStatus === "success" &&
      driversHistoryListStatus === "success" &&
      tripsListStatus === "idle"
    ) {
      void dispatch(
        tripsAsync({
          companyUuid: company.uuid,
          vehicles: analyticsVehicleList,
          driversHistory: driversHistoryList,
        }),
      );
      void dispatch(planningsAsync({ companyUuid: company.uuid }));
    }
  }, [
    dispatch,
    analyticsVehicleListStatus,
    analyticsVehicleList,
    tripsListStatus,
    company,
    driversHistoryListStatus,
    driversHistoryList,
  ]);

  useEffect(() => {
    if (company) {
      void dispatch(analyticVehiclesAsync(company.uuid));
      void dispatch(driversHistoryAsync(company.uuid));
    }
  }, [dispatch, company]);

  const apiRef = useGridApiRef();
  const key = "customers-grid";
  const aggregation = {
    model: {
      reference: "size",
      registrationNumber: "size",
      driverName: "size",
      count: "avg",
      totalDistanceInKm: "avg",
      hoursDuration: "avg",
      distanceVariation: "avg",
      distanceVariationPercent: "avg",
      driveTimeVariation: "avg",
      driveTimeVariationPercent: "avg",
    },
  };
  const dataGridState = usePersistMuiTableState(
    apiRef,
    key,
    pageName,
    aggregation,
  );

  const findKeysToTranslate = useCallback(
    (key: keyof Partial<TripsAndPlanningsHistoryDisplayed>, value: string) => {
      switch (key) {
        case "date":
          return dateFormatterDayMonthYearLong2Digits(new Date(value));
        case "usageStartDate":
        case "usageEndDate":
          return dateFormatterDayMonthLongYearAndHourMinute2Digits(
            new Date(value),
          );
        default:
          return value;
      }
    },
    [],
  );

  const keysToTranslate = useMemo(
    () =>
      [
        "date",
        "usageStartDate",
        "usageEndDate",
      ] as (keyof TripsAndPlanningsHistoryDisplayed)[],
    [],
  );

  const tripsAndPlanningsHistoryList: TripsAndPlanningsHistory[] =
    useMemo(() => {
      const tripsAndPlanningsHistoryInit = getTripsAndPlanningsHistory({
        trips: tripsListInit,
        plannings: planningsListInit,
      });
      const rowsWithId = tripsAndPlanningsHistoryInit.map((vt) => {
        let newDict: TripsAndPlanningsHistory & { id: string } = {
          ...vt,
          id: vt.vehicleUuid + vt.usageStartDate,
        };
        Object.entries(vt).forEach(([key, value]) => {
          if (
            keysToTranslate.includes(
              key as keyof TripsAndPlanningsHistoryDisplayed,
            )
          ) {
            newDict = {
              ...newDict,
              [key]: value
                ? findKeysToTranslate(
                    key as keyof TripsAndPlanningsHistoryDisplayed,
                    value as string,
                  )
                : "",
            };
          }
        });
        return newDict;
      });

      return sortByDateTime(rowsWithId, "desc", "createdAt");
    }, [
      findKeysToTranslate,
      keysToTranslate,
      planningsListInit,
      tripsListInit,
    ]);

  useEffect(() => {
    if (
      tripsListInit &&
      tripsListStatus === "success" &&
      planningsListInit &&
      planningsListStatus === "success"
    ) {
      const tripsAndPlanningsHistorySorted = sortByDateTime(
        tripsAndPlanningsHistoryList,
        "desc",
        "date",
      );
      setListDisplayed(tripsAndPlanningsHistorySorted);
    }
  }, [
    planningsListInit,
    planningsListStatus,
    tripsAndPlanningsHistoryList,
    tripsListInit,
    tripsListStatus,
  ]);

  const currentTripDetails: TripsAndPlanningsHistory | null = useMemo(
    () =>
      tripsAndPlanningsHistoryList.find(
        (t) => "id" in t && t.id === currentTripDetailsId,
      ) ?? null,
    [currentTripDetailsId, tripsAndPlanningsHistoryList],
  );

  const searchBarProps = {
    values: tripsAndPlanningsHistoryList,
    setFilterValues: setListDisplayed,
    keysToIgnore: [
      "vehicleUuid" as const,
      "driverUuid" as const,
      "trips" as const,
      "maxOdometerValue" as const,
      "minOdometerValue" as const,
      "distanceVariationPercent" as const,
      "driveTimeVariationPercent" as const,
    ],
  };

  const downloadViewAction = useCallback(
    (file: Buffer | undefined) => {
      if (!file) return;
      void dispatch(
        documentDownloadXlsxAsync({
          buffer: file,
          fileName: "QIVIA_" + t("trips.title") + ".xlsx",
        }),
      );
    },
    [t, dispatch],
  );

  const columnsDisplayed: {
    [index in keyof TripsAndPlanningsHistoryDisplayed]: MaterialUiColumnsProps;
  } = useMemo(() => {
    return {
      openDetails: {
        type: "string",
        width: 80,
        valueFormatter: (value, row) => {
          return (
            <StyledButton>
              <Spacer y={0.5} />
              <ButtonDS
                singleIcon={{
                  icon:
                    currentTripDetailsId === row["id"] ? (
                      <KeyboardArrowUptOutlined />
                    ) : (
                      <KeyboardArrowDownOutlined />
                    ),
                  size: "L",
                }}
                format={"fill"}
                buttonType={"secondary"}
                onClick={() => {
                  if (currentTripDetailsId === row["id"]) {
                    setCurrentTripDetailsUuid(null);
                  } else {
                    setCurrentTripDetailsUuid(row["id"] as string);
                  }
                }}
              />
              <Spacer y={0.5} />
            </StyledButton>
          );
        },
        renderCell: (p) => {
          return (
            p.id !== GRID_AGGREGATION_ROOT_FOOTER_ROW_ID && (
              <>{p.formattedValue}</>
            )
          );
        },
      },
      date: {
        type: "date",
        valueGetter: getDate,
        valueFormatter: formatDate,
      },
      reference: {
        type: "string",
        width: 250,
        valueFormatter: (value) =>
          value && (
            <StyledDivImg>
              <StyledImg src={Car} alt="Car" />
              <Spacer x={0.5} />
              <Tag
                textColor={colors["colors/text/black"]}
                backgroundColor={
                  colors["colors/surfaces/background/background_level0"]
                }
                text={value}
                borderColor={colors["colors/borders/cells/cells"]}
              />
            </StyledDivImg>
          ),
      },
      registrationNumber: {
        type: "string",
      },
      driverName: {
        type: "string",
        width: 200,
      },
      count: {
        type: "number",
      },
      totalDistanceInKm: {
        type: "number",
        valueFormatter: formatMileage,
      },
      distanceVariation: {
        width: 230,
        type: "number",
        valueFormatter: formatMileage,
      },
      distanceVariationPercent: {
        width: 220,
        type: "number",
        valueFormatter: formatPercent,
      },
      usageStartDate: {
        type: "date",
        width: 250,
        valueGetter: getDateTime,
        valueFormatter: formatDateHourMinute,
      },
      usageEndDate: {
        type: "date",
        width: 220,
        valueGetter: getDateTime,
        valueFormatter: formatDateHourMinute,
      },
      hoursDuration: {
        type: "number",
        valueFormatter: formatHour,
        renderCell: (p) => {
          return p.id !== GRID_AGGREGATION_ROOT_FOOTER_ROW_ID ? (
            <>{p.formattedValue}</>
          ) : (
            <StyledColumn>
              <StyledValue>{formatHour(p.value as number)}</StyledValue>
              <StyledLabel>Moyenne</StyledLabel>
            </StyledColumn>
          );
        },
      },
      driveTimeVariation: {
        type: "number",
        width: 250,
        valueFormatter: formatHour,
        renderCell: (p) => {
          return p.id !== GRID_AGGREGATION_ROOT_FOOTER_ROW_ID ? (
            <>{p.formattedValue}</>
          ) : (
            <StyledColumn>
              <StyledValue>{formatHour(p.value as number)}</StyledValue>
              <StyledLabel>Moyenne</StyledLabel>
            </StyledColumn>
          );
        },
      },
      driveTimeVariationPercent: {
        type: "number",
        width: 240,
        valueFormatter: formatPercent,
      },
    };
  }, [currentTripDetailsId]);

  if (!listDisplayed) return <LaunchPage hasBorderRadius={true} />;
  if (params.tab !== "history") {
    return;
  }

  return (
    <StyledContainer>
      <MaterialUiTable<
        keyof TripsAndPlanningsHistoryDisplayed,
        TripsAndPlanningsHistoryDisplayed
      >
        rows={listDisplayed}
        columns={columnsDisplayed}
        pageName={pageName}
        dataGridState={dataGridState}
        searchBar={searchBarProps}
        apiRef={apiRef}
        downloadViewAction={downloadViewAction}
      />
      {currentTripDetails && (
        <>
          <Spacer y={2} />
          <StyledBottom>
            <DayTripDetails
              date={getDate(currentTripDetails.date)}
              vehicleModel={currentTripDetails.vehicleModel}
              trips={currentTripDetails.trips}
            />
          </StyledBottom>
        </>
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledBottom = styled.div`
  max-height: 48%;
  min-height: 48%;
`;

const StyledDivImg = styled.div`
  display: flex;
  align-items: center;
`;

const StyledImg = styled.img`
  filter: grayscale(100%);
  width: 2rem;
  height: 60px;
  object-fit: contain;
  padding-bottom: 2.5px;
`;

const StyledLabel = styled.div`
  color: ${colors["colors/text/darkGrey"]};
  ${typographies["Body/XXS"]};
  height: 2rem;
`;

const StyledValue = styled.div`
  height: 2.2rem;
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const StyledButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
