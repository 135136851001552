import { useState } from "react";
import styled from "styled-components";
import Logo from "../assets/qiviaLogoSquare.svg?react";
import EnlargedLogo from "../assets/qiviaLogo.svg?react";
import { colors } from "../styles/colors";
import { Spacer } from "./Spacer";
import Badge from "../assets/badge.svg?react";
import { TooltipComponent } from "./Tooltip";
import { useTranslation } from "react-i18next";
import Logout from "../assets/logoutIcon.svg?react";
import { ElementButton } from "./ElementButton";

import RightArrow from "../assets/doubleArrowRightSolid.svg?react";
import RightArrowHover from "../assets/doubleArrowRightWhite.svg?react";
import LeftArrow from "../assets/doubleArrowLeftSolid.svg?react";
import LeftArrowHover from "../assets/doubleArrowLeftWhite.svg?react";
import { TextCapitalized } from "./text/TextCapitalized";
import { Avatar } from "../designSystem/components/Avatar/Avatar";

export interface MenuProps {
  sections: Array<{
    route: string;
    iconSrc: JSX.Element;
    title: string;
    newCreation?: boolean;
  }>;
  selectedRoute?: string;
  manager?: {
    firstName: string;
    lastName: string;
    email: string;
    uuid: string;
    companyUuid: string;
  } | null;
  onChangeRoute: (route: string) => void;
  route: string;
  logout: () => void;
  isQg?: boolean;
}

interface StyledHeaderLogoProps {
  $selected: boolean;
  $isMenuEnlarged: boolean;
  $isQg: boolean;
}

export interface HomeTrailerProps {
  route: string;
  selectedRoute?: string;
  onChangeRoute: (route: string) => void;
  manager?: {
    firstName: string;
    lastName: string;
    email: string;
    uuid: string;
    companyUuid: string;
  } | null;
  isMenuEnlarged: boolean;
  logout: () => void;
}

const MenuHeaderLogo = (props: {
  isArrowHover: boolean;
  setIsArrowHover: (value: boolean) => void;
  isMenuHover: boolean;
  setIsMenuHover: (value: boolean) => void;
  isMenuEnlarged: boolean;
  setIsMenuEnlarged: (value: boolean) => void;
  setStateByDefault: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <StyledHeaderLogo>
      <Spacer x={1} />
      {props.isMenuEnlarged ? (
        <>
          <div>
            {" "}
            <EnlargedLogo />
          </div>
          <StyledArrow $isMenuEnlarged={true}>
            <TooltipComponent
              id={"TooltipClose"}
              place={"left"}
              content={t("menu.tooltip.close") || ""}
              withoutSpacer
            />
            {props.isMenuHover &&
              (props.isArrowHover ? (
                <div
                  onMouseLeave={() => props.setIsArrowHover(false)}
                  data-tooltip-id="TooltipClose"
                >
                  <ElementButton
                    onClick={() => {
                      props.setIsMenuEnlarged(false);
                      props.setStateByDefault();
                    }}
                    Element={<LeftArrowHover />}
                  />
                </div>
              ) : (
                <div
                  onMouseEnter={() => props.setIsArrowHover(true)}
                  data-tooltip-id="TooltipClose"
                >
                  <LeftArrow />
                </div>
              ))}
          </StyledArrow>
        </>
      ) : (
        <StyledArrow $isMenuEnlarged={false}>
          <TooltipComponent
            id={"TooltipOpen"}
            place={"right"}
            content={t("menu.tooltip.open") || ""}
            withoutSpacer
          />
          {props.isMenuHover ? (
            props.isArrowHover ? (
              <div
                onMouseLeave={() => props.setIsArrowHover(false)}
                data-tooltip-id="TooltipOpen"
              >
                <ElementButton
                  onClick={() => {
                    props.setIsMenuEnlarged(true);
                    props.setStateByDefault();
                  }}
                  Element={<RightArrowHover />}
                />
              </div>
            ) : (
              <div
                onMouseEnter={() => props.setIsArrowHover(true)}
                data-tooltip-id="TooltipOpen"
              >
                <RightArrow />
              </div>
            )
          ) : (
            <div>
              <Logo />
            </div>
          )}
        </StyledArrow>
      )}
      <Spacer x={1} />
    </StyledHeaderLogo>
  );
};

export const HomeTrailer = (props: HomeTrailerProps) => {
  const { t } = useTranslation();

  return (
    <StyledTrailerContainer $isMenuEnlarged={props.isMenuEnlarged}>
      <StyledLogout
        $isMenuEnlarged={props.isMenuEnlarged}
        onClick={() => {
          props.logout();
        }}
      >
        {props.isMenuEnlarged ? (
          <StyledLogoutLogo>
            <Spacer x={1.7} />
            <Logout />
            <Spacer x={1} />
            <TextCapitalized>{t("home.menu.logout")}</TextCapitalized>
          </StyledLogoutLogo>
        ) : (
          <Logout />
        )}
      </StyledLogout>
      <Spacer y={2} />
      {props.manager && (
        <StyledManagerContainer
          $selected={props.selectedRoute === props.route}
          onClick={() => props.onChangeRoute(props.route)}
        >
          {props.isMenuEnlarged ? (
            <>
              <Spacer x={1} />
              <Avatar
                firstName={props.manager.firstName[0]}
                size="S"
                clickable={true}
                color={"red"}
              />
              <Spacer x={1} />
              <StyledManager>
                {props.manager.firstName + " " + props.manager.lastName}
                <br />
                <StyledManagerContent>
                  {t("home.menu.manager")}
                </StyledManagerContent>
              </StyledManager>
              <Spacer x={1} />
            </>
          ) : (
            <>
              <Spacer x={1} />
              <Avatar
                firstName={props.manager.firstName[0]}
                size="S"
                clickable={true}
                color={"red"}
              />
              <Spacer x={1} />
            </>
          )}
        </StyledManagerContainer>
      )}
    </StyledTrailerContainer>
  );
};

export const Menu = (props: MenuProps) => {
  const [isArrowHover, setIsArrowHover] = useState(false);
  const [isMenuHover, setIsMenuHover] = useState(false);
  const [isMenuEnlarged, setIsMenuEnlarged] = useState(true);
  const setStateByDefault = () => {
    setIsMenuHover(false);
    setIsArrowHover(false);
  };

  return (
    <>
      <Spacer x={1.5} />
      <StyledContainer
        onMouseEnter={() => setIsMenuHover(true)}
        onMouseLeave={() => setStateByDefault()}
        $isMenuEnlarged={isMenuEnlarged}
      >
        <Spacer y={2} />

        <MenuHeaderLogo
          isArrowHover={isArrowHover}
          setIsArrowHover={(value) => setIsArrowHover(value)}
          isMenuHover={isMenuHover}
          setIsMenuHover={(value) => setIsMenuHover(value)}
          isMenuEnlarged={isMenuEnlarged}
          setIsMenuEnlarged={(value) => setIsMenuEnlarged(value)}
          setStateByDefault={() => setStateByDefault()}
        />

        <Spacer y={2} />
        {props.sections.map((section) => (
          <StyledSectionContainer
            key={section.route}
            $selected={section.route === props.selectedRoute}
            onClick={() => props.onChangeRoute(section.route)}
            $isQg={props.isQg ? props.isQg : false}
            $isMenuEnlarged={isMenuEnlarged}
          >
            {isMenuEnlarged ? <Spacer x={1.6} /> : <Spacer x={1} />}
            <>
              {section.newCreation && (
                <StyledBadge $isMenuEnlarged={isMenuEnlarged}>
                  <Badge />
                </StyledBadge>
              )}
              <StyledIcon>{section.iconSrc}</StyledIcon>
            </>
            <StyledText $isMenuEnlarged={isMenuEnlarged}>
              <Spacer x={1} />
              <TextCapitalized>{section.title}</TextCapitalized>
            </StyledText>
            <Spacer x={1} />
          </StyledSectionContainer>
        ))}
        <Spacer y={1} />
        <StyledTrailer $isMenuEnlarged={isMenuEnlarged}>
          <HomeTrailer
            route={props.route}
            onChangeRoute={props.onChangeRoute}
            selectedRoute={props.selectedRoute}
            manager={props.manager}
            isMenuEnlarged={isMenuEnlarged}
            logout={props.logout}
          />
        </StyledTrailer>
        <Spacer y={2.5} />
      </StyledContainer>
      <Spacer x={1.5} />
    </>
  );
};

const StyledLogoutLogo = styled.div`
  display: flex;
  align-items: center;
`;

const StyledTrailerContainer = styled.div<{ $isMenuEnlarged: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: ${({ $isMenuEnlarged }) =>
    $isMenuEnlarged ? "flex-start" : "center"};
  width: 100%;
`;

const StyledLogout = styled.div<{ $isMenuEnlarged: boolean }>`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  &:hover {
    color: ${colors.disabled};
    & svg path {
      fill: ${colors.disabled};
    }
  }
  width: ${({ $isMenuEnlarged }) => ($isMenuEnlarged ? "100%" : "")};
`;

const StyledManager = styled.div`
  display: flex;
  flex-direction: column;
  width: 10rem;
`;

const StyledManagerContent = styled.div`
  font-size: 0.875rem;
  color: ${colors.disabled};

  &::first-letter {
    text-transform: uppercase;
  }
`;

const StyledManagerContainer = styled.div<{ $selected?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 4.5rem;
  width: 100%;
  line-height: 1.5rem;
  border-radius: 0.75rem;
  background-color: ${({ $selected }) => ($selected ? colors.white : "none")};
  justify-content: center;
  font-size: 1rem;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
  &:hover {
    background-color: ${colors.white};
  }
`;

const StyledText = styled.div<{ $isMenuEnlarged: boolean }>`
  width: ${({ $isMenuEnlarged }) => ($isMenuEnlarged ? "100%" : "0px")};
  display: flex;
  font-size: 1rem;
  font-family: Inter;
  font-style: normal;
`;

const StyledTrailer = styled.div<{ $isMenuEnlarged: boolean }>`
  display: flex;
  justify-content: center;
  width: ${({ $isMenuEnlarged }) => ($isMenuEnlarged ? "15.5rem" : "4.5rem")};
  margin-top: auto;
`;

const StyledContainer = styled.div<{ $isMenuEnlarged: boolean }>`
  width: ${({ $isMenuEnlarged }) => ($isMenuEnlarged ? "15.5rem" : "4.5rem")};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  transition: all 0.5s ease;
`;

const StyledIcon = styled.div`
  min-width: 1.3rem;
  height: 1.3rem;
`;

const StyledBadge = styled.div<{ $isMenuEnlarged: boolean }>`
  position: absolute;
  display: flex;
  align-items: flex-start;
  justify-content: ${({ $isMenuEnlarged }) =>
    $isMenuEnlarged ? "flex-start" : "flex-end"};
  width: ${({ $isMenuEnlarged }) => ($isMenuEnlarged ? "10.75rem" : "2.25rem")};
  height: 2.25rem;
`;

const StyledSectionContainer = styled.div<StyledHeaderLogoProps>`
  width: ${({ $isMenuEnlarged }) => ($isMenuEnlarged ? "15.5rem" : "4.5rem")};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.5rem;
  border-radius: 1rem;
  background-color: ${({ $selected, $isQg }) =>
    $selected ? ($isQg ? colors.paleRed : colors.paleGreen) : "none"};
  font-weight: ${({ $selected }) => ($selected ? "600" : "500")};
  color: ${({ $selected }) =>
    $selected ? `${colors.black}` : `${colors.contentSecondary}`};
  cursor: pointer;
  div {
    overflow: hidden;
  }
  transition: ${({ $isMenuEnlarged }) =>
    $isMenuEnlarged ? "none" : "all 0.5s ease"};
  &:hover {
    color: ${colors.disabled};
    & svg path {
      fill: ${colors.disabled};
    }
  }
`;

const StyledHeaderLogo = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 3.5rem;
  border-radius: 1rem;
  justify-content: center;
`;

const StyledArrow = styled.div<{ $isMenuEnlarged: boolean }>`
  display: flex;
  width: 100%;
  justify-content: ${({ $isMenuEnlarged }) =>
    $isMenuEnlarged ? "flex-end" : "center"};
  align-items: center;
`;
