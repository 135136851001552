import { PageDS } from "@qivia/ui";
import {
  PublicCompany,
  PublicManager,
  selectLoadCompanyStatus,
} from "../../homeSlice";
import { Params, useNavigate } from "react-router-dom";
import { PlatformSepaDebitModal } from "./platformSepaDebitModal";
import { ExternalBankAccountModal } from "./externalBankAccountModal";
import { useEffect } from "react";
import { getCompanySepaDebitInfoByAccountProviderIdAsync } from "../sepaDebitSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { useTranslation } from "react-i18next";

export const SepaDebitModals = (props: {
  company: PublicCompany;
  manager: PublicManager;
  params: Readonly<Params<string>>;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const companyStatus = useAppSelector(selectLoadCompanyStatus);

  useEffect(() => {
    void dispatch(
      getCompanySepaDebitInfoByAccountProviderIdAsync({
        accountProviderId: props.company.providerId,
      }),
    );
  }, [dispatch, props.company.providerId]);

  useEffect(() => {
    const modalType = ["external_account", "sign_platform_mandate"];
    if (
      !props.params.sepa_debit ||
      !modalType.includes(props.params.sepa_debit)
    ) {
      navigate(`/home/billings/account`);
    }
  }, [navigate, props.params.sepa_debit]);

  useEffect(() => {
    if (
      props.params.sepa_debit &&
      ["sign_platform_mandate"].includes(props.params.sepa_debit) &&
      !props.company.externalIban
    ) {
      navigate(`/home/billings/account/external_account`);
    }
  }, [navigate, props.params.sepa_debit, props.company.externalIban]);

  useEffect(() => {
    if (companyStatus === "processing" || props.manager.role !== "OWNER") {
      navigate(`/home/billings/account`);
    }
  }, [companyStatus, dispatch, navigate, props.manager.role]);

  return (
    <PageDS
      title={t(`account.${props.params.sepa_debit}.modal.title`) || ""}
      titleElement={{
        closeButtonAction: () => navigate(`/home/billings/account`),
      }}
    >
      <>
        <ExternalBankAccountModal
          company={props.company}
          isVisible={
            props.params.sepa_debit === "external_account" &&
            !props.company.externalIban &&
            props.manager.role === "OWNER"
          }
        />

        <PlatformSepaDebitModal
          company={props.company}
          manager={props.manager}
          isVisible={
            props.params.sepa_debit === "sign_platform_mandate" &&
            !props.company.platformSepaMandateSigned &&
            props.manager.role === "OWNER"
          }
        />
      </>
    </PageDS>
  );
};
