import { LocalizationProvider } from "@mui/x-date-pickers-pro/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFnsV3";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { useCallback, useState } from "react";
import { fr } from "date-fns/locale/fr";

import { CalendarWeek } from "../../materialUi/materialUi";
import MaterialUiThemeProvider from "../../../providers/MaterialUiThemeProvider";
import { colors } from "../../../styles/figmaColors";

type MaterialUiDatePickerProps = {
  dateSeparator?: string;
  value?: [start: Date, end: Date];
  onChange: (dates: Date[]) => void;
  Action?: JSX.ElementType;
};

export default function MaterialUiDatePicker({
  dateSeparator = "-",
  onChange,
  Action,
}: MaterialUiDatePickerProps) {
  const [error, setError] = useState(false);
  const handleChange = useCallback(
    (dates: (Date | null)[]) => {
      if (dates.filter((dates) => !!dates).length !== 2) {
        setError(true);
        return;
      }

      onChange(dates as Date[]);
      setError(false);
    },
    [onChange],
  );

  return (
    <MaterialUiThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
        <DateRangePicker
          // label="periode contrat" TODO: fix css
          slots={{
            field: SingleInputDateRangeField,
            shortcuts: ({ className }) =>
              Action && <Action className={className} />,
          }}
          onChange={handleChange}
          slotProps={{
            field: {
              dateSeparator,
            },
            textField: {
              InputProps: {
                endAdornment: <CalendarWeek />,
                sx: {
                  borderRadius: 2,
                  outline: "none !important",
                },
              },

              sx: {
                "& .MuiOutlinedInput-notchedOutline": {
                  outline: "none",
                  border: `0.0625rem solid ${colors["colors/borders/cells/cells"]} !important`,
                  boxShadow: "2px 1px 4px 0px rgba(122, 122, 122, 0.08)",
                },
                "& .MuiOutlinedInput-root:hover ": {
                  backgroundColor: `${colors["colors/button/secondary/hover"]} !important`,
                },
                "& .MuiOutlinedInput-root ": {
                  minWidth: "17rem",
                },
              },
              color: error ? "error" : "primary",
            },
          }}
        />
      </LocalizationProvider>
    </MaterialUiThemeProvider>
  );
}
