import {
  capitalize,
  dateFormatterDayMonthYearLong2Digits,
  LaunchPage,
  MaterialUiColumnsProps,
  MaterialUiTable,
  renderCellWithLabel,
  sortByDateTime,
  Spacer,
  Tag,
  usePersistMuiTableState,
} from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectCompany } from "../homeSlice";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  analyticVehiclesAsync,
  selectAnalyticsVehiclesList,
  selectAnalyticsVehiclesListStatus,
} from "./fleetSlice";
import { AnalyticsVehicles, AnalyticsVehiclesDisplayed } from "./fleetApi";
import { AnalyticsVehicleSidePanel } from "./SidePanel";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import styled from "styled-components";
import Car from "@qivia/ui/src/designSystem/assets/Car.png";
import { documentDownloadXlsxAsync } from "../../../libSlice/downloadFileXslx";
import {
  formatCurrency,
  formatDate,
  formatMileage,
  formatMonth,
  formatPercent,
  getDate,
  hasBadNumberValue,
} from "./../../../libSlice/materialUiFormats";
import { colors } from "@qivia/ui/src/styles/figmaColors";

export const AnalyticsVehiclesTab = () => {
  const { t } = useTranslation();
  const params = useParams();
  const company = useAppSelector(selectCompany);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const analyticsVehicleListInit = useAppSelector(selectAnalyticsVehiclesList);
  const analyticsVehicleListStatus = useAppSelector(
    selectAnalyticsVehiclesListStatus,
  );
  const [listDisplayed, setListDisplayed] = useState<
    AnalyticsVehiclesDisplayed[] | null
  >(null);

  const pageName = "fleet.analytics";
  useEffect(() => {
    if (company && !company.hasAnalytics) {
      navigate(`/home`);
    }
  }, [company, navigate]);

  useEffect(() => {
    if (company) {
      void dispatch(analyticVehiclesAsync(company.uuid));
    }
  }, [dispatch, company]);

  const apiRef = useGridApiRef();
  const key = "customers-grid";
  const aggregation = {
    model: {
      reference: "size",
      registrationNumber: "size",
      driverName: "size",
      mileage: "avg",
      mensualMileage: "avg",
      monthlyMileageKmVariation: "avg",
      mileageDeviationPercentage: "avg",
      mileageVariationEndContract: "avg",
      additionalCostEndContract: "sum",
      additionalCostPerKm: "avg",
    },
  };
  const dataGridState = usePersistMuiTableState(
    apiRef,
    key,
    pageName,
    aggregation,
  );

  const findKeysToTranslate = useCallback(
    (key: keyof Partial<AnalyticsVehiclesDisplayed>, value: string) => {
      switch (key) {
        case "contractStartDate":
        case "contractEndDate":
          return dateFormatterDayMonthYearLong2Digits(new Date(value));
        case "mileageDeviation":
          return t(`fleet.analytics.deviation.${value}`);
        default:
          return value;
      }
    },
    [t],
  );

  const keysToTranslate = useMemo(
    () =>
      [
        "contractStartDate",
        "contractEndDate",
        "mileageDeviation",
      ] as (keyof AnalyticsVehiclesDisplayed)[],
    [],
  );

  const analyticsVehicleList: AnalyticsVehicles[] = useMemo(() => {
    const rowsWithId = analyticsVehicleListInit.map((av) => {
      let newDict: AnalyticsVehicles & { id: string } = {
        ...av,
        id: av.uuid,
      };
      Object.entries(av).forEach(([key, value]) => {
        if (keysToTranslate.includes(key as keyof AnalyticsVehiclesDisplayed)) {
          newDict = {
            ...newDict,
            [key]: value
              ? findKeysToTranslate(
                  key as keyof AnalyticsVehiclesDisplayed,
                  value as string,
                )
              : "",
          };
        }
      });
      return newDict;
    });

    return sortByDateTime(rowsWithId, "desc", "createdAt");
  }, [analyticsVehicleListInit, findKeysToTranslate, keysToTranslate]);

  useEffect(() => {
    if (analyticsVehicleListInit && analyticsVehicleListStatus === "success") {
      const analyticsVehiclesTimeSorted = sortByDateTime(
        analyticsVehicleList,
        "desc",
        "createdAt",
      );

      setListDisplayed(analyticsVehiclesTimeSorted);
    }
  }, [
    analyticsVehicleList,
    analyticsVehicleListInit,
    analyticsVehicleListStatus,
  ]);

  const searchBarProps = {
    values: analyticsVehicleList,
    setFilterValues: setListDisplayed,
    keysToIgnore: [
      "uuid" as const,
      "date" as const,
      "vehicleUuid" as const,
      "lastMileageDate" as const,
      "monthlyMileage" as const,
      "vehicleType" as const,
      "status" as const,
      "model" as const,
      "averageConsumption" as const,
      "critAir" as const,
      "averageEmission" as const,
      "energyClass" as const,
      "marque" as const,
      "modele" as const,
      "energie" as const,
      "createdAt" as const,
    ],
  };

  const downloadViewAction = useCallback(
    (file: Buffer | undefined) => {
      if (!file) return;
      void dispatch(
        documentDownloadXlsxAsync({
          buffer: file,
          fileName: "QIVIA_" + t("fleet.title") + ".xlsx",
        }),
      );
    },
    [t, dispatch],
  );

  const columnsDisplayed: {
    [index in keyof AnalyticsVehiclesDisplayed]: MaterialUiColumnsProps;
  } = useMemo(() => {
    return {
      reference: {
        type: "string",
        width: 250,
        valueFormatter: (value) =>
          value && (
            <StyledDivImg>
              <StyledImg src={Car} alt="Car" />
              <Spacer x={0.5} />
              <Tag
                textColor={colors["colors/text/black"]}
                backgroundColor={
                  colors["colors/surfaces/background/background_level0"]
                }
                text={value}
                borderColor={colors["colors/borders/cells/cells"]}
              />
            </StyledDivImg>
          ),
      },
      registrationNumber: {
        type: "string",
      },
      driverName: {
        type: "string",
        width: 200,
      },
      mileage: {
        type: "number",
        valueFormatter: formatMileage,
      },
      mensualMileage: {
        type: "number",
        valueFormatter: formatMileage,
      },
      mileageDeviation: {
        type: undefined,
        width: 200,
        valueFormatter: (value: string) => value && capitalize(value),
      },
      monthlyMileageKmVariation: {
        type: "number",
        width: 220,
        valueFormatter: (value, row) =>
          `${formatMileage(value)} ${row["mensualContractMileage"] ? "/ " + formatMileage(row["mensualContractMileage"] as number) : ""}`,
      },
      mileageDeviationPercentage: {
        type: "number",
        width: 220,
        valueFormatter: formatPercent,
      },
      projectionEndOfContract: {
        type: "number",
        width: 250,
        valueFormatter: (value, row) =>
          `${formatMileage(value)} ${row["contractMileage"] ? "/ " + formatMileage(row["contractMileage"] as number) : ""}`,
      },
      mileageVariationEndContract: {
        type: "number",
        width: 280,
        valueFormatter: formatMileage,
      },
      additionalCostEndContract: {
        type: "number",
        width: 200,
        renderCell: (p) => renderCellWithLabel(p, "Somme"),
        valueFormatter: formatCurrency,
      },
      additionalCostPerKm: {
        type: "number",
        valueFormatter: formatCurrency,
      },
      consumedContractDuration: {
        type: "number",
        width: 250,
        valueFormatter: (value, row) =>
          `${hasBadNumberValue(value) ? "" : value} ${row["contractDurationMonth"] ? "/ " + formatMonth(row["contractDurationMonth"] as number) : ""}`,
      },
      remainingContractDuration: {
        type: "number",
        valueFormatter: formatMonth,
      },
      contractStartDate: {
        type: "date",
        valueGetter: getDate,
        valueFormatter: formatDate,
      },
      contractEndDate: {
        type: "date",
        valueGetter: getDate,
        valueFormatter: formatDate,
      },
    };
  }, []);

  const sidePanelAction = useCallback(
    (props: Partial<AnalyticsVehicles>) => {
      navigate(`/home/fleet/analytics/${props.uuid}`);
    },
    [navigate],
  );

  if (!listDisplayed) return <LaunchPage hasBorderRadius={true} />;
  if (params.tab !== "analytics") {
    return;
  }

  return (
    <>
      <MaterialUiTable<
        keyof AnalyticsVehiclesDisplayed,
        AnalyticsVehiclesDisplayed
      >
        rows={listDisplayed}
        columns={columnsDisplayed}
        pageName={pageName}
        sidePanelAction={sidePanelAction}
        dataGridState={dataGridState}
        searchBar={searchBarProps}
        apiRef={apiRef}
        downloadViewAction={downloadViewAction}
      />
      <AnalyticsVehicleSidePanel />
    </>
  );
};

const StyledDivImg = styled.div`
  display: flex;
  align-items: center;
`;

const StyledImg = styled.img`
  filter: grayscale(100%);
  width: 2rem;
  height: 60px;
  object-fit: contain;
  padding-bottom: 2.5px;
`;
