import { TextCapitalized } from "../../text/TextCapitalized";
import styled from "styled-components";
import { typographies } from "../../../../styles/figmaTypographies";
import { Spacer } from "../../Spacer";

interface LegendPayload {
  value: string;
  color: string;
}

export const CustomLineChartLegend = ({
  payload,
}: {
  payload?: LegendPayload[];
}) => {
  if (payload && payload.length > 0) {
    return (
      <StyledLegend>
        <Spacer x={1} />
        {payload.map((entry, index) => (
          <div key={index + "legend"}>
            <StyledRow>
              <Spacer x={0.5} />
              <StyledColor color={entry.color}>
                <TextCapitalized>{entry.value}</TextCapitalized>
              </StyledColor>
            </StyledRow>
          </div>
        ))}
      </StyledLegend>
    );
  }
  return null;
};

const StyledRow = styled.div`
  display: flex;
  align-items: center;
`;

const StyledColor = styled.div<{ color: string }>`
  color: ${({ color }) => color};
`;

const StyledLegend = styled.div`
  display: flex;
  ${typographies["Body/S"]}
  justify-content: flex-end;
`;
