export type SortingDirectionType = "asc" | "desc";

export type SortByDateType = {
  date: string;
  createdAt: string;
  contractStartDate: string | null;
  contractEndDate: string | null;
  dueDate: string;
};

export type SortByNumericType = {
  amount: number | null;
  mileage: number | null;
  mensualMileage: number | null;
  monthlyMileageKmVariation: number | null;
  mileageDeviationPercentage: number | null;
  projectionEndOfContract: number | null;
  mileageVariationEndContract: number | null;
  additionalCostEndContract: number | null;
  additionalCostPerKm: number | null;
  consumedContractDuration: number | null;
  remainingContractDuration: number | null;
  vehicleCount: number;
  totalAmount: number;
  closingBalance: number;
  latestMileage: number | null;
  transactionsAmountTtc: number | null;
  transactionsNumber: number | null;
};

export type SortByAlphabeticalOrderType = {
  merchantName: string;
  registrationNumber: string | null;
  vehicle: string;
  rule: string;
  name: string;
  reference: string | null;
  driverName: string | null;
  mileageDeviation: string | null;
  fileName: string;
  vehicleInfo: string;
};

export const sortByDateTime = <T extends Partial<SortByDateType>>(
  initialPayload: T[],
  sortingDirection: SortingDirectionType,
  column: keyof SortByDateType = "date",
): T[] => {
  const payloadToSort = [...initialPayload];
  return payloadToSort.sort((a, b) => {
    if (!a[column] && !b[column]) return 0;
    if (!a[column]) return 1;
    if (!b[column]) return -1;
    return sortingDirection === "asc"
      ? new Date(a[column]).getTime() - new Date(b[column]).getTime()
      : new Date(b[column]).getTime() - new Date(a[column]).getTime();
  });
};

export const sortByNumeric = <T extends Partial<SortByNumericType>>(
  initialPayload: T[],
  sortingDirection: SortingDirectionType,
  column: keyof SortByNumericType,
) => {
  const payloadToSort = [...initialPayload];

  return payloadToSort.sort((a, b) => {
    if (a[column] === null && b[column] === null) return 0;
    if (a[column] === null) return 1;
    if (b[column] === null) return -1;
    return sortingDirection === "asc"
      ? a[column]! - b[column]!
      : b[column]! - a[column]!;
  });
};

export const sortByAlphabeticalOrder = <
  T extends Partial<SortByAlphabeticalOrderType>,
>(
  initialPayload: T[],
  sortingDirection: SortingDirectionType,
  column: keyof SortByAlphabeticalOrderType,
) => {
  const payloadToSort = [...initialPayload];

  return payloadToSort.sort((a, b) => {
    if (!a[column] && !b[column]) return 0;
    if (!a[column]) return 1;
    if (!b[column]) return -1;
    return sortingDirection === "asc"
      ? a[column].localeCompare(b[column])
      : b[column].localeCompare(a[column]);
  });
};
