import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  ButtonDS,
  DownloadOutlined,
  EmptyTableComponent,
  LaunchPage,
  SortByAlphabeticalOrderType,
  SortByDateType,
  SortByNumericType,
  SortingDirectionType,
  Spacer,
  TableDS,
  TextCapitalized,
  dateFormatterDayMonthYearLong2Digits,
  sortByAlphabeticalOrder,
  sortByDateTime,
  sortByNumeric,
  triggerToast,
} from "@qivia/ui";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { selectCompany } from "../homeSlice";
import {
  depositExportAsync,
  depositsListAsync,
  documentDownloadAsync,
  selectDepositExportLink,
  selectDepositExportStatus,
  selectDepositsList,
  selectDepositsListStatus,
} from "./billingsSlice";
import { PublicDeposits, PublicDepositsDisplayed } from "./billingsAPI";

export const DepositTab = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const params = useParams();
  const company = useAppSelector(selectCompany);

  const [sortingDirection, setSortingDirection] =
    useState<SortingDirectionType>("desc");

  const [depositsListSorted, setDepositsListSorted] = useState<
    PublicDepositsDisplayed[] | null
  >([]);
  const depositsListStatus = useAppSelector(selectDepositsListStatus);
  const depositsListInit: PublicDeposits[] = useAppSelector(selectDepositsList);
  const depositExportLink = useAppSelector(selectDepositExportLink);
  const depositsExportStatus = useAppSelector(selectDepositExportStatus);

  const depositsList: PublicDeposits[] = useMemo(() => {
    return sortByDateTime(depositsListInit, "desc");
  }, [depositsListInit]);

  useEffect(() => {
    if (company) {
      void dispatch(depositsListAsync(company.uuid));
    }
  }, [dispatch, company]);

  useEffect(() => {
    if (depositsExportStatus === "success") {
      triggerToast(t("billings.deposits.upload.success") || "", "valid");
    } else if (depositsExportStatus === "failed") {
      triggerToast(t("billings.deposits.upload.failure") || "", "error");
    }
  }, [depositsExportStatus, t]);

  useEffect(() => {
    if (depositsList && depositsListStatus === "success") {
      const depositsListSorted = sortByDateTime(depositsList, "desc");
      setDepositsListSorted(depositsListSorted);
    }
  }, [depositsListStatus, depositsList]);

  useEffect(() => {
    if (depositExportLink.presignedUrl && depositExportLink.fileName) {
      void dispatch(documentDownloadAsync(depositExportLink));
    }
  }, [depositExportLink, dispatch]);

  const sortByDepositsDate = useCallback(
    (column?: string) => {
      if (!depositsListSorted) return;
      const depositsDateTimeSorted = sortByDateTime(
        depositsListSorted,
        sortingDirection,
        column as keyof SortByDateType,
      );
      setDepositsListSorted(depositsDateTimeSorted);
      setSortingDirection(sortingDirection === "asc" ? "desc" : "asc");
    },
    [depositsListSorted, sortingDirection],
  );

  const sortDepositsByAlphabeticalOrder = useCallback(
    (column: string) => {
      if (!depositsListSorted) return;
      const vehiclesSorted = sortByAlphabeticalOrder(
        depositsListSorted,
        sortingDirection,
        column as keyof SortByAlphabeticalOrderType,
      );
      setDepositsListSorted(vehiclesSorted);
      setSortingDirection(sortingDirection === "asc" ? "desc" : "asc");
    },
    [depositsListSorted, sortingDirection],
  );

  const sortDepositsByAmount = useCallback(
    (column: string) => {
      if (!depositsListSorted) return;

      const transactionsAmountSorted = sortByNumeric(
        depositsListSorted,
        sortingDirection,
        column as keyof SortByNumericType,
      );

      setDepositsListSorted(transactionsAmountSorted);
      setSortingDirection(sortingDirection === "asc" ? "desc" : "asc");
    },
    [depositsListSorted, sortingDirection],
  );

  const headers = {
    amount: {
      text: t("billings.deposits.table.column.amount"),
      sortAction: () => sortDepositsByAmount("amount"),
    },
    fileName: {
      text: t("billings.deposits.table.column.fileName"),
      sortAction: () => sortDepositsByAlphabeticalOrder("fileName"),
    },
    date: {
      text: t("billings.deposits.table.column.date"),
      sortAction: () => sortByDepositsDate(),
    },
    createdAt: {
      text: t("billings.deposits.table.column.createdAt"),
      sortAction: () => sortByDepositsDate("createdAt"),
    },
    bucketFileName: {
      text: t("billings.deposits.table.column.download"),
      sortAction: () => sortDepositsByAlphabeticalOrder("bucketFileName"),
    },
  };

  if (params.tab !== "deposits") {
    return;
  }
  if (!depositsListSorted) return <LaunchPage hasBorderRadius={true} />;

  const render =
    (row: PublicDepositsDisplayed) => (key: keyof PublicDepositsDisplayed) => {
      switch (key) {
        case "date":
        case "createdAt":
          return (
            <TextCapitalized>
              {dateFormatterDayMonthYearLong2Digits(new Date(row[key]))}
            </TextCapitalized>
          );
        case "amount":
          return (
            <StyledAmount>
              {Number(row[key] / 100).toString() + " € TTC"}
              <Spacer x={0.75} />
            </StyledAmount>
          );
        case "bucketFileName":
          return (
            <ButtonDS
              sizeButton={"S"}
              format={"hug"}
              buttonType={"secondary"}
              singleIcon={{ icon: <DownloadOutlined />, size: "S" }}
              onClick={() => {
                void dispatch(
                  depositExportAsync({
                    bucketFileName: row[key] ?? "",
                    fileName: row["fileName"],
                  }),
                );
              }}
            />
          );
        default:
          return row[key];
      }
    };

  return (
    <TableDS<keyof PublicDepositsDisplayed, PublicDepositsDisplayed>
      data={depositsListSorted}
      headers={headers}
      render={render}
      emptyContent={<EmptyTableComponent pageName={"billings.deposits"} />}
    />
  );
};

const StyledAmount = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
