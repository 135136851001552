import { Spacer } from "../Spacer";
import { TextCapitalized } from "../text/TextCapitalized";
import { colors } from "../../../styles/figmaColors";
import { typographies } from "../../../styles/figmaTypographies";
import {
  Tooltip,
  Legend,
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
} from "recharts";
import styled from "styled-components";
import { CATEGORY_COLORS } from "./ChartColors";

export interface PieChartData {
  name: string;
  value: number;
}

interface PieChartDSProps {
  categoryData: PieChartData[];
  unit: string;
}

interface TooltipPayload {
  name: string;
  value: number;
}

interface LegendPayload {
  value: string;
  color: string;
}

export const PieChartDS = (props: PieChartDSProps) => {
  const categoryDataWithColors = props.categoryData.map((category, index) => ({
    ...category,
    color: colors[CATEGORY_COLORS[index % CATEGORY_COLORS.length]],
  }));

  return (
    <ResponsiveContainer>
      <PieChart width={500} height={700}>
        <Pie
          data={categoryDataWithColors}
          dataKey="value"
          innerRadius={60}
          outerRadius={90}
          labelLine={false}
        >
          {categoryDataWithColors.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        <Tooltip content={<CustomTooltip unit={props.unit} />} />
        <Legend
          layout="vertical"
          verticalAlign="middle"
          align="left"
          content={<CustomLegend />}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

const CustomTooltip = ({
  active,
  payload,
  unit,
}: {
  active?: boolean;
  payload?: TooltipPayload[];
  unit: string;
}) => {
  if (active && payload && payload.length > 0) {
    return (
      <StyledTooltip>
        <Spacer y={0.75} />
        <StyledRow>
          <Spacer x={0.75} />
          {payload.map((entry, index) => (
            <div key={index + "tooltip"}>
              <TextCapitalized>{entry.name} :</TextCapitalized>
              <Spacer y={0.5} />
              <TextCapitalized>
                {Number(entry.value).toFixed(2)} {unit}
              </TextCapitalized>
            </div>
          ))}
          <Spacer x={0.75} />
        </StyledRow>
        <Spacer y={0.75} />
      </StyledTooltip>
    );
  }
  return null;
};

const CustomLegend = ({ payload }: { payload?: LegendPayload[] }) => {
  if (payload && payload.length > 0) {
    return (
      <StyledLegend>
        {payload.map((entry, index) => (
          <div key={index + "legend"}>
            <StyledRow>
              <StyledDot color={entry.color}></StyledDot>
              <Spacer x={0.5} />
              <TextCapitalized>{entry.value}</TextCapitalized>
            </StyledRow>
            <Spacer y={0.5} />
          </div>
        ))}
      </StyledLegend>
    );
  }
  return null;
};

const StyledTooltip = styled.div`
  display: flex;
  flex-direction: column;
  ${typographies["Body/L"]}
  color: ${colors["colors/text/black"]};
  background-color: ${colors["colors/surfaces/background/background_level0"]};
  border-radius: 0.5rem;
  border: 0.0625rem solid ${colors["colors/borders/cards/cards"]};
`;

const StyledLegend = styled.div`
  display: flex;
  flex-direction: column;
  ${typographies["Body/S"]}
  color: ${colors["colors/text/black"]};
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
`;

const StyledDot = styled.div<{ color?: string }>`
  display: flex;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  background-color: ${({ color }) =>
    color ? color : `${colors["colors/text/black"]}`};
`;
