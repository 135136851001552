import { TagOrBadge } from "./TagOrBadge";

export interface BadgeProps {
  text: string | JSX.Element;
  backgroundColor: string;
  textColor: string;
  allWidth?: boolean;
  typo?: string;
}

export const Badge = (props: BadgeProps) => {
  return (
    <TagOrBadge
      type={"BADGE"}
      textColor={props.textColor}
      text={props.text}
      backgroundColor={props.backgroundColor}
      allWidth={props.allWidth}
      typo={props.typo}
    />
  );
};
