import {
  CheckBox,
  EuroSymbolOutlined,
  NumericInputDS,
  PageDS,
  SelectTimeDS,
  SideMenu,
  Spacer,
  Switch,
  TextCapitalized,
  TitleInputDS,
  StyledFlex,
} from "@qivia/ui";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  AmountType,
  DaysOfWeekType,
  RuleFormData,
  CategoryType,
  AllowedPeriodType,
  AmountLimit,
  PublicRuleType,
  AllowedCategoryType,
  CategoryError,
  ExpensesLimitType,
  GeographicControlType,
  RuleDefaultData,
  MerchantGroupsType,
  MerchantGroups,
  NotificationType,
} from "./rulesAPI";
import {
  allowedPeriodsDatas,
  CategoryInfoType,
  merchantGroupsData,
} from "./libDefaultDatas";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import { typographies } from "@qivia/ui/src/styles/figmaTypographies";
import { RuleTemplate } from "./components/ruleTemplate";
import { StyledBodyLeft } from "./components/styledBodyLeft";
import { StyledBodyRight } from "./components/styledBodyRight";
import { StyledNumericInput } from "./components/styledNumericInput";
import { SelectInputAmountType } from "./components/selectInputAmountType";
import { SpecificAmounts } from "./components/specificAmounts";
import { GeographicControl } from "./components/geographicalControl";
import { merchantIcons } from "../../../libSlice/merchantGroups";

export type MerchantGroupsSwitch = "OTHER" | MerchantGroups;

const hasDuplicateName = (newName: string, rulesList: PublicRuleType[]) => {
  const hasDuplicateName = rulesList.find(
    (rule) => rule.name.toLowerCase() === newName.toLowerCase(),
  );
  return hasDuplicateName;
};

const formatCategories = (categories: AllowedCategoryType[]) => {
  return categories.map((category) => {
    return {
      ...category,
      amountLimit:
        category.amountLimit === "" || category.amountLimit === 0
          ? null
          : Number(category.amountLimit) * 100,
      typeLimit:
        category.amountLimit === "" || category.amountLimit === 0
          ? null
          : category.typeLimit,
    };
  });
};

export const AddRuleModal = (props: {
  onCloseModal: () => void;
  onSubmit: (data: RuleFormData) => void;
  values: RuleDefaultData;
  rulesList: PublicRuleType[];
}) => {
  const { t } = useTranslation();
  const [selectedCategory, setSelectedCategory] = useState<string>(
    t(`rules.modal.expensesLimits.title`),
  );

  const [expensesLimits, setExpensesLimits] = useState<ExpensesLimitType>(
    props.values.defaultExpensesLimit,
  );
  const [geographicControl, setGeographicControl] =
    useState<GeographicControlType>(props.values.defaultGeographicControl);
  const [nationalHolidays, setNationalHolidays] = useState<boolean>(
    props.values.defaultNationalHolidays,
  );

  const [merchantGroups, setMerchantGroups] = useState<MerchantGroupsType>(
    props.values.defaultMerchantGroups,
  );

  const [allowedCategories, setAllowedCategories] = useState<
    AllowedCategoryType[]
  >(props.values.defaultAllowedCategories);
  const [allowedPeriods, setAllowedPeriods] = useState<AllowedPeriodType[]>(
    props.values.defaultAllowedPeriods,
  );
  const [ruleName, setRuleName] = useState<string>(
    props.values.defaultRuleName,
  );
  const [notifications, setNotifications] = useState<NotificationType>(
    props.values.defaultNotifications,
  );

  const [specificsAmountsError, setSpecificsAmountsError] = useState<
    CategoryError[]
  >(
    props.values.rulesCategoryInfoList.map((expense) => {
      return {
        category: expense.category,
        error: false,
      };
    }),
  );

  const hasSpecificAmountEmpty: boolean = useMemo(() => {
    return (
      specificsAmountsError.filter((category) => category.error === true)
        .length > 0
    );
  }, [specificsAmountsError]);

  const [displayError, setDisplayError] = useState<boolean>(false);

  const isAllowedPeriodsError: boolean = useMemo(() => {
    return (
      allowedPeriods.filter((allowedPeriod) => {
        return (
          allowedPeriod.startTime >= allowedPeriod.endTime &&
          allowedPeriod.endTime !== "00:00:00"
        );
      }).length > 0
    );
  }, [allowedPeriods]);

  const isRuleDataFilled: boolean = useMemo(() => {
    return (
      ruleName !== "" &&
      !isAllowedPeriodsError &&
      !hasDuplicateName(ruleName, props.rulesList) &&
      expensesLimits.amountLimit !== "" &&
      !hasSpecificAmountEmpty
    );
  }, [
    ruleName,
    isAllowedPeriodsError,
    props.rulesList,
    expensesLimits,
    hasSpecificAmountEmpty,
  ]);

  const ruleData = useMemo(() => {
    return {
      name: ruleName,
      onlyFrance: true,
      nationalHolidays,
      amountLimit: Number(expensesLimits.amountLimit) * 100,
      typeLimit: expensesLimits.typeLimit,
      allowedPeriods,
      allowedCountries: geographicControl,
      categories: formatCategories(allowedCategories),
      areOtherMerchantsAuthorized: merchantGroups.areOtherMerchantsAuthorized,
      unauthorizedMerchantGroups: merchantGroups.unauthorizedMerchantGroups,
      areSupportingDocumentsMandatory:
        notifications.areSupportingDocumentsMandatory,
    };
  }, [
    ruleName,
    geographicControl,
    nationalHolidays,
    expensesLimits.amountLimit,
    expensesLimits.typeLimit,
    allowedPeriods,
    allowedCategories,
    merchantGroups.areOtherMerchantsAuthorized,
    merchantGroups.unauthorizedMerchantGroups,
    notifications,
  ]);

  const expensesLimitsRef = useRef<HTMLDivElement | null>(null);
  const authorizedCategoryRef = useRef<HTMLDivElement | null>(null);
  const allowedPeriodsRef = useRef<HTMLDivElement | null>(null);
  const geographicControlRef = useRef<HTMLDivElement | null>(null);
  const notificationsRef = useRef<HTMLDivElement | null>(null);
  const merchantGroupsRef = useRef<HTMLDivElement | null>(null);

  const categoriesMenu = [
    { name: "expensesLimits", titleRef: expensesLimitsRef },
    { name: "authorizedCategory", titleRef: authorizedCategoryRef },
    { name: "merchantGroups", titleRef: merchantGroupsRef },
    { name: "allowedPeriods", titleRef: allowedPeriodsRef },
    { name: "notifications", titleRef: notificationsRef },
    { name: "geographicControl", titleRef: geographicControlRef },
  ];
  const categoriesMenuOptions = categoriesMenu.map((category) => {
    const firstOptionPosition = categoriesMenu[0].titleRef.current?.offsetTop;
    const categoryPosition =
      category.titleRef.current?.offsetTop &&
      firstOptionPosition &&
      category.titleRef.current?.offsetTop - firstOptionPosition;

    return {
      label: t(`rules.modal.${category.name}.title`),
      action: () =>
        category.titleRef &&
        category.titleRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        }),
      position: categoryPosition,
    };
  });

  const cta = {
    title: t("rules.modal.button.save"),
    action: () => {
      {
        setDisplayError(true);
        isRuleDataFilled &&
          props.onSubmit({
            ...ruleData,
            allowedPeriods:
              ruleData.allowedPeriods.length === 0
                ? allowedPeriodsDatas
                : ruleData.allowedPeriods,
          });
      }
    },
    isDisabled: !isRuleDataFilled,
  };

  return (
    <PageDS
      title={
        <TitleInputDS
          value={ruleName}
          onChange={(e: string) => setRuleName(e)}
          placeholder={t("rules.modal.name.placeholder") || ""}
          error={
            (displayError && ruleName === "" && t("rules.modal.name.empty")) ||
            (displayError &&
              hasDuplicateName(ruleName, props.rulesList) &&
              t("rules.modal.name.alreadyExist", {
                rule: ruleName.toLowerCase(),
              }).toString()) ||
            undefined
          }
        />
      }
      cta={cta}
      titleElement={{
        closeButtonAction: () => props.onCloseModal(),
      }}
      withoutSupport
    >
      <StyledRuleModalContainer>
        <SideMenu
          options={categoriesMenuOptions}
          selectedOption={selectedCategory}
          setSelectedOption={setSelectedCategory}
        />
        <Spacer x={6} />
        <StyledModalBody
          onScroll={(e) => {
            const topScrollPosition =
              (e.target as HTMLDivElement).scrollTop + 10;
            const categories = categoriesMenuOptions.filter(
              (c) =>
                c.position !== undefined && topScrollPosition >= c.position,
            );
            const lastCategory = categories[categories.length - 1];
            if (lastCategory) {
              setSelectedCategory(lastCategory.label);
            }
          }}
        >
          <ExpensesLimits
            oldValue={expensesLimits}
            onChange={(value) => setExpensesLimits(value)}
            displayError={displayError}
            titleRef={expensesLimitsRef}
          />
          <AllowedCategories
            oldValue={allowedCategories}
            onChange={(allowedCategories) => {
              setAllowedCategories(allowedCategories);
            }}
            categoryInfoList={props.values.rulesCategoryInfoList}
            displayError={displayError}
            specificsAmountsError={specificsAmountsError}
            setSpecificsAmountsError={setSpecificsAmountsError}
            titleRef={authorizedCategoryRef}
          />
          <MerchantGroupsControl
            oldValueMerchantGroups={merchantGroups}
            onChangeMerchantGroups={(value) => setMerchantGroups(value)}
            titleRef={merchantGroupsRef}
          />
          <ExpensesControl
            oldValueAllowedPeriods={allowedPeriods}
            onChangeAllowedPeriods={(value) => setAllowedPeriods(value)}
            oldValueNationalHolidays={nationalHolidays}
            onChangeNationalHolidays={(value) => setNationalHolidays(value)}
            titleRef={allowedPeriodsRef}
          />
          <NotificationsControl
            oldValue={notifications}
            onChange={(value) => setNotifications(value)}
            titleRef={notificationsRef}
          />
          <GeographicControl
            value={geographicControl}
            onChange={(value) => setGeographicControl(value)}
            titleRef={geographicControlRef}
          />
        </StyledModalBody>
      </StyledRuleModalContainer>
    </PageDS>
  );
};

const ExpensesLimits = (props: {
  oldValue: ExpensesLimitType;
  onChange: (value: ExpensesLimitType) => void;
  displayError: boolean;
  titleRef: MutableRefObject<HTMLDivElement | null>;
}) => {
  const { onChange, oldValue } = props;
  const { t } = useTranslation();

  const updateAmountLimit = useCallback(
    (limit: number | "") => {
      const newValueState = { ...oldValue, amountLimit: limit as AmountLimit };
      onChange(newValueState);
    },
    [oldValue, onChange],
  );

  const updateAmountType = useCallback(
    (typeLimit: AmountType) => {
      const newValueState = { ...oldValue, typeLimit: typeLimit };
      onChange(newValueState);
    },
    [oldValue, onChange],
  );

  return (
    <CategorieRuleTemplate
      title={t("rules.modal.expensesLimits.title")}
      titleRef={props.titleRef}
    >
      <RuleTemplate>
        <>
          <StyledBodyLeft>
            <TextCapitalized>
              {t("rules.modal.expensesLimits.body")}
            </TextCapitalized>
            <Spacer x={1} />
          </StyledBodyLeft>
          <Spacer x={1} />
          <StyledBodyRight>
            <StyledNumericInput>
              <NumericInputDS
                adornent={<EuroSymbolOutlined />}
                value={props.oldValue.amountLimit}
                placeholder={t("rules.modal.amountLimit.placeholder")}
                update={updateAmountLimit}
                error={
                  props.displayError && props.oldValue.amountLimit === ""
                    ? t("rules.modal.amountLimit.error")
                    : undefined
                }
              />
            </StyledNumericInput>
            <Spacer x={1} />
            <SelectInputAmountType
              typeLimit={props.oldValue.typeLimit}
              onChange={updateAmountType}
            />
          </StyledBodyRight>
        </>
      </RuleTemplate>
    </CategorieRuleTemplate>
  );
};

const AllowedCategories = (props: {
  oldValue: AllowedCategoryType[];
  onChange: (allowedCategories: AllowedCategoryType[]) => void;
  categoryInfoList: CategoryInfoType[];
  displayError: boolean;
  setSpecificsAmountsError: Dispatch<SetStateAction<CategoryError[]>>;
  specificsAmountsError: CategoryError[];
  titleRef: MutableRefObject<HTMLDivElement | null>;
}) => {
  const { t } = useTranslation();
  const { onChange, oldValue } = props;

  const updateCategories = useCallback(
    (category: CategoryType) => {
      const categories = oldValue;

      const isAlreadyAllowed: boolean = categories
        .map((c) => c.category)
        .includes(category);

      if (isAlreadyAllowed) {
        onChange(categories.filter((c) => category !== c.category));
      } else {
        onChange([
          ...categories,
          { category, amountLimit: "", typeLimit: "DAILY" },
        ]);
      }
    },
    [onChange, oldValue],
  );

  const updateCategory = useCallback(
    (currentCategory: AllowedCategoryType) => {
      const categories = oldValue;
      const newValue = categories.map((category) => {
        if (category.category === currentCategory.category) {
          return currentCategory;
        } else {
          return category;
        }
      });
      onChange(newValue);
    },
    [oldValue, onChange],
  );

  return (
    <CategorieRuleTemplate
      title={t("rules.modal.authorizedCategory.title")}
      titleRef={props.titleRef}
    >
      <StyledColumn>
        {props.categoryInfoList.map((categoryInfo, index) => {
          const currentCategory = props.oldValue.find(
            (c) =>
              c.category.toLowerCase() === categoryInfo.category.toLowerCase(),
          );

          return (
            <div key={"category" + index}>
              <SpecificAmounts
                updateCategories={() => updateCategories(categoryInfo.category)}
                updateCategory={updateCategory}
                title={`rules.modal.authorizedCategory.${categoryInfo.category}`}
                categoryInfo={categoryInfo}
                currentCategory={currentCategory}
                displayError={props.displayError}
                specificsAmountsError={props.specificsAmountsError}
                setSpecificsAmountsError={props.setSpecificsAmountsError}
                index={index}
                key={"category" + index}
              />
              {index !== props.categoryInfoList.length - 1 && (
                <Spacer y={1.5} />
              )}
            </div>
          );
        })}
      </StyledColumn>
    </CategorieRuleTemplate>
  );
};

const MerchantGroupsControl = (props: {
  oldValueMerchantGroups: MerchantGroupsType;
  onChangeMerchantGroups: (value: MerchantGroupsType) => void;
  titleRef: MutableRefObject<HTMLDivElement | null>;
}) => {
  const { t } = useTranslation();

  return (
    <CategorieRuleTemplate
      title={t("rules.modal.merchantGroups.title")}
      titleRef={props.titleRef}
    >
      <RuleTemplate>
        <StyledColumn>
          <TextCapitalized>
            {t("rules.modal.merchantGroups.subTitle")}
          </TextCapitalized>
          <Spacer y={1} />
          <MerchantGroupsSwitchControl
            onChangeMerchantsUnauthorized={(value) => {
              props.onChangeMerchantGroups({
                ...props.oldValueMerchantGroups,
                unauthorizedMerchantGroups: value,
              });
            }}
            oldValueMerchantsUnauthorized={
              props.oldValueMerchantGroups.unauthorizedMerchantGroups
            }
            oldValueOtherMerchantsAuthorized={
              props.oldValueMerchantGroups.areOtherMerchantsAuthorized
            }
            onChangeOtherMerchantsAuthorized={(value) => {
              props.onChangeMerchantGroups({
                ...props.oldValueMerchantGroups,
                areOtherMerchantsAuthorized: value,
              });
            }}
          />
        </StyledColumn>
      </RuleTemplate>
    </CategorieRuleTemplate>
  );
};

const MerchantGroupsSwitchControl = (props: {
  oldValueMerchantsUnauthorized: MerchantGroups[];
  oldValueOtherMerchantsAuthorized: boolean;
  onChangeMerchantsUnauthorized: (value: MerchantGroups[]) => void;
  onChangeOtherMerchantsAuthorized: (value: boolean) => void;
}) => {
  const { t } = useTranslation();

  const [
    currentMerchantGroupsUnauthorized,
    setCurrentMerchantGroupsUnauthorized,
  ] = useState<MerchantGroupsSwitch[]>([
    ...props.oldValueMerchantsUnauthorized,
  ]);
  const {
    onChangeMerchantsUnauthorized,
    oldValueMerchantsUnauthorized,
    oldValueOtherMerchantsAuthorized,
    onChangeOtherMerchantsAuthorized,
  } = props;

  useEffect(() => {
    if (!oldValueOtherMerchantsAuthorized) {
      setCurrentMerchantGroupsUnauthorized([
        ...props.oldValueMerchantsUnauthorized,
        "OTHER",
      ]);
    } else {
      setCurrentMerchantGroupsUnauthorized(props.oldValueMerchantsUnauthorized);
    }
  }, [oldValueOtherMerchantsAuthorized, props.oldValueMerchantsUnauthorized]);

  const updateMerchantGroups = useCallback(
    (merchantGroup: MerchantGroupsSwitch) => {
      const currentMerchant = currentMerchantGroupsUnauthorized.find(
        (merchant) => {
          return merchant === merchantGroup;
        },
      );

      if (merchantGroup === "OTHER") {
        onChangeOtherMerchantsAuthorized(!oldValueOtherMerchantsAuthorized);
      } else if (currentMerchant) {
        onChangeMerchantsUnauthorized(
          oldValueMerchantsUnauthorized.filter(
            (merchant) => merchant !== merchantGroup,
          ),
        );
      } else {
        onChangeMerchantsUnauthorized([
          ...oldValueMerchantsUnauthorized,
          merchantGroup,
        ]);
      }
    },
    [
      oldValueMerchantsUnauthorized,
      onChangeMerchantsUnauthorized,
      onChangeOtherMerchantsAuthorized,
      currentMerchantGroupsUnauthorized,
      oldValueOtherMerchantsAuthorized,
    ],
  );

  return (
    <>
      {merchantGroupsData.map((merchantGroup, index) => {
        const merchantGroupUnauthorized =
          currentMerchantGroupsUnauthorized.find((oldMerchantGroup) => {
            return oldMerchantGroup === merchantGroup;
          });
        return (
          <StyledColumn key={index + "merchant groups column"}>
            <Spacer y={1.5} />
            <StyledFlex>
              <StyledBodyLeftMerchantGroup
                key={index + "merchant groups body left"}
              >
                {merchantIcons[merchantGroup as keyof typeof merchantIcons]}
                <Spacer x={0.75} />
                <TextCapitalized>
                  {t(`merchantGroups.${merchantGroup}`)}
                </TextCapitalized>
              </StyledBodyLeftMerchantGroup>
              <Spacer x={1} />
              <StyledBodyRight key={index + "merchant groups body right"}>
                <Switch
                  size="S"
                  checked={!merchantGroupUnauthorized}
                  onClick={() =>
                    updateMerchantGroups(merchantGroup as MerchantGroupsSwitch)
                  }
                  color={"GREEN"}
                />
              </StyledBodyRight>
            </StyledFlex>
          </StyledColumn>
        );
      })}
    </>
  );
};

const ExpensesControl = (props: {
  oldValueAllowedPeriods: AllowedPeriodType[];
  onChangeAllowedPeriods: (value: AllowedPeriodType[]) => void;
  oldValueNationalHolidays: boolean;
  onChangeNationalHolidays: (value: boolean) => void;
  titleRef: MutableRefObject<HTMLDivElement | null>;
}) => {
  const { t } = useTranslation();
  const [hasAllowedPeriods, setHasAllowedPeriods] = useState<boolean>(
    props.oldValueAllowedPeriods.length > 0,
  );

  return (
    <CategorieRuleTemplate
      title={t("rules.modal.allowedPeriods.title")}
      titleRef={props.titleRef}
    >
      <>
        <RuleTemplate>
          <StyledColumn>
            <StyledFlex>
              <StyledBodyLeft>
                <TextCapitalized>
                  {t("rules.modal.allowedPeriods.body")}
                </TextCapitalized>
              </StyledBodyLeft>
              <Spacer x={1} />
              <StyledBodyRight>
                <Switch
                  checked={hasAllowedPeriods}
                  onClick={() => {
                    setHasAllowedPeriods(!hasAllowedPeriods);
                    if (allowedPeriodsDatas) {
                      props.onChangeAllowedPeriods([]);
                    }
                  }}
                  size="S"
                  color={"GREEN"}
                />
              </StyledBodyRight>
            </StyledFlex>
            {hasAllowedPeriods && (
              <>
                <Spacer y={1} />
                <AllowedPeriodsDayOfWeek
                  onChange={(value) => props.onChangeAllowedPeriods(value)}
                  oldValue={props.oldValueAllowedPeriods}
                />
              </>
            )}
          </StyledColumn>
        </RuleTemplate>
        <Spacer y={1.5} />
        <NationalHolidays
          oldValue={props.oldValueNationalHolidays}
          onChange={(value) => props.onChangeNationalHolidays(value)}
        />
      </>
    </CategorieRuleTemplate>
  );
};

const NationalHolidays = (props: {
  oldValue: boolean;
  onChange: (value: boolean) => void;
}) => {
  const { t } = useTranslation();

  return (
    <RuleTemplate>
      <>
        <StyledBodyLeft>
          <TextCapitalized>
            {t("rules.modal.nationalHolidays.body")}
          </TextCapitalized>
        </StyledBodyLeft>
        <Spacer x={1} />
        <StyledBodyRight>
          <Switch
            checked={props.oldValue}
            onClick={() => props.onChange(!props.oldValue)}
            size="S"
            color={"GREEN"}
          />
        </StyledBodyRight>
      </>
    </RuleTemplate>
  );
};

const AllowedPeriodsDayOfWeek = (props: {
  oldValue: AllowedPeriodType[];
  onChange: (value: AllowedPeriodType[]) => void;
}) => {
  const { onChange, oldValue } = props;
  const { t } = useTranslation();

  const updateAllowedDay = useCallback(
    (dayOfWeek: string) => {
      const allowedPeriods = oldValue;

      const isDayAlreadyAllowed = allowedPeriods.find((allowedPeriod) => {
        return allowedPeriod.dayOfWeek === (dayOfWeek as DaysOfWeekType);
      });

      if (isDayAlreadyAllowed) {
        onChange(
          allowedPeriods.filter(
            (allowedPeriod) => allowedPeriod.dayOfWeek !== dayOfWeek,
          ),
        );
      } else {
        onChange([
          ...allowedPeriods,
          {
            dayOfWeek: dayOfWeek,
            startTime: "00:00:00",
            endTime: "00:00:00",
          },
        ]);
      }
    },
    [onChange, oldValue],
  );

  const updateStartTime = useCallback(
    (startTime: string, dayOfWeek: string) => {
      const oldAllowedPeriods = oldValue;

      const newAllowedPeriods = oldAllowedPeriods.map((allowedPeriod) => {
        if (allowedPeriod.dayOfWeek === dayOfWeek) {
          return {
            ...allowedPeriod,
            startTime,
          };
        } else return allowedPeriod;
      });

      onChange(newAllowedPeriods);
    },
    [oldValue, onChange],
  );

  const updateEndTime = useCallback(
    (endTime: string, dayOfWeek: string) => {
      const oldAllowedPeriods = oldValue;

      const newAllowedPeriods = oldAllowedPeriods.map((allowedPeriod) => {
        if (allowedPeriod.dayOfWeek === dayOfWeek) {
          return {
            ...allowedPeriod,
            endTime,
          };
        } else return allowedPeriod;
      });
      onChange(newAllowedPeriods);
    },
    [oldValue, onChange],
  );

  return (
    <>
      {allowedPeriodsDatas.map((allowedPeriod, index) => {
        const allowedPeriodExist = oldValue.find((oldAllowedPeriod) => {
          return oldAllowedPeriod.dayOfWeek === allowedPeriod.dayOfWeek;
        });

        const isAllowedPeriodError =
          allowedPeriodExist &&
          allowedPeriodExist.startTime >= allowedPeriodExist.endTime &&
          allowedPeriodExist.endTime !== "00:00:00";

        return (
          <StyledColumn key={index + "column"}>
            <Spacer y={0.75} />
            <StyledFlex>
              <StyledBodyLeft key={index + "body left"}>
                <CheckBox
                  keyProp={index + "day"}
                  text={t(
                    `rules.modal.dayOfWeek.${allowedPeriod.dayOfWeek.toLowerCase()}`,
                  )}
                  state={allowedPeriodExist ? "CHECKED" : "UNCHECKED"}
                  onClick={() => updateAllowedDay(allowedPeriod.dayOfWeek)}
                />
              </StyledBodyLeft>
              <Spacer x={1} />
              <StyledBodyRight key={index + "body right"}>
                <SelectTimeDS
                  beginningTime={0}
                  endingTime={24}
                  value={
                    allowedPeriodExist
                      ? allowedPeriodExist.startTime
                      : "00:00:00"
                  }
                  onChange={(value) =>
                    updateStartTime(
                      value ?? "00:00:00",
                      allowedPeriod.dayOfWeek,
                    )
                  }
                  disabled={!allowedPeriodExist}
                  error={isAllowedPeriodError}
                  key={index + "selectTimeRight"}
                />
                <Spacer x={1} />
                <StyledLinkedWord $disabled={!allowedPeriodExist}>
                  {t("rules.modal.dayOfWeek.time.linkWord")}
                </StyledLinkedWord>
                <Spacer x={0.5} />
                <SelectTimeDS
                  key={index + "selectTimeLeft"}
                  beginningTime={0}
                  endingTime={24}
                  value={
                    allowedPeriodExist ? allowedPeriodExist.endTime : "00:00:00"
                  }
                  onChange={(value) =>
                    updateEndTime(value ?? "00:00:00", allowedPeriod.dayOfWeek)
                  }
                  disabled={!allowedPeriodExist}
                  error={isAllowedPeriodError}
                />
              </StyledBodyRight>
            </StyledFlex>
            {isAllowedPeriodError && (
              <StyledError>
                <TextCapitalized>
                  {t("rules.modal.error.allowedPeriods")}
                </TextCapitalized>
              </StyledError>
            )}
          </StyledColumn>
        );
      })}
    </>
  );
};

const NotificationsControl = (props: {
  oldValue: NotificationType;
  onChange: (value: NotificationType) => void;
  titleRef: MutableRefObject<HTMLDivElement | null>;
}) => {
  const { t } = useTranslation();
  return (
    <CategorieRuleTemplate
      title={t("rules.modal.notifications.title")}
      titleRef={props.titleRef}
    >
      <RuleTemplate>
        <>
          <StyledBodyLeft>
            <TextCapitalized>
              {t("rules.modal.areSupportingDocumentsMandatory.body")}
            </TextCapitalized>
          </StyledBodyLeft>
          <Spacer x={1} />
          <StyledBodyRight>
            <Switch
              checked={props.oldValue.areSupportingDocumentsMandatory}
              onClick={() =>
                props.onChange({
                  areSupportingDocumentsMandatory:
                    !props.oldValue.areSupportingDocumentsMandatory,
                })
              }
              size={"S"}
              color={"GREEN"}
            />
          </StyledBodyRight>
        </>
      </RuleTemplate>
    </CategorieRuleTemplate>
  );
};

const CategorieRuleTemplate = (props: {
  title: string;
  children: JSX.Element | string;
  titleRef?: MutableRefObject<HTMLDivElement | null>;
}) => (
  <>
    <StyledTitle ref={props.titleRef}>
      <TextCapitalized>{props.title}</TextCapitalized>
    </StyledTitle>
    <Spacer y={1} />
    {props.children}
    <Spacer y={2} />
  </>
);

const StyledModalBody = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const StyledTitle = styled.div`
  color: ${colors["colors/text/black"]};
  ${typographies["Header/H3"]}
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;

const StyledRuleModalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const StyledBodyLeftMerchantGroup = styled.div`
  color: ${colors["colors/text/black"]};
  ${typographies["Body/L"]}
  display: flex;
  width: 100%;
  align-items: center;
  & svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledError = styled.div`
  display: flex;
  justify-content: flex-end;
  color: ${colors["colors/system/error/error_normal"]};
`;

const StyledLinkedWord = styled.div<{ $disabled: boolean }>`
  display: flex;
  color: ${({ $disabled }) =>
    $disabled ? colors["colors/text/lightGrey"] : colors["colors/text/black"]};
`;
