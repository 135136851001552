import {
  ToastContainer,
  triggerToast,
  LaunchPage,
  sortByDateTime,
  sortByAlphabeticalOrder,
  SortingDirectionType,
  SortByAlphabeticalOrderType,
  PageDS,
  TableDS,
  AddOutlined,
  EmptyTableComponent,
  sortByNumeric,
  SortByNumericType,
} from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  rulesSlice,
  rulesListAsync,
  selectRuleStatus,
  selectRulesList,
  selectRulesListStatus,
  selectUpdatedRuleStatus,
} from "./rulesSlice";
import { unreachable } from "../../../utils";
import { PublicRuleType, RulesListDisplayedType } from "./rulesAPI";
import { useNavigate, useParams } from "react-router-dom";
import { selectCompany } from "../homeSlice";

export const Rules = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const createRuleStatus = useAppSelector(selectRuleStatus);
  const updateRuleStatus = useAppSelector(selectUpdatedRuleStatus);
  const rulesListInit = useAppSelector(selectRulesList);
  const rulesListStatus = useAppSelector(selectRulesListStatus);
  const company = useAppSelector(selectCompany);
  const [ruleName, setRuleName] = useState("");
  const [isModalCreationVisible, setIsModalCreationVisible] = useState(false);
  const [sortingDirection, setSortingDirection] =
    useState<SortingDirectionType>("desc");

  const [listDisplayed, setListDisplayed] = useState<
    RulesListDisplayedType[] | null
  >(null);

  const rulesList: PublicRuleType[] = useMemo(() => {
    return sortByDateTime(rulesListInit, "desc");
  }, [rulesListInit]);

  const routerParams = useParams();

  useEffect(() => {
    if (routerParams.name) {
      setRuleName(routerParams.name);
    }
  }, [routerParams.name]);

  useEffect(() => {
    if (isModalCreationVisible) {
      navigate("/home/rule_creation/rule");
    }
  }, [isModalCreationVisible, navigate]);

  useEffect(() => {
    if (company) {
      void dispatch(rulesListAsync(company.uuid));
    }
  }, [dispatch, company]);

  useEffect(() => {
    if (createRuleStatus === "success" && ruleName !== "") {
      triggerToast(t("rules.createRule.success") + ruleName || "", "valid");
      dispatch(rulesSlice.actions.resetRuleCreationStatus());
    } else if (createRuleStatus === "failed") {
      triggerToast(t("rules.createRule.failure") || "", "error");
      dispatch(rulesSlice.actions.resetRuleCreationStatus());
    }
  }, [createRuleStatus, dispatch, ruleName, t, updateRuleStatus]);

  useEffect(() => {
    if (updateRuleStatus === "success" && ruleName !== "") {
      triggerToast(t("rules.update.success") + ruleName || "", "valid");
      dispatch(rulesSlice.actions.resetRuleUpdateStatus());
    } else if (updateRuleStatus === "failed") {
      triggerToast(t("rules.update.failure") || "", "error");
      dispatch(rulesSlice.actions.resetRuleUpdateStatus());
    }
  }, [updateRuleStatus, t, createRuleStatus, ruleName, dispatch]);

  useEffect(() => {
    if (rulesList && rulesListStatus === "success") {
      const rulesDateTimeSorted = sortByDateTime(rulesList, "desc");
      setListDisplayed(rulesDateTimeSorted);
    }
  }, [dispatch, rulesList, rulesListStatus]);

  const retrieveRuleUuid = useCallback(
    (ruleName: string) => {
      const rule = rulesList.find((rule) => rule.name === ruleName);
      if (!rule) {
        triggerToast(t("rules.reloadRule.error") || "", "error");
        return;
      }
      void dispatch(rulesSlice.actions.resetRulesList());
      navigate(`/home/rule/${rule.uuid}`);
    },
    [dispatch, navigate, rulesList, t],
  );

  const sortRulesByAlphabeticalOrder = useCallback(
    (column: string) => {
      if (!listDisplayed) return;
      const nameAlphabeticalSorted = sortByAlphabeticalOrder(
        listDisplayed,
        sortingDirection,
        column as keyof SortByAlphabeticalOrderType,
      );
      setListDisplayed(nameAlphabeticalSorted);
      setSortingDirection(sortingDirection === "asc" ? "desc" : "asc");
    },
    [listDisplayed, sortingDirection],
  );

  const sortRulesByAmount = useCallback(
    (column: string) => {
      if (!listDisplayed) return;

      const rulesAmountSorted = sortByNumeric(
        listDisplayed,
        sortingDirection,
        column as keyof SortByNumericType,
      );

      setListDisplayed(rulesAmountSorted);
      setSortingDirection(sortingDirection === "asc" ? "desc" : "asc");
    },
    [listDisplayed, sortingDirection],
  );

  if (!listDisplayed) return <LaunchPage hasBorderRadius={true} />;

  const cta = {
    title: t("rules.cta"),
    action: () => {
      setIsModalCreationVisible(true);
    },
    leftIcon: <AddOutlined />,
  };

  const headers = {
    name: {
      text: t("rules.page.column.name"),
      hasButton: true,
      sortAction: () => sortRulesByAlphabeticalOrder("name"),
    },
    vehicleCount: {
      text: t("rules.page.column.vehicles"),
      sortAction: () => sortRulesByAmount("vehicleCount"),
    },
  };

  const searchBarProps = {
    values: rulesList,
    setFilterValues: setListDisplayed,
    keysToIgnore: ["uuid" as const, "date" as const],
    name: "rules",
  };

  const render =
    (row: RulesListDisplayedType) => (key: keyof RulesListDisplayedType) => {
      switch (key) {
        case "name":
        case "vehicleCount":
          return row[key];
      }
      unreachable(key);
    };

  return (
    <PageDS
      title={t("rules.title")}
      cta={cta}
      //isEmptyTable={listDisplayed.length === 0}
      toaster={<ToastContainer />}
    >
      <TableDS<keyof RulesListDisplayedType, RulesListDisplayedType>
        data={listDisplayed}
        headers={headers}
        searchBar={searchBarProps}
        render={render}
        onClickRow={(props) => retrieveRuleUuid(props.name)}
        emptyContent={
          <EmptyTableComponent
            cta={cta}
            pageName={"rules"}
            isSearchResult={searchBarProps.values.length > 0}
          />
        }
      />
    </PageDS>
  );
};
