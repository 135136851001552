import {
  sortByAlphabeticalOrder,
  SortByAlphabeticalOrderType,
  TableTagVehicle,
  Tag,
  unreachable,
  TextCapitalized,
  Spacer,
  TableDS,
  SelectDS,
  getDeliveryDate,
  ButtonDS,
} from "@qivia/ui";
import { LittleQiviaCard, EmptyTable } from "@qivia/ui/src/assets/assets";
import { typographies } from "@qivia/ui/src/styles/figmaTypographies";
import { useState, useMemo, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  VehiclesListToOrderType,
  ModalVehicleType,
  VehiclesListToOrderDisplayedType,
  CardsListDisplayed,
  CardsListType,
} from "./../cardsAPI";
import {
  selectCardsList,
  selectCompanyAddress,
  addressAsync,
  orderMultipleCardsAsync,
} from "./../cardsSlice";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import { selectRulesList } from "../../rules/rulesSlice";
import { selectListAllVehiclesInDb } from "../../vehicles/vehiclesSlice";
import { selectCompany } from "../../homeSlice";

const findVehiclesNotAssignedToCard = (
  vehicles: ModalVehicleType[],
  cardsList: CardsListType[] | CardsListDisplayed[],
) => {
  return vehicles.filter(
    (vehicle) =>
      cardsList.find(
        (card) =>
          (card.registrationNumber === vehicle.registrationNumber ||
            (vehicle.reference &&
              card.vehicleReference === vehicle.reference) ||
            vehicle.status === "DEACTIVATED") &&
          card.status !== "IN_OPPOSITION",
      ) === undefined,
  );
};

export const AddMultipeCardsModal = (props: { onCloseModal: () => void }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { onCloseModal } = props;

  const [buttonDisabled, setButtonDisabled] = useState(true);
  const rules = useAppSelector(selectRulesList);
  const company = useAppSelector(selectCompany);
  const vehicles = useAppSelector(selectListAllVehiclesInDb);
  const cardsList = useAppSelector(selectCardsList);
  const companyAddress = useAppSelector(selectCompanyAddress);
  const [rule, setRule] = useState<string>("");
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [rowsChecked, setRowsChecked] = useState<
    Array<VehiclesListToOrderType>
  >([]);

  const vehiclesNotAssignedToCard: ModalVehicleType[] =
    findVehiclesNotAssignedToCard(vehicles, cardsList);

  const vehiclesListDisplayed = useMemo(() => {
    const vehiclesNotAssignedToCardWithReference: VehiclesListToOrderType[] =
      [];
    const vehiclesNotAssignedToCardWithoutReference: VehiclesListToOrderType[] =
      [];

    vehiclesNotAssignedToCard.map((vehicle) => {
      if (vehicle.reference) {
        vehiclesNotAssignedToCardWithReference.push(vehicle);
      } else {
        vehiclesNotAssignedToCardWithoutReference.push(vehicle);
      }
    });

    const vehiclesNotAssignedToCardSortedByReference = sortByAlphabeticalOrder(
      vehiclesNotAssignedToCardWithReference,
      "asc",
      "reference" as keyof SortByAlphabeticalOrderType,
    );

    return [
      ...vehiclesNotAssignedToCardSortedByReference,
      ...vehiclesNotAssignedToCardWithoutReference,
    ].map((vehicle) => {
      return {
        uuid: vehicle.uuid,
        registrationNumber: vehicle.registrationNumber,
        reference: vehicle.reference,
        status: vehicle.status,
      };
    });
  }, [vehiclesNotAssignedToCard]);

  const optionRule = useMemo(
    () =>
      rules.map((rule) => {
        return {
          value: rule.uuid,
          label: rule.name,
        };
      }),
    [rules],
  );

  const valueOptionRule = useMemo(
    () => optionRule.find((option) => option.value === rule),
    [optionRule, rule],
  );

  useEffect(() => {
    if (company) {
      void dispatch(addressAsync(company.uuid));
    }
  }, [companyAddress, dispatch, company]);

  useEffect(() => {
    if (rowsChecked.length > 0 && rule) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [rowsChecked, rule]);

  const resetValue = useCallback(() => {
    setRule("");
    setRowsChecked([]);
    setDisplayError(false);
  }, []);

  const onSubmit = useCallback(() => {
    const vehiclesUuidList: string[] = rowsChecked
      .map((row) => {
        if (row.uuid) {
          return row.uuid;
        } else {
          return "";
        }
      })
      .filter((vehicle) => vehicle !== "");
    const cardData = {
      rule: rule,
      vehicles: vehiclesUuidList,
    };

    void dispatch(orderMultipleCardsAsync(cardData));
    resetValue();
    onCloseModal();
  }, [dispatch, onCloseModal, resetValue, rowsChecked, rule]);

  const clickToSubmit = useCallback(() => {
    setDisplayError(true);
    if (!buttonDisabled) {
      onSubmit();
    }
  }, [buttonDisabled, onSubmit]);

  const render =
    (row: VehiclesListToOrderType) =>
    (key: keyof VehiclesListToOrderDisplayedType) => {
      switch (key) {
        case "status":
          return <TableTagVehicle status={row[key]} />;
        case "reference":
          return row[key] ? (
            <Tag
              textColor={colors["colors/text/black"]}
              backgroundColor={
                colors["colors/surfaces/background/background_level0"]
              }
              text={row[key]}
              borderColor={colors["colors/borders/cells/cells"]}
            />
          ) : (
            "-"
          );
        case "registrationNumber":
          return row[key] ?? "-";
      }
      unreachable(key);
    };

  const headers = {
    registrationNumber: {
      text: t("cards.multipleCards.vehicles.registrationNumber"),
    },
    reference: {
      text: t("cards.multipleCards.vehicles.reference"),
    },
    status: {
      text: t("cards.multipleCards.vehicles.status"),
    },
  };

  return (
    <StyledOrderCards>
      <StyledTable>
        {displayError && rowsChecked.length === 0 && (
          <StyledErrorMessage>
            <TextCapitalized>
              {t("cards.modal.multipleCards.error.listEmpty")}
            </TextCapitalized>
            <Spacer y={1} />
          </StyledErrorMessage>
        )}
        <TableDS<
          keyof VehiclesListToOrderDisplayedType,
          VehiclesListToOrderDisplayedType
        >
          data={vehiclesListDisplayed}
          headers={headers}
          render={render}
          rowsChecked={rowsChecked}
          setRowsChecked={setRowsChecked}
          height={34.5}
          width={39}
          headerOverlayed={
            <StyledHeader>
              <LittleQiviaCard />
              <Spacer x={1} />
              <div>
                {t("cards.modal.multipleCards.table.header", {
                  number: rowsChecked.length,
                })}
              </div>
            </StyledHeader>
          }
          emptyContent={
            <StyledFlex>
              <Spacer x={1.5} />
              <StyledEmptyTable>
                <Spacer y={2} />
                <EmptyTable />
                <Spacer y={1.5} />
                <StyledTitleEmptyTable>
                  <TextCapitalized>
                    {t("cards.modal.multipleCards.listEmpty.title")}
                  </TextCapitalized>
                </StyledTitleEmptyTable>
                <Spacer y={1} />
                <StyledSubText>
                  <TextCapitalized>
                    {t("cards.modal.multipleCards.listEmpty.body")}
                  </TextCapitalized>
                </StyledSubText>
                <Spacer y={2} />
              </StyledEmptyTable>
              <Spacer x={1.5} />
            </StyledFlex>
          }
        />
        <Spacer y={4} />
      </StyledTable>
      <Spacer x={2.5} />
      <StyledRightColumn>
        <StyledSelectDataImport>
          <Spacer x={1.5} />
          <StyledColumn>
            <Spacer y={1.5} />
            <StyledTitle>
              <TextCapitalized>
                {t("cards.modal.multipleCards.rule.title") || ""}
              </TextCapitalized>
            </StyledTitle>
            <Spacer y={0.75} />
            <SelectDS
              label={""}
              placeholder={t("cards.modal.rule.placeholder") || ""}
              value={valueOptionRule}
              options={optionRule}
              onChange={(selectedOption) =>
                setRule(selectedOption ? selectedOption.value : "")
              }
              error={
                (displayError &&
                  rule === "" &&
                  t("cards.modal.error.selectInput")) ||
                undefined
              }
            />
            <Spacer y={1} />
            <StyledTitle>
              <TextCapitalized>
                {t("cards.modal.multipleCards.address.title") || ""}
              </TextCapitalized>
            </StyledTitle>
            <Spacer y={0.75} />
            <StyledSubText>{companyAddress}</StyledSubText>
            <Spacer y={3} />
            <StyledTitle>
              <TextCapitalized>
                {t("cards.modal.multipleCards.deliveryDate.title") || ""}
              </TextCapitalized>
            </StyledTitle>
            <Spacer y={0.75} />
            <StyledText>{getDeliveryDate(3, 4)}</StyledText>
            <Spacer y={1.75} />
          </StyledColumn>
          <Spacer x={1.5} />
        </StyledSelectDataImport>
        <Spacer y={1.5} />
        <StyledCenter>
          <ButtonDS
            format="hug"
            buttonType="primary"
            text={t("cards.modal.multipleCards.submit") || ""}
            onClick={clickToSubmit}
            disabled={buttonDisabled}
          />
        </StyledCenter>
        <Spacer y={1.5} />
      </StyledRightColumn>
    </StyledOrderCards>
  );
};

const StyledEmptyTable = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  text-align: center;
`;

const StyledFlex = styled.div`
  display: flex;
`;

const StyledTitleEmptyTable = styled.div`
  display: flex;
  ${typographies["Header/H1"]};
  color: ${colors["colors/text/black"]};
`;

const StyledErrorMessage = styled.div`
  display: flex;
  flex-direction: column;
  color: ${colors["colors/system/error/error_normal"]};
`;

const StyledHeader = styled.div`
  display: flex;
  ${typographies["Header/H3"]};
  color: ${colors["colors/text/black"]};
  align-items: center;
  height: 3rem;
`;

const StyledTitle = styled.div`
  display: flex;
  ${typographies["Body/M"]};
  color: ${colors["colors/text/black"]};
`;

const StyledText = styled.div`
  display: flex;
  ${typographies["Body/L"]};
  color: ${colors["colors/text/black"]};
`;

const StyledSubText = styled.div`
  display: flex;
  ${typographies["Body/S"]};
  color: ${colors["colors/text/darkGrey"]};
`;

const StyledOrderCards = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledRightColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
`;

const StyledTable = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledCenter = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledSelectDataImport = styled.div`
  display: flex;
  justify-content: center;
  width: 22.625rem;
  height: fit-content;
  border: 0.0625rem solid ${colors["colors/borders/cards/cards"]};
  background-color: ${colors["colors/surfaces/background/background_level2"]};
  border-radius: 0.5rem;
`;
