import {
  Spacer,
  ToastContainer,
  triggerToast,
  LaunchPage,
  sortByDateTime,
  PageDS,
  TableTagCard,
  AddOutlined,
  AddCardOutlined,
  Badge,
  MaterialUiTable,
  usePersistMuiTableState,
  MaterialUiColumnsProps,
  renderCellWithLabel,
  Status,
} from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectCompany } from "./../homeSlice";
import {
  cardsListAsync,
  selectCardsList,
  selectCardCreationStatus,
  selectCardsListStatus,
  cardsSlice,
  selectCardUpdatedStatus,
  selectOrderMultipleCardsStatus,
  selectNewCardsList,
  selectRuleUpdatedStatus,
} from "./cardsSlice";
import { CardsListDisplayedType, CardsListType } from "./cardsAPI";
import styled from "styled-components";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import { LittleCardAsset } from "@qivia/ui/src/assets/assets";
import { useNavigate, useParams } from "react-router-dom";
import { CardSidePanel } from "./SidePanel";
import { AddMultipeCardsModal } from "./modals/addMulitpleCardsModal";
import { AddCardModal } from "./modals/addCardModal";
import { listAllVehiclesInDbAsync } from "../vehicles/vehiclesSlice";
import { rulesListAsync } from "../rules/rulesSlice";
import { documentDownloadXlsxAsync } from "../../../libSlice/downloadFileXslx";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import { formatCurrency } from "../../../libSlice/materialUiFormats";

export type AddressInput = {
  addressLine1: string;
  addressLine2: string;
  city: string;
  postalCode: string;
  country: string;
};

export const Cards = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams<{ id?: string }>();
  const pageName = "cards";

  const [listDisplayed, setListDisplayed] = useState<
    CardsListDisplayedType[] | null
  >(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isMultipleCardsModalVisible, setIsMultipleCardsModalVisible] =
    useState(false);

  const newCardsList = useAppSelector(selectNewCardsList);
  const cardsListStatus = useAppSelector(selectCardsListStatus);
  const createCardStatus = useAppSelector(selectCardCreationStatus);
  const company = useAppSelector(selectCompany);
  const cardsListInit = useAppSelector(selectCardsList);
  const cardUpdatedStatus = useAppSelector(selectCardUpdatedStatus);
  const ruleUpdatedStatus = useAppSelector(selectRuleUpdatedStatus);
  const orderMultipleCardStatus = useAppSelector(
    selectOrderMultipleCardsStatus,
  );
  const addCardIsDisabled = useMemo(
    () =>
      company === null ||
      company.iban === "" ||
      company.iban === null ||
      company.bic === "" ||
      company.bic === null,
    [company],
  );

  const apiRef = useGridApiRef();
  const key = "customers-grid";

  const aggregation = {
    model: {
      last4Digits: "size",
      status: "size",
      vehicleInfo: "size",
      ruleName: "size",
      driverName: "size",
      monthlyExpenses: "avg",
      qrCodesIdentifier: "size",
    },
  };
  const dataGridState = usePersistMuiTableState(
    apiRef,
    key,
    pageName,
    aggregation,
  );

  const findKeysToTranslate = useCallback(
    (key: keyof CardsListType, value: string) => {
      switch (key) {
        case "status":
          return t(`cards.status.${value}`);
        default:
          return value;
      }
    },
    [t],
  );

  const keysToTranslate = useMemo(
    () => ["status"] as (keyof CardsListDisplayedType)[],
    [],
  );

  const cardsList: CardsListType[] = useMemo(() => {
    const rowsWithId = cardsListInit.map((av) => {
      let newDict: CardsListType & { id: string } = {
        ...av,
        id: av.uuid,
      };
      Object.entries(av).forEach(([key, value]) => {
        if (keysToTranslate.includes(key as keyof CardsListDisplayedType)) {
          newDict = {
            ...newDict,
            [key]: value
              ? findKeysToTranslate(key as keyof CardsListType, value as string)
              : "",
          };
        }
      });
      return newDict;
    });
    return sortByDateTime(rowsWithId, "desc");
  }, [cardsListInit, findKeysToTranslate, keysToTranslate]);

  useEffect(() => {
    if (company) {
      void dispatch(cardsListAsync(company.uuid));
      void dispatch(listAllVehiclesInDbAsync({ companyUuid: company.uuid }));
      void dispatch(rulesListAsync(company.uuid));
    }
  }, [
    dispatch,
    createCardStatus,
    cardUpdatedStatus,
    orderMultipleCardStatus,
    ruleUpdatedStatus,
    company,
  ]);

  useEffect(() => {
    if (cardsList && cardsListStatus === "success") {
      const cardsListUpdated = cardsList.map((card) => {
        if (newCardsList.vehicles.includes(card.vehicleUuid)) {
          return { ...card, card: "(new)" };
        } else {
          return card;
        }
      });
      const cardsDateTimeSorted = sortByDateTime(cardsListUpdated, "desc");
      setListDisplayed(cardsDateTimeSorted);
      dispatch(cardsSlice.actions.resetCardsListStatus());
    }
  }, [cardsList, cardsListStatus, dispatch, newCardsList]);

  useEffect(() => {
    if (orderMultipleCardStatus === "success") {
      triggerToast(t("cards.multipleCards.creation.success") || "", "valid");
    } else if (orderMultipleCardStatus === "failed") {
      triggerToast(t("cards.multipleCards.creation.failure") || "", "error");
    }
  }, [orderMultipleCardStatus, t]);

  const sidePanelAction = useCallback(
    (props: Partial<CardsListType>) => {
      navigate(`/home/cards/${props.uuid}`);
    },
    [navigate],
  );

  useEffect(() => {
    if (params.id) {
      const cardSelected = cardsList.find((c) => c.uuid === params.id);
      if (cardSelected) {
        navigate(`/home/cards/${cardSelected.uuid}`);
      }
    }
  }, [cardsList, navigate, params.id]);

  const searchBarProps = {
    values: cardsList,
    setFilterValues: setListDisplayed,
    keysToIgnore: [
      "uuid" as const,
      "date" as const,
      "driverUuid" as const,
      "vehicleUuid" as const,
      "ruleUuid" as const,
      "driverFirstName" as const,
      "driverLastName" as const,
      "monthlyAmountlimit" as const,
    ],
  };

  const downloadViewAction = useCallback(
    (file: Buffer | undefined) => {
      if (!file) return;
      void dispatch(
        documentDownloadXlsxAsync({
          buffer: file,
          fileName: "QIVIA_" + t("cards.title") + ".xlsx",
        }),
      );
    },
    [t, dispatch],
  );

  const cta = {
    title: t("cards.cta"),
    action: () => {
      if (!addCardIsDisabled) setIsModalVisible(true);
    },
    width: 12,
    isDisabled: addCardIsDisabled,
    leftIcon: <AddOutlined />,
  };

  const cta2 = {
    title: t("cards.cta2"),
    action: () => {
      if (!addCardIsDisabled) setIsMultipleCardsModalVisible(true);
    },
    width: 12,
    isDisabled: addCardIsDisabled,
    leftIcon: <AddCardOutlined />,
  };

  const columnsDisplayed: {
    [index in keyof CardsListDisplayedType]: MaterialUiColumnsProps;
  } = useMemo(() => {
    return {
      last4Digits: {
        type: "string",
        width: 220,
        renderCell: (p) =>
          renderCellWithLabel(
            p,
            "Nb d'éléments",
            <StyledRow>
              <LittleCardAsset />
              <Spacer x={1} />
              {(p.formattedValue as string)?.endsWith("(new)") ? (
                <Badge
                  text={t("cards.status.badge.new")}
                  backgroundColor={
                    colors["colors/system/informative/informative_ultraLight"]
                  }
                  textColor={
                    colors["colors/system/informative/informative_normal"]
                  }
                />
              ) : (
                (p.formattedValue as string) &&
                `** ${p.formattedValue as string}`
              )}
            </StyledRow>,
          ),
      },
      status: {
        type: "string",
        valueFormatter: (value: Status) =>
          value && (
            <StyledRow>
              <TableTagCard status={value} />
            </StyledRow>
          ),
      },
      vehicleInfo: {
        type: "string",
        width: 300,
      },
      ruleName: {
        type: "string",
        width: 250,
      },
      driverName: {
        type: "string",
        width: 250,
      },
      monthlyExpenses: {
        type: "number",
        valueFormatter: formatCurrency,
      },
      qrCodesIdentifier: {
        type: "string",
        width: 200,
      },
    };
  }, [t]);

  if (!listDisplayed) return <LaunchPage hasBorderRadius={true} />;

  return (
    <>
      {isMultipleCardsModalVisible ? (
        <PageDS
          title={t("cards.multipleCards.title")}
          titleElement={{
            closeButtonAction: () => setIsMultipleCardsModalVisible(false),
          }}
        >
          <AddMultipeCardsModal
            onCloseModal={() => {
              setIsMultipleCardsModalVisible(false);
            }}
          />
        </PageDS>
      ) : (
        <>
          <AddCardModal
            visible={isModalVisible}
            onCloseModal={() => {
              setIsModalVisible(false);
            }}
          />
          <PageDS
            title={t("cards.title")}
            cta={cta}
            cta2={cta2}
            //isEmptyTable={listDisplayed.length === 0}
            toaster={<ToastContainer />}
          >
            <MaterialUiTable<
              keyof CardsListDisplayedType,
              CardsListDisplayedType
            >
              rows={listDisplayed}
              columns={columnsDisplayed}
              pageName={pageName}
              sidePanelAction={sidePanelAction}
              dataGridState={dataGridState}
              searchBar={searchBarProps}
              apiRef={apiRef}
              downloadViewAction={downloadViewAction}
              cta={cta}
              cta2={cta2}
            />
            <CardSidePanel />
          </PageDS>
        </>
      )}
    </>
  );
};

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;
