import {
  ArrowBackOutlined,
  ButtonDS,
  CachedOutlined,
  DownloadOutlined,
  DragAndDrop,
  FileInputButton,
  FilePresentOutlined,
  TextCapitalized,
  triggerToast,
} from "@qivia/ui";
import { Spacer } from "@qivia/ui/src/designSystem/components/Spacer";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import { typographies } from "@qivia/ui/src/styles/figmaTypographies";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectCompany } from "../homeSlice";
import { uploadDriverPlanningsFileThunk } from "./driverPlanningsSlice";

export const ImportDriverPlanningsModal = (props: {
  visible: boolean;
  onCloseModal: () => void;
}) => {
  const { t } = useTranslation();

  if (!props.visible) {
    return;
  }

  return (
    <StyledFrame>
      <Spacer x={2.5} />
      <StyledColumn>
        <Spacer y={2.5} />
        <ReturnButton onCloseModal={props.onCloseModal} />
        <Spacer y={2.5} />
        <StyledContentImport>
          <StyledTitle>
            <TextCapitalized>{t(`trips.importPlanning.title`)}</TextCapitalized>
          </StyledTitle>
          <Spacer y={2} />
          <StyledFrameContainer>
            <StyledFrameTitle>
              <TextCapitalized>
                {t(`trips.importPlanning.stepframe.title.step`)}
              </TextCapitalized>
            </StyledFrameTitle>
            <Spacer y={2} />
            <FileImport onCloseModal={props.onCloseModal} />
          </StyledFrameContainer>
          <Spacer y={2.5} />
        </StyledContentImport>
        <Spacer y={2.5} />
      </StyledColumn>
      <Spacer x={2.5} />
    </StyledFrame>
  );
};

const ReturnButton = (props: { onCloseModal: () => void }) => {
  const { t } = useTranslation();
  return (
    <div>
      <ButtonDS
        text={t("trips.importPlanning.button.return")}
        leftIcon={<ArrowBackOutlined />}
        onClick={() => props.onCloseModal()}
        buttonType={"secondary"}
        format={"hug"}
      />
    </div>
  );
};

const FileImport = (props: { onCloseModal: () => void }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const [isValidImport, setIsValidImport] = useState<boolean>(false);
  const [hasExtensionError, setHasExtensionError] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const company = useAppSelector(selectCompany);

  const MIME = {
    xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  };

  type DisplayFileType = "REPLACE" | "DOWNLOAD";

  const DisplayFile = (props: {
    selectedFile?: string;
    handleFileChange?: (event: File | null) => void;
    type: DisplayFileType;
  }) => {
    const { t } = useTranslation();

    return (
      <StyledFlex>
        <Spacer x={0.75} />
        <StyledDisplayFile>
          <StyledLeft>
            <StyledIcon>
              <FilePresentOutlined />
            </StyledIcon>
            <Spacer x={0.5} />
            {props.selectedFile}
          </StyledLeft>
          <Spacer x={0.25} />
          {props.type === "REPLACE" ? (
            <FileInputButton
              handleFile={(file) => props.handleFileChange?.(file)}
            >
              <ButtonDS
                format="hug"
                buttonType={"secondary"}
                text={t(`vehicles.modal.addMultipleVehicles.button.replace`)}
                leftIcon={<CachedOutlined />}
              />
            </FileInputButton>
          ) : (
            <StyledDownload
              download={props.selectedFile}
              style={{ textDecoration: "none" }}
            >
              <ButtonDS
                text={t(`vehicles.modal.addMultipleVehicles.button.download`)}
                leftIcon={<DownloadOutlined />}
                format="hug"
                buttonType="secondary"
              />
            </StyledDownload>
          )}
        </StyledDisplayFile>
        <Spacer x={0.75} />
      </StyledFlex>
    );
  };

  const checkFile = useCallback(
    (selectedFile: File) => {
      if (selectedFile && selectedFile.type !== MIME.xlsx) {
        setHasExtensionError(true);
      } else if (selectedFile) {
        setHasExtensionError(false);
        setIsValidImport(true);
      } else {
        triggerToast(t("trips.mime.type.failure"), "error");
      }
    },
    [MIME.xlsx, t],
  );

  const onSubmitDriverPlannings = useCallback(() => {
    if (!selectedFile || !company) {
      return;
    }
    void dispatch(
      uploadDriverPlanningsFileThunk({
        file: selectedFile,
        companyUuid: company.uuid,
      }),
    );
    props.onCloseModal();
  }, [selectedFile, company, dispatch, props]);

  const clickToSubmit = useCallback(() => {
    isValidImport && onSubmitDriverPlannings();
  }, [isValidImport, onSubmitDriverPlannings]);

  useEffect(() => {
    if (selectedFile) checkFile(selectedFile);
  }, [checkFile, selectedFile]);

  const isFileAlreadyImport = isValidImport && !hasExtensionError;

  return (
    <>
      {isFileAlreadyImport ? (
        <>
          <DisplayFile
            selectedFile={selectedFile?.name}
            handleFileChange={setSelectedFile}
            type="REPLACE"
          />
          <Spacer y={1.5} />
          {isValidImport && (
            <ButtonDS
              text={t("vehicles.modal.addMultipleVehicles.add")}
              format={"hug"}
              buttonType={"primary"}
              onClick={clickToSubmit}
              disabled={!isValidImport}
            />
          )}
        </>
      ) : (
        <StyledFlexCenter>
          <DragAndDrop
            handleFileChange={setSelectedFile}
            body={t("vehicles.modal.addMultipleVehicles.dragAndDrop.subTitle")}
            hasExtensionError={hasExtensionError}
          />
        </StyledFlexCenter>
      )}
    </>
  );
};

const StyledFlex = styled.div`
  display: flex;
`;

const StyledFlexCenter = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledLeft = styled.div`
  display: flex;
  align-items: center;
`;

const StyledFrame = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: ${colors["colors/surfaces/background/background_level0"]};
  border-radius: 0.75rem 0 0 0.75rem;
`;

const StyledColumn = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const StyledContentImport = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
`;

const StyledFrameContainer = styled.div`
  max-width: 39.5rem;
  width: 100%;
  display: flex;
  padding: 3rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow:
    0px 2px 5px 0px rgba(143, 143, 143, 0.15),
    0px 0px 15px 0px rgba(143, 143, 143, 0.15);
  border-radius: 0.75rem;
  border: 0.0625rem solid transparent;
  background:
    linear-gradient(white, white) padding-box,
    linear-gradient(to left, #3cd3ad, #44b2d5) border-box;
  background-color: ${colors["colors/surfaces/background/background_level0"]};
`;

const StyledFrameTitle = styled.div`
  ${typographies["Body/M"]}
  color: ${colors["colors/text/black"]};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const StyledTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${typographies["Header/H3"]};
  color: ${colors["colors/text/black"]};
  width: 100%;
`;

const StyledDisplayFile = styled.div`
  width: 100%;
  display: flex;
  border: 0.0625rem solid ${colors["colors/borders/cards/cards"]};
  padding: 0.75rem;
  border-radius: 0.5rem;
  justify-content: space-between;
  align-items: center;
  ${typographies["Body/M"]};
  color: ${colors["colors/accent/600"]};
`;

const StyledDownload = styled.a`
  &:focus {
    outline: none;
  }
`;

const StyledIcon = styled.div`
  & svg {
    fill: ${colors["colors/accent/600"]};
  }
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 2.25rem;
  height: 2.25rem;
  background-color: ${colors["colors/surfaces/background/background_level0"]};
  border-radius: 0.5rem;
  border: 0.0625rem solid ${colors["colors/borders/cards/cards"]};
`;
