import {
  PieChartDS,
  PieChartData,
} from "@qivia/ui/src/designSystem/components/Charts/PieChart";
import { TotalType } from "@qivia/ui/src/designSystem/components/Charts/CardChart";
import { useTranslation } from "react-i18next";
import { EmptyChart } from "./emptyChart";
import {
  GetTransactionsAmountsPerPeriodType,
  TotalAmountPerCategoryType,
} from "../../transactions/transactionsAPI";

export const CategoryPieChart = (props: {
  totalType: TotalType;
  data: GetTransactionsAmountsPerPeriodType;
}) => {
  const { t } = useTranslation();

  const mapCategories = (category: TotalAmountPerCategoryType) => {
    return {
      name: t(`dashboard.charts.title.category.${category.category}`),
      value: category.amount / 100,
    };
  };

  let categoryData: PieChartData[] = [];
  if (props.totalType === "month") {
    categoryData = props.data.month.categoriesAmounts.map(mapCategories);
  } else if (props.totalType === "quarter") {
    categoryData = props.data.quarter.categoriesAmounts.map(mapCategories);
  } else if (props.totalType === "year") {
    categoryData = props.data.year.categoriesAmounts.map(mapCategories);
  }

  return (
    <>
      {!categoryData.length ? (
        <EmptyChart />
      ) : (
        <PieChartDS categoryData={categoryData} unit="€" />
      )}
    </>
  );
};
