import {
  ButtonDS,
  ErrorOutlineOutlined,
  ModalDS,
  Spacer,
  TextCapitalized,
} from "@qivia/ui";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import { typographies } from "@qivia/ui/src/styles/figmaTypographies";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { PublicCompany } from "../../homeSlice";

export const MandateUnsignedModal = (props: {
  visible: boolean;
  onCloseModal: () => void;
  company: PublicCompany;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <ModalDS
      visible={props.visible}
      onClose={() => {
        props.onCloseModal();
      }}
      width={30}
    >
      <StyledModalContainer>
        <StyledModalContainer>
          <StyledIcon>
            <ErrorOutlineOutlined />
          </StyledIcon>
          <Spacer y={2.5} />
          <TextCapitalized>
            <StyledModalTitle>
              {t("badges.mandateUnsignedModal.title")}
            </StyledModalTitle>
            <Spacer y={1} />
          </TextCapitalized>
          <StyledModalContent>
            <TextCapitalized>
              {t("badges.mandateUnsignedModal.content")}
            </TextCapitalized>
          </StyledModalContent>
          <Spacer y={1.5} />
          <ButtonDS
            text={t("badges.mandateUnsignedModal.button.redirect")}
            buttonType={"secondary"}
            format={"fill"}
            onClick={() => {
              if (
                !props.company.externalIban &&
                !props.company.connectSepaMandateSigned
              ) {
                navigate("/home/billings/account/external_account");
              } else if (
                props.company.externalIban &&
                !props.company.connectSepaMandateSigned &&
                props.company.canActivatePlatformSepaDebit
              ) {
                navigate("/home/billings/account/sign_platform_mandate");
              } else {
                navigate("/home/billings/account");
              }
            }}
          />
          <Spacer y={0.5} />
          <ButtonDS
            text={t("badges.mandateUnsignedModal.button.close")}
            onClick={props.onCloseModal}
            buttonType={"primary"}
            format={"fill"}
          />
        </StyledModalContainer>
      </StyledModalContainer>
    </ModalDS>
  );
};

const StyledModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

const StyledModalTitle = styled.div`
  ${typographies["Header/H3"]};
  color: ${colors["colors/text/black"]};
`;

const StyledModalContent = styled.div`
  ${typographies["Body/S"]};
  display: flex;
  flex-direction: column;
  color: ${colors["colors/text/darkGrey"]};
  text-align: center;
`;

const StyledIcon = styled.div`
  & svg {
    width: 3.5em;
    height: 3.5em;
  }
  height: 4rem;
  width: 4rem;
  border: 0.032rem solid ${colors["colors/borders/button/primary"]};
  border-radius: 0.5rem;
  fill: ${colors["colors/system/error/error_normal"]};
  justify-content: center;
  display: flex;
  align-items: center;
`;
