import { LineChartDS } from "@qivia/ui/src/designSystem/components/Charts/LineChart";
import { TotalType } from "@qivia/ui/src/designSystem/components/Charts/CardChart";
import {
  dateFormatterDayShortMonthYear,
  dateFormatterMonthYear,
} from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { EmptyChart } from "./emptyChart";
import { extractLineChartData } from "./lib";
import { GetTransactionsAmountsPerPeriodType } from "../../transactions/transactionsAPI";

export const PricePerLiterLineChart = (props: {
  totalType: TotalType;
  data: GetTransactionsAmountsPerPeriodType;
}) => {
  const { t } = useTranslation();

  const pricePerLiterData = extractLineChartData({
    transactions: props.data,
    totalType: props.totalType,
    dataPath: "averagePricePerLiter",
    formatter:
      props.totalType === "month"
        ? dateFormatterDayShortMonthYear
        : dateFormatterMonthYear,
  });

  return (
    <>
      {!pricePerLiterData.length ? (
        <EmptyChart />
      ) : (
        <LineChartDS
          transactions={pricePerLiterData}
          legend={t("dashboard.charts.title.pricePerLiter.legend")}
          unit={t("dashboard.charts.title.pricePerLiter.unit")}
        />
      )}
    </>
  );
};
