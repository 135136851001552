import {
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { colors } from "../../../styles/figmaColors";
import styled from "styled-components";
import { typographies } from "../../../styles/figmaTypographies";
import { Spacer } from "../Spacer";
import { TextCapitalized } from "../text/TextCapitalized";
import { CATEGORY_COLORS } from "./ChartColors";
import { CustomTick } from "./Custom/CustomTick";
import { CustomLineChartLegend } from "./Custom/CustomLineChartLegend";

interface MultipleLineChartProps {
  transactions: {
    name: string;
    data: {
      date: string;
      value: number;
    }[];
  }[];
  unit: string;
}

interface TooltipPayload {
  name: string;
  payload: {
    date: string;
    value: number;
  };
  color: string;
}

export const MultipleLineChartDS = (props: MultipleLineChartProps) => {
  const startDate = props.transactions[0].data[0].date;
  const endDate =
    props.transactions[0].data[
      props.transactions[props.transactions.length - 1].data.length - 1
    ].date;

  const transactionWithColors = props.transactions.map(
    (transaction, index) => ({
      ...transaction,
      color: colors[CATEGORY_COLORS[index % CATEGORY_COLORS.length]],
    }),
  );

  return (
    <ResponsiveContainer>
      <LineChart>
        <XAxis
          dataKey="date"
          type="category"
          allowDuplicatedCategory={false}
          ticks={startDate !== endDate ? [startDate, endDate] : [startDate]}
          tick={(tickProps) => <CustomTick {...tickProps} />}
          tickLine={false}
          tickMargin={15}
        />
        <YAxis
          dataKey="value"
          tick={(tickProps) => <CustomTick {...tickProps} />}
          tickMargin={15}
          padding={{ top: 8 }}
        />
        <Tooltip content={<CustomTooltip unit={props.unit} />} />
        <Legend content={<CustomLineChartLegend />} />
        {transactionWithColors.map((line) => (
          <Line
            legendType="plainline"
            dataKey="value"
            strokeWidth={2}
            data={line.data}
            name={line.name}
            key={line.name}
            stroke={line.color}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

const CustomTooltip = ({
  active,
  payload,
  unit,
}: {
  active?: boolean;
  payload?: TooltipPayload[];
  unit: string;
}) => {
  if (active && payload && payload.length > 0) {
    return (
      <StyledTooltip>
        <Spacer x={0.75} />
        <StyledColumn>
          <Spacer y={0.75} />
          <StyledRow>{payload[0].payload.date}</StyledRow>
          {payload.map(({ payload: { value }, name, color }, index) => (
            <StyledRow key={index + "tooltip"}>
              <StyledColor color={color}>
                <TextCapitalized>{name}:</TextCapitalized>
              </StyledColor>
              <Spacer x={0.3} />
              <StyledColor color={color}>
                {value.toString()} {unit}
              </StyledColor>
            </StyledRow>
          ))}
          <Spacer y={0.75} />
        </StyledColumn>
        <Spacer x={0.75} />
      </StyledTooltip>
    );
  }
  return null;
};

const StyledTooltip = styled.div`
  display: flex;
  ${typographies["Body/L"]}
  color: ${colors["colors/text/black"]};
  background-color: ${colors["colors/surfaces/background/background_level0"]};
  border-radius: 0.5rem;
  border: 0.0625rem solid ${colors["colors/borders/cards/cards"]};
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
`;

const StyledColor = styled.div<{ color: string }>`
  color: ${({ color }) => color};
`;

export const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
`;
