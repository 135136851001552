import { colors } from "../../../styles/figmaColors";

type ColorKeys = keyof typeof colors;

export const CATEGORY_COLORS: ColorKeys[] = [
  "colors/system/informative/informative_light",
  "colors/system/success/success_normal",
  "colors/system/informative/informative_normal",
  "colors/system/error/error_veryDark",
  "colors/accent/400",
  "colors/system/warning/warning_dark",
  "colors/button/danger/default",
  "colors/system/success/success_dark",
  "colors/button/danger/hover",
  "colors/system/informative/informative_dark",
  "colors/system/success/success_veryDark",
  "colors/system/informative/informative_veryDark",
  "colors/system/error/error_dark",
  "colors/accent/900",
] as const;
