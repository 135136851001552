import {
  ToastContainer,
  triggerToast,
  LaunchPage,
  sortByDateTime,
  PageDS,
  Tag,
  TableTagVehicle,
  AddOutlined,
  UploadFileOutlined,
  Badge,
  MessageTooltip,
  TextCapitalized,
  MaterialUiTable,
  usePersistMuiTableState,
  renderCellWithLabel,
  MaterialUiColumnsProps,
} from "@qivia/ui";
import { Trans, useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  selectErrorMessageMassivImport,
  selectListAllVehiclesInDb,
  selectMultipleVehiclesCreatedStatus,
  listAllVehiclesInDbAsync,
  vehiclesSlice,
  selectListAllVehiclesInDbStatus,
  selectDeactivateVehicleStatus,
} from "./vehiclesSlice";
import {
  VehiclesListType,
  VehiclesListDisplayedType,
  VehicleStatus,
} from "./vehiclesAPI";
import styled from "styled-components";
import Car from "@qivia/ui/src/designSystem/assets/Car.png";
import { Spacer } from "@qivia/ui/src/designSystem/components/Spacer";
import { VehicleSidePanel } from "./SidePanel";
import { useNavigate, useParams } from "react-router-dom";
import { typographies } from "@qivia/ui/src/styles/figmaTypographies";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import { selectCompany } from "../homeSlice";
import { documentDownloadXlsxAsync } from "../../../libSlice/downloadFileXslx";
import { AddVehicleModal } from "./modals/addVehicleModal";
import { ImportVehiclesModal } from "./modals/importVehiclesModal";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import {
  formatCurrency,
  formatMileage,
  formatCarbonEmissionPerKilometer,
  formatLiterPerOneHundredMileage,
} from "../../../libSlice/materialUiFormats";

export const Vehicles = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams<{ id?: string }>();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isVehiclesModalVisible, setIsVehiclesModalVisible] = useState(false);
  const [messageTooltip, setMessageTooltip] = useState<JSX.Element | null>(
    null,
  );
  const [titleTooltip, setTitleTooltip] = useState<string>("");
  const allVehiclesInDb = useAppSelector(selectListAllVehiclesInDb);
  const deactivateVehicleStatus = useAppSelector(selectDeactivateVehicleStatus);
  const company = useAppSelector(selectCompany);

  const listAllVehiclesInDbStatus = useAppSelector(
    selectListAllVehiclesInDbStatus,
  );
  const createMultipleVehiclesStatus = useAppSelector(
    selectMultipleVehiclesCreatedStatus,
  );

  const [listDisplayed, setListDisplayed] = useState<
    VehiclesListDisplayedType[] | null
  >(null);

  const vehiclesMassiveImportErrorMessage = useAppSelector(
    selectErrorMessageMassivImport,
  );

  const pageName = "vehicles";

  const findKeysToTranslate = useCallback(
    (key: keyof VehiclesListType, value: string) => {
      if (key === "status") {
        return t(`vehicles.status.${value}`);
      } else return value;
    },
    [t],
  );

  const keysToTranslate = useMemo(
    () => ["status"] as (keyof VehiclesListType)[],
    [],
  );

  const sidePanelAction = useCallback(
    (props: Partial<VehiclesListType>) => {
      navigate(`/home/vehicles/${props.uuid}`);
    },
    [navigate],
  );

  const apiRef = useGridApiRef();
  const key = "customers-grid";
  const aggregation = {
    model: {
      reference: "size",
      registrationNumber: "size",
      brand: "size",
      status: "size",
      lastDriver: "size",
      latestMileage: "avg",
      averagePricePerLiter: "avg",
      averageConsumption: "avg",
      averageCarbonEmissionPerKm: "avg",
    },
  };
  const dataGridState = usePersistMuiTableState(
    apiRef,
    key,
    pageName,
    aggregation,
  );

  const listAllVehiclesInDb: VehiclesListType[] = useMemo(() => {
    const rowsWithId = allVehiclesInDb.map((av) => {
      let newDict: VehiclesListType & { id: string } = {
        ...av,
        id: av.uuid,
      };
      Object.entries(av).forEach(([key, value]) => {
        if (keysToTranslate.includes(key as keyof VehiclesListDisplayedType)) {
          newDict = {
            ...newDict,
            [key]: value
              ? findKeysToTranslate(
                  key as keyof VehiclesListType,
                  value as string,
                )
              : "",
          };
        }
      });
      return newDict;
    });
    return sortByDateTime(rowsWithId, "desc");
  }, [allVehiclesInDb, findKeysToTranslate, keysToTranslate]);

  useEffect(() => {
    if (params.id) {
      const vehicleSelected = listAllVehiclesInDb.find(
        (v) => v.uuid === params.id,
      );
      if (vehicleSelected) {
        navigate(`/home/vehicles/${vehicleSelected.uuid}`);
      }
    }
  }, [dispatch, listAllVehiclesInDb, navigate, params.id]);

  useEffect(() => {
    if (
      createMultipleVehiclesStatus === "success" &&
      vehiclesMassiveImportErrorMessage
    ) {
      triggerToast(
        t("vehicles.modal.addMultipleVehicles.sameListError"),
        "error",
      );
    } else if (createMultipleVehiclesStatus === "failed") {
      triggerToast(
        t("vehicles.modal.addMultipleVehicles.failure") || "",
        "error",
      );
    }
  }, [createMultipleVehiclesStatus, t, vehiclesMassiveImportErrorMessage]);

  useEffect(() => {
    if (deactivateVehicleStatus === "success") {
      triggerToast(t("vehicles.deactivated.success") || "", "valid");
    } else if (deactivateVehicleStatus === "failed") {
      triggerToast(t("vehicles.deactivated.failure") || "", "error");
    }
  }, [deactivateVehicleStatus, t]);

  useEffect(() => {
    if (company) {
      void dispatch(listAllVehiclesInDbAsync({ companyUuid: company.uuid }));
    }
  }, [dispatch, company, deactivateVehicleStatus]);

  useEffect(() => {
    if (listAllVehiclesInDb && listAllVehiclesInDbStatus === "success") {
      const newVehiclesList = listAllVehiclesInDb.filter((v) => v.isNewVehicle);
      const updatedVehiclesList = listAllVehiclesInDb.filter(
        (v) => v.isUpdatedVehicle,
      );
      const alreadyExistedVehiclesList = listAllVehiclesInDb.filter(
        (v) => v.isAlreadyExisted,
      );

      const stringifyAlreadyVehiclesList = alreadyExistedVehiclesList
        .map((vehicle) => {
          return [vehicle.registrationNumber, vehicle.reference]
            .filter((d) => d)
            .join(" / ");
        })
        .join(", ");

      const vehiclesImportLength =
        newVehiclesList.length +
        updatedVehiclesList.length +
        alreadyExistedVehiclesList.length;

      const toolTipMessageAllVehicles =
        vehiclesImportLength > 0
          ? vehiclesImportLength === 1
            ? t("vehicles.status.messageTooltip.importAll.singular")
            : t("vehicles.status.messageTooltip.importAll.plural", {
                number: vehiclesImportLength.toString(),
              })
          : "";

      setTitleTooltip(toolTipMessageAllVehicles);

      const toolTipMessageCreated =
        newVehiclesList.length > 0
          ? newVehiclesList.length === 1
            ? t("vehicles.status.messageTooltip.creation.singular")
            : t("vehicles.status.messageTooltip.creation.plural", {
                number: newVehiclesList.length.toString(),
              })
          : "";

      const toolTipMessageUpdated =
        updatedVehiclesList.length > 0
          ? updatedVehiclesList.length === 1
            ? t("vehicles.status.messageTooltip.update.singular")
            : t("vehicles.status.messageTooltip.update.plural", {
                number: updatedVehiclesList.length.toString(),
              })
          : "";

      const toolTipMessageAlreadyExists =
        alreadyExistedVehiclesList.length > 0 ? (
          alreadyExistedVehiclesList.length === 1 ? (
            <Trans
              i18nKey={t(
                "vehicles.status.messageTooltip.alreadyExists.singular",
                {
                  registrationNumbers: stringifyAlreadyVehiclesList,
                },
              )}
            />
          ) : (
            <Trans
              i18nKey={t(
                "vehicles.status.messageTooltip.alreadyExists.plural",
                {
                  number: alreadyExistedVehiclesList.length.toString(),
                  registrationNumbers: stringifyAlreadyVehiclesList,
                },
              )}
            />
          )
        ) : (
          ""
        );

      setMessageTooltip(
        vehiclesImportLength > 0 ? (
          <StyledColumn>
            <div>
              <TextCapitalized>{toolTipMessageCreated}</TextCapitalized>
            </div>
            {toolTipMessageCreated && toolTipMessageUpdated && (
              <Spacer y={0.5} />
            )}
            <div>
              <TextCapitalized>{toolTipMessageUpdated}</TextCapitalized>
            </div>
            {toolTipMessageUpdated && toolTipMessageAlreadyExists && (
              <Spacer y={0.5} />
            )}
            <div>
              <TextCapitalized>{toolTipMessageAlreadyExists}</TextCapitalized>
            </div>
          </StyledColumn>
        ) : null,
      );

      const vehiclesDateTimeSorted = sortByDateTime(
        listAllVehiclesInDb,
        "desc",
      );

      setListDisplayed(vehiclesDateTimeSorted);
      dispatch(vehiclesSlice.actions.resetListAllVehiclesInDbStatus());
    }
  }, [dispatch, listAllVehiclesInDbStatus, listAllVehiclesInDb, t]);

  const searchBarProps = {
    values: listAllVehiclesInDb,
    setFilterValues: setListDisplayed,
    keysToIgnore: [
      "date" as const,
      "uuid" as const,
      "latestMileage" as const,
      "latestMileageDate" as const,
      "rule" as const,
      "ruleName" as const,
      "cardUuid" as const,
      "cardName" as const,
      "energy" as const,
      "vehicleType" as const,
      "deliveryDate" as const,
      "createdAt" as const,
      "isNewVehicle" as const,
      "isAlreadyExisted" as const,
      "isUpdatedVehicle" as const,
    ],
  };

  const downloadViewAction = useCallback(
    (file: Buffer | undefined) => {
      if (!file) return;
      void dispatch(
        documentDownloadXlsxAsync({
          buffer: file,
          fileName: "QIVIA_" + t("vehicles.title") + ".xlsx",
        }),
      );
    },
    [t, dispatch],
  );

  const columnsDisplayed: {
    [index in keyof VehiclesListDisplayedType]: MaterialUiColumnsProps;
  } = useMemo(() => {
    return {
      reference: {
        type: "string",
        width: 250,
        valueFormatter: (value) =>
          value && (
            <StyledRow>
              <div style={{ width: "2rem" }}>
                <img
                  src={Car}
                  style={{ width: "100%", filter: "grayscale(100%)" }}
                  alt="Car"
                />
              </div>
              <Spacer x={0.5} />
              <Tag
                textColor={colors["colors/text/black"]}
                backgroundColor={
                  colors["colors/surfaces/background/background_level0"]
                }
                text={value}
                borderColor={colors["colors/borders/cells/cells"]}
              />
            </StyledRow>
          ),
      },
      registrationNumber: {
        type: "string",
        width: 250,
        renderCell: (p) => {
          const isNewVehicle = p.row["isNewVehicle"] as string;
          const isUpdatedVehicle = p.row["isUpdatedVehicle"] as string;
          return renderCellWithLabel(
            p,
            "Nb d'éléments",
            <>
              {isNewVehicle || isUpdatedVehicle ? (
                <>
                  {p.formattedValue as string}
                  <Spacer x={0.5} />
                  <Badge
                    text={t(
                      `vehicles.status.badge.${isNewVehicle ? "new" : "updated"}`,
                    )}
                    backgroundColor={
                      colors["colors/system/informative/informative_ultraLight"]
                    }
                    textColor={
                      colors["colors/system/informative/informative_normal"]
                    }
                  />
                </>
              ) : (
                ((p.formattedValue as string) ?? "-")
              )}
            </>,
          );
        },
      },
      brand: {
        type: "string",
        width: 300,
        valueFormatter: (value, row) => (
          <StyledRow>
            <StyledBrand>{value}</StyledBrand>
            {value && <Spacer x={0.25} />}
            <StyledModel>{row["model"]}</StyledModel>
          </StyledRow>
        ),
      },
      status: {
        type: "string",
        valueFormatter: (value: VehicleStatus) =>
          value && (
            <StyledRow>
              <TableTagVehicle status={value} />
            </StyledRow>
          ),
      },
      lastDriver: {
        type: "string",
        width: 250,
      },
      latestMileage: {
        type: "number",
        valueFormatter: formatMileage,
      },
      averageConsumption: {
        type: "number",
        width: 200,
        valueFormatter: formatLiterPerOneHundredMileage,
      },
      averageCarbonEmissionPerKm: {
        type: "number",
        width: 250,
        valueFormatter: formatCarbonEmissionPerKilometer,
      },
      averagePricePerLiter: {
        type: "number",
        valueFormatter: formatCurrency,
      },
    };
  }, [t]);

  if (!listDisplayed) return <LaunchPage hasBorderRadius={true} />;

  const cta = {
    title: t("vehicles.cta"),
    action: () => {
      setIsModalVisible(true);
    },
    leftIcon: <AddOutlined />,
  };

  const cta2 = {
    title: t("vehicles.cta2"),
    action: () => {
      setIsVehiclesModalVisible(true);
    },
    leftIcon: <UploadFileOutlined />,
  };

  return (
    <>
      <AddVehicleModal
        visible={isModalVisible}
        onCloseModal={() => {
          setIsModalVisible(false);
        }}
      />
      <ImportVehiclesModal
        visible={isVehiclesModalVisible}
        onCloseModal={() => {
          setIsVehiclesModalVisible(false);
        }}
      />
      {!isVehiclesModalVisible && (
        <PageDS
          title={t("vehicles.title")}
          cta={cta}
          cta2={cta2}
          //isEmptyTable={listDisplayed.length === 0}
          toaster={<ToastContainer />}
          topElement={
            messageTooltip ? (
              <MessageTooltip
                title={titleTooltip}
                text={messageTooltip}
                type={"INFORMATIVE"}
              />
            ) : (
              ""
            )
          }
        >
          <MaterialUiTable<
            keyof VehiclesListDisplayedType,
            VehiclesListDisplayedType
          >
            rows={listDisplayed}
            columns={columnsDisplayed}
            pageName={pageName}
            sidePanelAction={sidePanelAction}
            dataGridState={dataGridState}
            searchBar={searchBarProps}
            apiRef={apiRef}
            downloadViewAction={downloadViewAction}
            cta={cta}
            cta2={cta2}
          />
          <VehicleSidePanel />
        </PageDS>
      )}
    </>
  );
};

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const StyledBrand = styled.div`
  ${typographies["Body/M"]}
`;

const StyledModel = styled.div`
  ${typographies["Body/XS"]}
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
`;
