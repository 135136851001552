import styled from "styled-components";
import { Spacer } from "../Spacer";
import { SidePanel } from "../../../components/SidePanel";
import { ButtonDS } from "../Button";
import { colors } from "../../../styles/figmaColors";
import { typographies } from "../../../styles/figmaTypographies";
import { TextCapitalized } from "../text/TextCapitalized";
import { SupportDS } from "../Support";
import { StepHeader, StepHeaderProps } from "../StepHeader";
import { ClearOutlined } from "../../materialUi/materialUi";

interface PageProps {
  title: string | JSX.Element;
  subTitle?: string;
  titleElement?: {
    closeButtonAction?: () => void;
  };
  cta?: {
    title: string;
    action: () => void;
    width?: number;
    isDisabled?: boolean;
    leftIcon?: JSX.Element | null;
  };
  cta2?: {
    title: string;
    action: () => void;
    width?: number;
    isDisabled?: boolean;
    leftIcon?: JSX.Element | null;
  };
  isEmptyTable?: boolean;
  children?: string | JSX.Element | JSX.Element[];
  toaster?: JSX.Element;
  sidePanel?: {
    isSidePanelDisplayed: boolean;
    onCloseSidePanel: () => void;
    sidePanelChildren: string | JSX.Element | JSX.Element[];
    sidePanelStickyBottom: string | JSX.Element | JSX.Element[];
  };
  stepHeader?: StepHeaderProps;
  topElement?: string | JSX.Element;
  withoutSupport?: boolean;
  withoutTopElement?: boolean;
}

export const PageDS = (props: PageProps) => {
  return (
    <StyledFrame>
      <Spacer x={2.5} />
      <StyledColumn>
        {props.toaster}
        {!props.withoutTopElement && (
          <>
            <Spacer y={2.5} />
            {props.title && (
              <Title
                cta={props.cta}
                cta2={props.cta2}
                isEmptyTable={props.isEmptyTable}
                title={props.title}
                subTitle={props.subTitle}
                titleElement={props.titleElement}
              />
            )}
            {props.titleElement?.closeButtonAction && (
              <>
                <Spacer y={2.5} />
                <StyledLine />
                <Spacer y={2.5} />
              </>
            )}
            {props.subTitle && (
              <>
                <StyledSubTitle>
                  <TextCapitalized>{props.subTitle}</TextCapitalized>
                </StyledSubTitle>
                <Spacer y={1} />
              </>
            )}
            {props.stepHeader && <StepHeader {...props.stepHeader} />}
            {props.topElement}
            <Spacer y={1} />
          </>
        )}
        <StyledContent>
          {props.children}
          {props.sidePanel && (
            <SidePanel
              visible={props.sidePanel.isSidePanelDisplayed}
              onClose={props.sidePanel.onCloseSidePanel}
              stickyBottom={props.sidePanel.sidePanelStickyBottom}
            >
              {props.sidePanel.sidePanelChildren}
            </SidePanel>
          )}
        </StyledContent>
        <Spacer y={2} />
      </StyledColumn>
      <Spacer x={2.5} />
      {!props.withoutSupport && (
        <StyledSupport>
          <SupportDS />
        </StyledSupport>
      )}
    </StyledFrame>
  );
};

const Title = (props: {
  cta?: {
    title: string;
    action: () => void;
    width?: number;
    isDisabled?: boolean;
    leftIcon?: JSX.Element | null;
  };
  cta2?: {
    title: string;
    action: () => void;
    width?: number;
    isDisabled?: boolean;
    leftIcon?: JSX.Element | null;
  };
  isEmptyTable?: boolean;
  title: string | JSX.Element;
  subTitle?: string;
  titleElement?: {
    closeButtonAction?: () => void;
  };
}) => {
  return (
    <StyledTitle>
      <StyledAllWidth>
        <TextCapitalized>{props.title}</TextCapitalized>
      </StyledAllWidth>
      <Spacer x={1.5} />
      <StyledFlexEnd>
        {!props.isEmptyTable && (
          <StyledFlex>
            {props.cta2 && (
              <>
                <ButtonDS
                  onClick={props.cta2.action}
                  text={props.cta2.title}
                  format={"hug"}
                  buttonType={"secondary"}
                  leftIcon={props.cta2.leftIcon}
                  disabled={props.cta2.isDisabled}
                />
                <Spacer x={0.75} />
              </>
            )}
            {props.cta && (
              <ButtonDS
                onClick={props.cta.action}
                text={props.cta.title}
                format={"hug"}
                buttonType={"primary"}
                leftIcon={props.cta.leftIcon}
                disabled={props.cta.isDisabled}
              />
            )}
          </StyledFlex>
        )}
        <Spacer x={1} />
        {props.titleElement?.closeButtonAction && (
          <ButtonDS
            format="hug"
            buttonType="secondary"
            singleIcon={{ icon: <ClearOutlined />, size: "L" }}
            onClick={props.titleElement?.closeButtonAction}
          />
        )}
      </StyledFlexEnd>
    </StyledTitle>
  );
};

const StyledFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledFlexEnd = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;

const StyledTitle = styled.div`
  display: flex;
  ${typographies["Header/H1"]};
  colors: ${colors["colors/text/black"]};
  align-items: center;
  min-height: 3rem;
  width: 100%;
  justify-content: flex-start;
`;

const StyledAllWidth = styled.div`
  width: 100%;
`;

const StyledSubTitle = styled.div`
  width: 100%;
  ${typographies["Body/XS"]};
  font-size: 1rem;
`;

const StyledSupport = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

const StyledFrame = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  background-color: ${colors["colors/surfaces/background/background_level0"]};
  border-radius: 0.75rem 0 0 0.75rem;
  overflow: hidden;
`;

const StyledContent = styled.div`
  width: 100%;
  height: fit-content;
  overflow-x: auto;
  overflow-y: auto;
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const StyledLine = styled.div`
  display: flex;
  border-top: 0.0625rem solid ${colors["colors/borders/cells/cells"]};
  width: 100%;
`;
