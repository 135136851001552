import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { useNavigate, useParams } from "react-router-dom";
import {
  selectCompany,
  selectLoadCompanyStatus,
  selectManager,
} from "../homeSlice";
import { styled } from "styled-components";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import {
  Assets,
  Badge,
  ButtonDS,
  LaunchPage,
  MessageTooltip,
  Spacer,
  TextCapitalized,
  triggerToast,
} from "@qivia/ui";
import { typographies } from "@qivia/ui/src/styles/figmaTypographies";
import {
  bankDetailsExportAsync,
  billingsSlice,
  documentDownloadAsync,
  selectBankDetailsExportLink,
  selectBankDetailsExportStatus,
} from "./billingsSlice";
import { useEffect, useMemo } from "react";
import {
  selectIsStripeConnectMandateSigned,
  selectIsStripePlatformMandateSigned,
  sepaDebitSlice,
} from "./sepaDebitSlice";

export const AccountTab = () => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const company = useAppSelector(selectCompany);
  const manager = useAppSelector(selectManager);
  const bankDetailsExportStatus = useAppSelector(selectBankDetailsExportStatus);
  const companyStatus = useAppSelector(selectLoadCompanyStatus);
  const bankDetailsExportLink = useAppSelector(selectBankDetailsExportLink);
  const isStripeConnectMandateSigned = useAppSelector(
    selectIsStripeConnectMandateSigned,
  );
  const isStripePlatformMandateSigned = useAppSelector(
    selectIsStripePlatformMandateSigned,
  );

  useEffect(() => {
    if (companyStatus === "processing") {
      void dispatch(sepaDebitSlice.actions.setConnectSepaMandateSigned(null));
      void dispatch(sepaDebitSlice.actions.setPlatformSepaMandateSigned(null));
    }
  }, [companyStatus, dispatch, navigate]);

  useEffect(() => {
    if (
      companyStatus === "success" &&
      company &&
      isStripeConnectMandateSigned === null &&
      isStripePlatformMandateSigned === null
    ) {
      void dispatch(
        sepaDebitSlice.actions.setConnectSepaMandateSigned(
          company?.connectSepaMandateSigned,
        ),
      );
      void dispatch(
        sepaDebitSlice.actions.setPlatformSepaMandateSigned(
          company?.platformSepaMandateSigned,
        ),
      );
    }
  }, [
    company,
    dispatch,
    isStripeConnectMandateSigned,
    isStripePlatformMandateSigned,
    companyStatus,
  ]);

  useEffect(() => {
    if (bankDetailsExportStatus === "success") {
      triggerToast(t("accountData.bankDetails.upload.success") || "", "valid");
      dispatch(billingsSlice.actions.resetBankDetailsExportStatus());
    } else if (bankDetailsExportStatus === "failed") {
      triggerToast(t("accountData.bankDetails.upload.failure") || "", "error");
      dispatch(billingsSlice.actions.resetBankDetailsExportStatus());
    }
  }, [bankDetailsExportStatus, dispatch, t]);

  useEffect(() => {
    if (bankDetailsExportLink.presignedUrl && bankDetailsExportLink.fileName) {
      void dispatch(documentDownloadAsync(bankDetailsExportLink));
    }
  }, [bankDetailsExportLink, dispatch]);

  const isSepaDebitCardVisibleByManager = useMemo(() => {
    return manager && manager.role === "OWNER";
  }, [manager]);

  if (params.tab !== "account" || !company || !manager) {
    return;
  }

  const hasIbanAndBic: boolean = !!(
    company.iban &&
    company.iban !== "" &&
    company.bic &&
    company.bic !== ""
  );

  const ibanDetails = hasIbanAndBic
    ? t("account.bankDetails.ibanDetails", {
        country: company.iban.substring(0, 2),
        code: company.iban.substring(2, 4),
        bank: company.iban.substring(4, 12),
        account: company.iban.substring(12, 22),
      })
    : "";

  let ibanFormatted = "";
  if (hasIbanAndBic) {
    for (let i = 0; i <= 20; i = i + 4) {
      ibanFormatted += company.iban.substring(i, i + 4) + " ";
    }
  }

  const bankDetailsText = t("account.bankDetails.copy.text", {
    iban: ibanFormatted,
    bic: company.bic,
  });

  if (companyStatus !== "success") {
    return <LaunchPage />;
  }

  return (
    <StyledColumn>
      <Spacer y={1} />
      <StyledRow>
        <StyledFrame>
          <Spacer x={2} />
          <StyledColumn>
            <Spacer y={2} />
            <StyledTitle>
              <TextCapitalized>{t(`account.balance.title`)}</TextCapitalized>
            </StyledTitle>
            <Spacer y={1.5} />
            <StyledRow>
              <StyledBalance>
                {company.balance.value ? company.balance.value / 100 : 0}
              </StyledBalance>
              <StyledCurrency>
                &nbsp;{t(`account.balance.currency`).toLocaleUpperCase()}
              </StyledCurrency>
            </StyledRow>
            <Spacer y={1.5} />
            <StyledRowWrap>
              <Badge
                text={
                  hasIbanAndBic
                    ? t(`account.verified`)
                    : t(`account.unverified`)
                }
                textColor={
                  hasIbanAndBic
                    ? colors["colors/system/success/success_dark"]
                    : colors["colors/system/error/error_dark"]
                }
                backgroundColor={
                  hasIbanAndBic
                    ? colors["colors/system/success/success_veryLight"]
                    : colors["colors/system/error/error_veryLight"]
                }
              />
              <Badge
                text={
                  isStripePlatformMandateSigned
                    ? t(`account.platformSepaMandate.signed`)
                    : t(`account.platformSepaMandate.unsigned`)
                }
                textColor={
                  isStripePlatformMandateSigned
                    ? colors["colors/system/informative/informative_normal"]
                    : colors["colors/system/warning/warning_dark"]
                }
                backgroundColor={
                  isStripePlatformMandateSigned
                    ? colors["colors/system/informative/informative_ultraLight"]
                    : colors["colors/system/warning/warning_veryLight"]
                }
              />
            </StyledRowWrap>
            <Spacer y={2} />
          </StyledColumn>
          <Spacer x={2} />
        </StyledFrame>
        <Spacer x={2} />
        {isSepaDebitCardVisibleByManager && !company.externalIban && (
          <StyledSepaDebitColumn>
            <StyledColumn>
              <Spacer y={1} />
              <StyledTitle>
                <TextCapitalized>
                  {t(`account.externalBankAccount.title`)}
                </TextCapitalized>
              </StyledTitle>
              <Spacer y={1} />
              <TextCapitalized>
                <StyledSepaDebitText>
                  <div>{t(`account.externalBankAccount.text`)}</div>
                </StyledSepaDebitText>
              </TextCapitalized>
              <Spacer y={1} />
              <div>
                <ButtonDS
                  sizeButton={"S"}
                  format={"hug"}
                  buttonType={"primary"}
                  text={t(`account.externalBankAccount.button`)}
                  onClick={() =>
                    navigate(`/home/billings/account/external_account`)
                  }
                />
              </div>
            </StyledColumn>
          </StyledSepaDebitColumn>
        )}
        {isSepaDebitCardVisibleByManager &&
          company.externalIban &&
          !isStripePlatformMandateSigned && (
            <StyledColumn>
              <Spacer y={1.5} />
              <StyledTitle>
                <TextCapitalized>
                  {t(`account.sepaDebit.created.title`)}
                </TextCapitalized>
              </StyledTitle>
              <Spacer y={1.5} />
              {!company.canActivatePlatformSepaDebit && (
                <MessageTooltip
                  title={t(`account.connect.sepaDebit.messageTooltip.title`)}
                  text={t(`account.connect.sepaDebit.messageTooltip.text`)}
                  type={"NEUTRAL"}
                />
              )}
              <StyledColumn>
                {!isStripePlatformMandateSigned &&
                  company.canActivatePlatformSepaDebit && (
                    <ButtonDS
                      sizeButton={"S"}
                      format={"hug"}
                      buttonType={"primary"}
                      text={t(`account.platform.sepaDebit.button`)}
                      onClick={() => {
                        if (company.canActivatePlatformSepaDebit) {
                          navigate(
                            `/home/billings/account/sign_platform_mandate`,
                          );
                        }
                      }}
                    />
                  )}
              </StyledColumn>
              <Spacer y={2} />
            </StyledColumn>
          )}
      </StyledRow>
      <Spacer y={1} />
      {hasIbanAndBic && (
        <StyledRow>
          <StyledFrame>
            <Spacer x={2} />
            <StyledColumn>
              <Spacer y={2} />
              <StyledTitle>
                <TextCapitalized>
                  {t(`account.bankDetails.title`)}
                </TextCapitalized>
              </StyledTitle>
              <Spacer y={1.5} />
              <StyledSubTitle>
                <StyledRowSpaceBetween>
                  <div>{t(`account.bankDetails.iban.title`)}</div>
                  <div>{t(`account.bankDetails.bic.title`)}</div>
                </StyledRowSpaceBetween>
              </StyledSubTitle>
              <StyledRowSpaceBetween>
                <div>{ibanFormatted}</div>
                <div>{company.bic}</div>
              </StyledRowSpaceBetween>
              <Spacer y={0.25} />
              <StyledSmallText>{ibanDetails}</StyledSmallText>
              <Spacer y={1} />
              <StyledSubTitle>
                <TextCapitalized>
                  {t(`account.bankDetails.holder.title`)}
                </TextCapitalized>
              </StyledSubTitle>
              <div>{t(`account.bankDetails.holder.text.part1`)}</div>
              <div>{t(`account.bankDetails.holder.text.part2`)}</div>
              <div>{t(`account.bankDetails.holder.text.part3`)}</div>
              <Spacer y={1} />
              <StyledSubTitle>
                <TextCapitalized>
                  {t(`account.bankDetails.address.title`)}
                </TextCapitalized>
              </StyledSubTitle>
              <StyledSmallText>
                {t(`account.bankDetails.address.text`)}
              </StyledSmallText>
              <Spacer y={2} />
            </StyledColumn>
            <Spacer x={2} />
          </StyledFrame>
          <Spacer x={2} />
          {company.bankDetailsBucketFileName && (
            <StyledColumn>
              <Spacer y={1} />
              <StyledTitle>
                <TextCapitalized>
                  {t(`account.bankDetails.download.title`)}
                </TextCapitalized>
              </StyledTitle>
              <Spacer y={1.5} />
              <StyledRow>
                <ButtonDS
                  sizeButton={"S"}
                  format={"hug"}
                  buttonType={"secondary"}
                  text={t(`account.bankDetails.download.button`)}
                  onClick={() => {
                    void dispatch(
                      bankDetailsExportAsync({
                        bucketFileName: company.bankDetailsBucketFileName ?? "",
                        fileName: "Qivia_RIB.pdf",
                      }),
                    );
                  }}
                />
                <Spacer x={1} />
                <div>
                  <ButtonDS
                    leftIcon={<Assets.Copy />}
                    sizeButton={"S"}
                    format={"fill"}
                    buttonType={"primary"}
                    text={t(`account.bankDetails.copy.button`)}
                    onClick={() => {
                      void navigator.clipboard.writeText(bankDetailsText);
                      triggerToast(
                        t("account.bankDetails.copy.success") || "",
                        "valid",
                      );
                    }}
                  />
                </div>
              </StyledRow>
            </StyledColumn>
          )}
        </StyledRow>
      )}
      <Spacer y={1} />
      {isSepaDebitCardVisibleByManager && company.externalIban && (
        <StyledFrame>
          <Spacer x={2} />
          <StyledColumn>
            <Spacer y={2} />
            <StyledTitle>
              <TextCapitalized>
                {t(`account.externalBankAccount.created.title`)}
              </TextCapitalized>
            </StyledTitle>
            <Spacer y={1} />
            <StyledSubTitle>
              <TextCapitalized>
                {t("account.externalBankAccount.modal.bankName")}
              </TextCapitalized>
            </StyledSubTitle>
            {company.name}
            <Spacer y={0.5} />
            <StyledSubTitle>
              <TextCapitalized>
                {t("account.externalBankAccount.modal.ibanButton")}
              </TextCapitalized>
            </StyledSubTitle>
            {company.externalIban}
            <Spacer y={2} />
          </StyledColumn>
          <Spacer x={2} />
        </StyledFrame>
      )}
    </StyledColumn>
  );
};

const StyledFrame = styled.div`
  display: flex;
  width: 100%;
  border: 0.0625rem solid ${colors["colors/borders/cells/cells"]};
  border-radius: 0.5rem;
  max-width: 28rem;
  min-width: 20rem;
  ${typographies["Paragraph/P2"]};
`;

const StyledSepaDebitColumn = styled.div`
  display: flex;
  max-width: 20.5rem;
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 55rem;
`;

const StyledRow = styled.div`
  display: flex;
`;

const StyledRowWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.4rem;
`;

const StyledRowSpaceBetween = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const StyledTitle = styled.div`
  ${typographies["Header/H5"]};
`;

const StyledSubTitle = styled.div`
  ${typographies["Body/XXXS"]}
  color: ${colors["colors/text/darkGrey"]};
`;

const StyledSepaDebitText = styled.div`
  ${typographies["Body/XXS"]}
`;

const StyledSmallText = styled.div`
  font-size: 0.5rem;
  font-weight: 400;
  color: ${colors["colors/text/darkGrey"]};
`;

const StyledBalance = styled.div`
  display: flex;
  font-size: 1.5rem;
  font-weight: 700;
`;

const StyledCurrency = styled.div`
  display: flex;
  ${typographies["Header/H4"]};
`;
