import styled from "styled-components";
import { typographies } from "../../../../styles/figmaTypographies";

interface CustomTickProps {
  x: number;
  y: number;
  payload: {
    value: number;
  };
}

export const CustomTick = ({ x, y, payload }: CustomTickProps) => {
  return (
    <>
      <StyledText textAnchor="middle" x={x} y={y}>
        {payload.value}
      </StyledText>
    </>
  );
};

const StyledText = styled.text`
  ${typographies["Body/S"]}
`;
