import {
  ButtonDS,
  DialogModalDS,
  DownloadOutlined,
  RowElement,
  Spacer,
  VehicleCard,
} from "@qivia/ui";
import { useCallback, useMemo } from "react";
import { QrCodeInfo } from "../cardsAPI";
import { useAppDispatch } from "../../../../redux/hooks";
import { qrCodeDownloadAsync } from "../cardsSlice";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { VehicleType } from "../../vehicles/vehiclesAPI";

type Information = {
  name: string | null;
  lastName?: string;
  uuid: string | null;
  reference?: string | null;
  type: VehicleType;
};

type CardsQrCodesModalProps = {
  vehicle: Information;
  qrCodes: QrCodeInfo[];
  isQrCodeModalOpen: boolean;
  setIsQrCodeModalOpen: (b: boolean) => void;
};

export default function CardsQrCodesModal(props: CardsQrCodesModalProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleDownload = useCallback(
    ({ archiveFileName, identifier }: QrCodeInfo) => {
      void dispatch(
        qrCodeDownloadAsync({ bucketFileName: archiveFileName, identifier }),
      );
    },
    [dispatch],
  );

  const modalTitle = useMemo(
    () =>
      props.qrCodes.length > 1
        ? t("cards.sidePanel.modal.title.qrCodes")
        : t("cards.sidePanel.modal.title.qrCode"),
    [props.qrCodes.length, t],
  );

  return (
    <DialogModalDS
      visible={props.isQrCodeModalOpen}
      onClose={() => {
        props.setIsQrCodeModalOpen(false);
      }}
      title={modalTitle}
    >
      <StyledModal>
        <VehicleCard
          registrationNumber={props.vehicle.name ?? ""}
          reference={props.vehicle.reference ?? ""}
          vehicleType={props.vehicle.type}
        />
        <Spacer y={1.5} />
        {props.qrCodes.map((qrCode, i) => (
          <div key={qrCode.identifier}>
            {i !== 0 && <Spacer y={1} />}
            <RowElement
              text={t("cards.sidePanel.modal.item.title.qrCodes")}
              subText={qrCode.identifier}
              size="L"
              rightElement={
                <ButtonDS
                  buttonType="primary"
                  format="hug"
                  leftIcon={<DownloadOutlined />}
                  text={t("cards.sidePanel.button.downloadQrCode")}
                  sizeButton="S"
                  onClick={() => handleDownload(qrCode)}
                />
              }
            />
          </div>
        ))}
      </StyledModal>
    </DialogModalDS>
  );
}

const StyledModal = styled.div`
  width: 100%;
`;
