import {
  PieChartDS,
  PieChartData,
} from "@qivia/ui/src/designSystem/components/Charts/PieChart";
import { TotalType } from "@qivia/ui/src/designSystem/components/Charts/CardChart";
import { useTranslation } from "react-i18next";
import { EmptyChart } from "./emptyChart";
import { TotalAmountPerMerchantGroupType } from "../../transactions/transactionsAPI";
import { GetTransactionsAmountsPerPeriodType } from "../../transactions/transactionsAPI";

export const MerchantGroupsPieChart = (props: {
  totalType: TotalType;
  data: GetTransactionsAmountsPerPeriodType;
}) => {
  const { t } = useTranslation();

  const mapMerchantGroups = (
    merchantGroup: TotalAmountPerMerchantGroupType,
  ) => {
    return {
      name: merchantGroup.merchantGroup
        ? t(
            `dashboard.charts.title.merchantGroups.${merchantGroup.merchantGroup}`,
          )
        : t("dashboard.charts.title.merchantGroups.OTHER"),
      value: merchantGroup.amount / 100,
    };
  };

  let merchantGroupsData: PieChartData[] = [];

  if (props.totalType === "month") {
    merchantGroupsData =
      props.data.month.merchantGroupsAmounts.map(mapMerchantGroups);
  } else if (props.totalType === "quarter") {
    merchantGroupsData =
      props.data.quarter.merchantGroupsAmounts.map(mapMerchantGroups);
  } else if (props.totalType === "year") {
    merchantGroupsData =
      props.data.year.merchantGroupsAmounts.map(mapMerchantGroups);
  }

  return (
    <>
      {!merchantGroupsData.length ? (
        <EmptyChart />
      ) : (
        <PieChartDS categoryData={merchantGroupsData} unit="€" />
      )}
    </>
  );
};
