import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DriverPlannings } from "./driverPlanningsApi";
import * as XLSX from "xlsx";
import Ajv from "ajv";
import { authAxios, QueryStatus } from "../../../utils";
import { RootState } from "../../../redux/store";

export type DriverPlanningsState = {
  driverPlannings: DriverPlannings[];
  driverPlanningsStatus: QueryStatus;
};

const initialState: DriverPlanningsState = {
  driverPlannings: [],
  driverPlanningsStatus: "idle",
};

const schema = {
  type: "array",
  items: {
    type: "object",
    properties: {
      "Code salarié": {
        type: "string",
      },
      "Date début intervention": {
        type: "string",
        pattern: "^[0-9][0-9]?/[0-9][0-9]?/[0-9]{2,4}$",
      },
      "Heure début intervention": {
        type: "string",
        pattern: "^[0-9]{4}$",
      },
      "Heure fin intervention": {
        type: "string",
        pattern: "^[0-9]{4}$",
      },
      Kilomètres: {
        type: "string",
        pattern: "^[0-9]+\\.?[0-9]*$",
      },
    },
    required: [
      "Code salarié",
      "Date début intervention",
      "Heure début intervention",
      "Heure fin intervention",
      "Kilomètres",
    ],
  },
};

type InputLine = {
  "Code salarié": string;
  "Date début intervention": string;
  "Heure fin intervention": string;
  "Heure début intervention": string;
  Kilomètres: string;
};

const ajv = new Ajv({ formats: { date: "date" } });

const validate = ajv.compile(schema);

const parseLine = (line: InputLine): DriverPlannings => {
  const dateSplitted = line["Date début intervention"].split("/");
  let day: string;
  let month: string;
  let year: string;

  if (dateSplitted[2].length === 2) {
    [month, day, year] = dateSplitted;
    year = `20${year}`;
  } else {
    [day, month, year] = dateSplitted;
  }

  return {
    driverIdNumber: line["Code salarié"],
    expectedMileage: parseFloat(line["Kilomètres"]),
    date: new Date(
      parseInt(year),
      parseInt(month) - 1,
      parseInt(day),
      12,
      0,
      0,
    ).toISOString(),
    startTime: {
      hours: parseInt(line["Heure début intervention"].slice(0, 2)),
      minutes: parseInt(line["Heure début intervention"].slice(2, 4)),
    },
    endTime: {
      hours: parseInt(line["Heure fin intervention"].slice(0, 2)),
      minutes: parseInt(line["Heure fin intervention"].slice(2, 4)),
    },
  };
};

export const processFile = (file: File): Promise<DriverPlannings[]> =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    fileReader.onload = (event) => {
      if (event?.target) {
        try {
          const bufferArray = event.target.result;
          const workBook = XLSX.read(bufferArray, { type: "buffer" });
          const sheet = workBook.Sheets[workBook.SheetNames[0]];
          const jsonData: InputLine[] = XLSX.utils.sheet_to_json(sheet, {
            raw: false,
          });
          if (!validate(jsonData)) {
            throw new Error("Invalid data format");
          }
          const data = jsonData.map(parseLine);
          resolve(data);
        } catch (e) {
          reject(e);
        }
      }
    };
  });
export const uploadDriverPlanningsFileThunk = createAsyncThunk(
  "driverPlannings/upload",
  async (payload: { file: File; companyUuid: string }) => {
    const driverPlannings = await processFile(payload.file);
    const axios = authAxios();
    await axios.post("driver_plannings", {
      driverPlannings,
      companyUuid: payload.companyUuid,
    });
  },
);

export const driverPlanningsSlice = createSlice({
  name: "driverPlannings",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetPlanningUploadStatus(state) {
      state.driverPlanningsStatus = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadDriverPlanningsFileThunk.pending, (state) => {
        state.driverPlanningsStatus = "processing";
      })
      .addCase(uploadDriverPlanningsFileThunk.fulfilled, (state) => {
        state.driverPlanningsStatus = "success";
      })
      .addCase(uploadDriverPlanningsFileThunk.rejected, (state) => {
        state.driverPlanningsStatus = "failed";
      });
  },
});

export const selectDriverPlanningsStatus = (state: RootState) =>
  state.driverPlannings.driverPlanningsStatus;

export default driverPlanningsSlice.reducer;
