import { TotalType } from "@qivia/ui/src/designSystem/components/Charts/CardChart";
import { GetTransactionsAmountsPerPeriodType } from "../../transactions/transactionsAPI";

export const extractLineChartData = (props: {
  transactions: GetTransactionsAmountsPerPeriodType;
  totalType: TotalType;
  dataPath: keyof Pick<
    GetTransactionsAmountsPerPeriodType[TotalType],
    "averagePricePerLiter" | "totalCarbonEmission"
  >;
  formatter: (date: Date) => string;
}) => {
  const { transactions, totalType, dataPath, formatter } = props;
  if (!transactions) return [];
  if (!totalType || !transactions[totalType]) return [];

  return transactions[totalType][dataPath].map((elem) => ({
    date: formatter(new Date(elem.date)),
    value: elem.value,
  }));
};

export const extractMultipleLineChartData = (props: {
  transactions: GetTransactionsAmountsPerPeriodType;
  totalType: TotalType;
  dataPath: keyof Pick<
    GetTransactionsAmountsPerPeriodType[TotalType],
    "averagePricePerLiterPerFuel"
  >;
  formatter: (date: Date) => string;
}) => {
  const { transactions, totalType, dataPath, formatter } = props;
  if (!transactions) return [];
  if (!totalType || !transactions[totalType]) return [];

  return transactions[totalType][dataPath].map((elem) => {
    const data = elem.data.map((e) => ({
      date: formatter(new Date(e.date)),
      value: e.value,
    }));
    return {
      name: elem.fuel,
      data: data,
    };
  });
};
