import { Assets, AddBusinessOutlined } from "@qivia/ui";

export const merchantIcons = {
  SHELL: <Assets.Shell />,
  BP: <Assets.Bp />,
  ENI: <Assets.Eni />,
  TOTAL_ENERGIES: <Assets.Total />,
  AVIA: <Assets.Avia />,
  ESSO: <Assets.Esso />,
  CASINO: <Assets.Casino />,
  CARREFOUR: <Assets.Carrefour />,
  INTERMARCHE: <Assets.Intermarche />,
  AUCHAN: <Assets.Auchan />,
  SYSTEME_U: <Assets.SystemeU />,
  LECLERC: <Assets.Leclerc />,
  OTHER: <AddBusinessOutlined />,
};
