import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authAxios, QueryStatus } from "../../../utils";
import { RootState } from "../../../redux/store";
import {
  getDriverHistoryGroupByVehicle,
  getAnalyticsVehiclesGroupByVehicle,
  getRowsDetails,
  getTripsDetailsWithDrivers,
  getTripsDetailsGroupedByDrivers,
} from "./lib";
import {
  DriverPlanningByDay,
  GetTrips,
  Trips,
  TripsByDayApi,
} from "./tripsApi";

export interface FleetState {
  tripsByDayStatus: QueryStatus;
  tripsByDay: Trips[];
  planningsByDayStatus: QueryStatus;
  planningsByDay: DriverPlanningByDay[];
}

const initialState: FleetState = {
  tripsByDayStatus: "idle",
  tripsByDay: [],
  planningsByDayStatus: "idle",
  planningsByDay: [],
};

export const tripsAsync = createAsyncThunk(
  "trips/call",
  async ({ companyUuid, vehicles, driversHistory }: GetTrips) => {
    const axios = authAxios();
    const response = await axios.get<TripsByDayApi[]>(
      `trips/company/${companyUuid}/vehicles`,
      {
        params: { vehiclesUuid: vehicles.map((av) => av.uuid) },
        paramsSerializer: { indexes: null },
      },
    );

    const driverHistoryGroupByVehicle =
      getDriverHistoryGroupByVehicle(driversHistory);
    const analyticsVehicleGroupByVehicle =
      getAnalyticsVehiclesGroupByVehicle(vehicles);

    try {
      const result = response.data.flatMap((tripRow) => {
        const analyticVehicle =
          analyticsVehicleGroupByVehicle[tripRow.vehicleUuid];

        if (!analyticVehicle) return [];

        const filteredDriversByDay =
          driverHistoryGroupByVehicle[tripRow.vehicleUuid] ?? [];

        const tripsWithDriver = getTripsDetailsWithDrivers(
          tripRow.trips,
          filteredDriversByDay,
        );
        const tripsByDayVehicleDriver =
          getTripsDetailsGroupedByDrivers(tripsWithDriver);

        return tripsByDayVehicleDriver.map((twd) =>
          getRowsDetails(tripRow, twd, analyticVehicle),
        );
      });

      return result;
    } catch (e) {
      return [];
    }
  },
);

export const planningsAsync = createAsyncThunk(
  "planningsAsync/call",
  async (payload: { companyUuid: string }) => {
    const axios = authAxios();
    const response = await axios.get<DriverPlanningByDay[]>(
      `driver_plannings/company/${payload.companyUuid}`,
    );
    return response.data;
  },
);

export const tripSlice = createSlice({
  name: "trip",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetTripsListStatus(state) {
      state.tripsByDayStatus = "idle";
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(tripsAsync.pending, (state) => {
        state.tripsByDayStatus = "processing";
      })
      .addCase(tripsAsync.fulfilled, (state, action) => {
        state.tripsByDay = action.payload;
        state.tripsByDayStatus = "success";
      })
      .addCase(tripsAsync.rejected, (state) => {
        state.tripsByDayStatus = "failed";
      })
      .addCase(planningsAsync.pending, (state) => {
        state.planningsByDayStatus = "processing";
      })
      .addCase(planningsAsync.fulfilled, (state, action) => {
        state.planningsByDay = action.payload;
        state.planningsByDayStatus = "success";
      })
      .addCase(planningsAsync.rejected, (state) => {
        state.planningsByDayStatus = "failed";
      });
  },
});

export const selectTripsByDayList = (state: RootState) =>
  state.trips.tripsByDay;
export const selectTripsByDayStatus = (state: RootState) =>
  state.trips.tripsByDayStatus;
export const selectPlanningsByDayList = (state: RootState) =>
  state.trips.planningsByDay;
export const selectPlanningsByDayStatus = (state: RootState) =>
  state.trips.planningsByDayStatus;

export default tripSlice.reducer;
