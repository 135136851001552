import styled from "styled-components";
import { Spacer } from "./Spacer";
import { SelectDS } from "./Select";
import { t } from "i18next";

interface SelectFuelProps {
  onChange: (value: string | null) => void;
  value: string | null;
  disabled?: boolean;
  error?: string;
  placeholder?: string;
  onlyFuel?: boolean;
  label?: string;
  withoutSpacerBottom?: boolean;
  menuListMaxHeight: number;
}

interface Option<T extends string> {
  readonly value: T;
  readonly label: string;
  readonly disabled?: boolean;
}

export const SelectFuel = (props: SelectFuelProps) => {
  const handleChange = (selectedOption: Option<string> | null) => {
    props.onChange(selectedOption?.value ?? null);
  };

  const options: Option<string>[] = [];
  [
    "SP95",
    "SP95_E10",
    "SP98",
    "Diesel_B7",
    "Diesel_B10",
    "Diesel_B30",
    "GPL",
    "E85",
    "ED95",
    "GNV",
    "GNC",
    "GNL",
    "AdBlue",
    "electric",
    "wash",
    "parking",
    "toll",
  ].map((fuel) => {
    if (
      props.onlyFuel &&
      (fuel === "wash" ||
        fuel === "toll" ||
        fuel === "parking" ||
        fuel === "electric")
    ) {
      return;
    }
    options.push({ value: fuel, label: t(`select.${fuel}`) });
  });
  const valueOption = options.find((option) => option.value === props.value);

  return (
    <SelectContainer>
      <SelectDS
        label={props.label ?? ""}
        onChange={handleChange}
        placeholder={props.placeholder}
        options={options}
        value={valueOption}
        disabled={props.disabled}
        error={props.error}
        allWidth={true}
        withoutSpacerBottom
        menuListMaxHeight={props.menuListMaxHeight}
      />
      <Spacer y={1.5} />
    </SelectContainer>
  );
};

const SelectContainer = styled.div`
  width: 100%;
`;
