import { Spacer, ToastContainer, triggerToast, PageDS } from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  loadCompanyAsync,
  selectCompany,
  selectCurrentCompanyUuid,
} from "./../homeSlice";
import { DashboardOnboardCta } from "./dashboardOnboardCta";
import { selectRuleStatus, rulesSlice } from "./../rules/rulesSlice";
import {
  companiesSlice,
  selectCompanyCreationStatus,
} from "../companies/companiesSlice";
import { DashboardCharts } from "./dashboardCharts";
import { DashboardTop } from "./dashboardTop";

export const Dashboard = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const company = useAppSelector(selectCompany);
  const currentCompany = useAppSelector(selectCurrentCompanyUuid);
  const companyCreationStatus = useAppSelector(selectCompanyCreationStatus);
  const [isOnboardingFinished, setIsOnboardingFinished] =
    useState<boolean>(true);
  const createRuleStatus = useAppSelector(selectRuleStatus);

  useEffect(() => {
    if (createRuleStatus === "success") {
      triggerToast(t("rules.createRule.dashboard.success") || "", "valid");
      dispatch(rulesSlice.actions.resetRuleCreationStatus());
    } else if (createRuleStatus === "failed") {
      triggerToast(t("rules.createRule.failure") || "", "error");
      dispatch(rulesSlice.actions.resetRuleCreationStatus());
    }
  }, [createRuleStatus, dispatch, t]);

  useEffect(() => {
    if (company && company.providerOnboardingStatus === "FINISHED") {
      setIsOnboardingFinished(true);
    } else if (company && company.providerOnboardingStatus !== "FINISHED") {
      setIsOnboardingFinished(false);
    }
  }, [company]);

  useEffect(() => {
    if (companyCreationStatus === "success" && company) {
      triggerToast(
        t("dashboard.createCompany.success", { entity: company.name }) || "",
        "valid",
      );
      void dispatch(companiesSlice.actions.resetCompanyCreationStatus());
    } else if (companyCreationStatus === "failed" && currentCompany) {
      triggerToast(t("dashboard.createCompany.failure") || "", "error");
      void dispatch(loadCompanyAsync({ uuid: currentCompany }));
      void dispatch(companiesSlice.actions.resetCompanyCreationStatus());
    }
  }, [
    createRuleStatus,
    t,
    company,
    companyCreationStatus,
    dispatch,
    currentCompany,
  ]);

  return (
    <PageDS title={""} toaster={<ToastContainer />}>
      <>
        {!isOnboardingFinished && (
          <>
            <DashboardOnboardCta />
            <Spacer y={2} />
          </>
        )}
      </>
      <DashboardTop />
      <>
        <Spacer y={3} />
        {isOnboardingFinished && <DashboardCharts />}
      </>
      <ToastContainer />
      <Spacer y={2} x={10} />
    </PageDS>
  );
};
