import styled from "styled-components";
import { colors } from "../../../styles/figmaColors";
import { Spacer } from "../Spacer";
import { typographies } from "../../../styles/figmaTypographies";
import { SelectDS } from "../Select";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextCapitalized } from "../text/TextCapitalized";

export type TotalType = "month" | "quarter" | "year";

interface CardChartProps {
  title: string;
  children: (totalType: TotalType) => JSX.Element | string;
  isLineChart?: boolean;
  isVisible: boolean;
}

export const CardChart = (props: CardChartProps) => {
  const { t } = useTranslation();

  const options = useMemo(
    () => [
      {
        value: "month" as TotalType,
        label: t("dashboard.charts.title.pricePerLiter.monthly"),
      },
      {
        value: "quarter" as TotalType,
        label: t("dashboard.charts.title.pricePerLiter.quarterly"),
      },
      {
        value: "year" as TotalType,
        label: t("dashboard.charts.title.pricePerLiter.yearly"),
      },
    ],
    [t],
  );

  const [totalType, setTotalType] = useState<TotalType>("month");

  const valueTotalType = useMemo(
    () => options.find((option) => option.value === totalType),
    [options, totalType],
  );

  return (
    props.isVisible && (
      <StyledCard>
        <Spacer y={2} />
        <StyledRow>
          <Spacer x={2} />
          <StyledCardTitle>
            <TextCapitalized>{props.title}</TextCapitalized>
          </StyledCardTitle>
          <Spacer y={0.5} />
        </StyledRow>
        <Spacer y={0.5} />
        <StyledRow>
          <Spacer x={2} />
          <SelectDS
            options={options}
            placeholder={t("dashboard.charts.title.pricePerLiter.placeholder")}
            onChange={(e) => setTotalType(e ? e.value : "month")}
            label={""}
            value={valueTotalType}
            withoutSpacerBottom
          />
        </StyledRow>
        {props.isLineChart && <Spacer y={1} />}
        <Spacer y={0.5} />
        <StyledChartRow>
          {!props.isLineChart && <Spacer x={2} />}
          {props.children(totalType)}
          <Spacer x={2} />
        </StyledChartRow>
        <Spacer y={0.5} />
      </StyledCard>
    )
  );
};

const StyledRow = styled.div`
  display: flex;
`;
const StyledChartRow = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 32rem;
  height: 22rem;
  background: ${colors["colors/surfaces/background/background_level0"]};
  border-radius: 0.5rem;
  border: 0.0625rem solid ${colors["colors/borders/cards/cards"]};
`;

const StyledCardTitle = styled.div`
  ${typographies["Header/H4"]}
  color: ${colors["colors/text/black"]};
`;
