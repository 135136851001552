import { Money } from "@qivia/core";
import { CategoryType } from "../rules/rulesAPI";

export type TransactionStatus =
  | "ACCEPTED"
  | "REJECTED"
  | "SUSPICIOUS"
  | "PENDING_ADJUSTEMENT"
  | "PENDING_CAPTURE"
  | "PENDING_CONFIRMATION"
  | "CANCELED";

export type PublicTransactionStatus =
  | "ACCEPTED"
  | "REJECTED"
  | "SUSPICIOUS"
  | "PENDING"
  | "CANCELED";

export type SupportingDocumentStatus = "ACCEPTED" | "MISSING" | "";

export type PublicTransactionType = {
  uuid: string;
  amount: Money;
  executionDate: string;
  registrationNumber: string;
  merchantName: string;
  merchantCity: string;
  mileage: number | null;
  quantity: string;
  consumption: string;
  cardLast4Digits: string;
  driverName: string;
  driverPhone: string;
  supportingDocumentUuid?: string;
  status: TransactionStatus;
  vehicleReference: string;
  driverLastName: string;
  driver: string;
  ruleName: string;
  rule: string;
  vehicleUuid: string;
  card: string;
  category: CategoryType;
  fuel: string;
  carbonEmission: number;
  areSupportingDocumentsMandatory: boolean;
  merchantGroup: MerchantGroups | null;
};

export type PublicAuthorizationType = {
  uuid: string;
  executionDate: string;
  amount: number;
  merchantName: string;
  merchantCode: string;
  merchantCity: string;
  reasonsRefused: ReasonsRefusedEnum[];
  registrationNumber: string | null;
  cardLast4Digits: string;
  driverFirstName: string | null;
  driverLastName: string | null;
  driver: string | null;
  ruleName: string;
  ruleUuid: string;
  vehicleUuid: string;
  cardUuid: string;
  vehicleReference: string;
  category: CategoryType | null;
  merchantGroup: MerchantGroups | null;
};

export type PublicTransactionDisplayed = {
  date: string;
  time: string;
  amount: number | null;
  merchantName: string;
  merchantGroup: MerchantGroups | null;
  category: CategoryType | null;
  driverName: string | null;
  status: PublicTransactionStatus;
  supportingDocumentStatus: SupportingDocumentStatus;
  mileage: number | null;
};

export type BadgeTransactionDisplayedApi = {
  uuid: string;
  executionDate: string;
  badgeNumber: string;
  category: "TOLL" | "PARKING";
  amount: number | null;
  registrationNumber: string;
  reference: string | null;
  type: "EXPENSE" | "REFUND";
  entranceStationName: string;
  exitStationName: string;
};

export type PublicBadgeTransaction = {
  uuid: string;
  date: string;
  badgeNumber: string;
  category: "TOLL" | "PARKING";
  amount: number | null;
  vehicleInfo: string;
  stationsInfo: string;
  type: "EXPENSE" | "REFUND";
  entranceStationName: string;
  exitStationName: string;
};

export type PublicBadgeTransactionDisplayed = {
  date: string;
  badgeNumber: string;
  category: "TOLL" | "PARKING";
  amount: number | null;
  vehicleInfo: string;
  stationsInfo: string;
};

export type PublicTransactionsPropsType = {
  uuid: string;
  amount: number | null;
  registrationNumber: string | null;
  merchantName: string;
  merchantCity: string;
  cardLast4Digits: string;
  driverName: string | null;
  driverPhone?: string;
  status: PublicTransactionStatus;
  date: string;
  time: string;
  reasonsRefused?: ReasonsRefusedEnum[] | null;
  mileage: number | null;
  consumption?: string;
  supportingDocumentUuid?: string;
  vehicleReference: string;
  supportingDocumentStatus: SupportingDocumentStatus;
  driverLastName: string | null;
  driver: string | null;
  ruleName: string;
  ruleUuid: string;
  vehicleUuid: string;
  cardUuid: string;
  category: CategoryType | null;
  fuel?: string;
  quantity?: string;
  carbonEmission?: number;
  areSupportingDocumentsMandatory?: boolean;
  merchantGroup: MerchantGroups | null;
};

export type GetSupportingDocumentType = {
  presignedUrl: string;
  fileName: string;
};

export type SendSmsPayload = {
  driverPhone: string;
  driverName: string;
  transactionUuid: string;
};

export type TotalAmountPerCategoryType = {
  category: CategoryType;
  amount: number;
};

export type TotalAmountPerMerchantGroupType = {
  merchantGroup: MerchantGroups | null;
  amount: number;
};

export type DateValueType = {
  date: string;
  value: number;
};

export type AveragePricePerLiterPerFuelType = {
  fuel: string;
  data: DateValueType[];
};

export type GetTransactionsAmountsPerPeriodType = {
  year: {
    categoriesAmounts: TotalAmountPerCategoryType[];
    averagePricePerLiter: DateValueType[];
    totalCarbonEmission: DateValueType[];
    merchantGroupsAmounts: TotalAmountPerMerchantGroupType[];
    averagePricePerLiterPerFuel: AveragePricePerLiterPerFuelType[];
  };
  month: {
    categoriesAmounts: TotalAmountPerCategoryType[];
    averagePricePerLiter: DateValueType[];
    totalCarbonEmission: DateValueType[];
    merchantGroupsAmounts: TotalAmountPerMerchantGroupType[];
    averagePricePerLiterPerFuel: AveragePricePerLiterPerFuelType[];
  };
  quarter: {
    categoriesAmounts: TotalAmountPerCategoryType[];
    averagePricePerLiter: DateValueType[];
    totalCarbonEmission: DateValueType[];
    merchantGroupsAmounts: TotalAmountPerMerchantGroupType[];
    averagePricePerLiterPerFuel: AveragePricePerLiterPerFuelType[];
  };
};

export type ReasonsRefusedEnum =
  | "NO_DRIVER"
  | "NO_FUNDS"
  | "EXCEEDED_LIMITS"
  | "WRONG_TIME"
  | "WRONG_DAY"
  | "WRONG_PLACE"
  | "WRONG_CURRENCY"
  | "UNAUTHORIZE_MERCHANT"
  | "INACTIVE_CARD"
  | "IN_OPPOSITION_CARD"
  | "EARLY_RETRY"
  | "ERROR"
  | "STRIPE_DASHBOARD"
  | "SUSPECTED_FRAUD"
  | "NO_WEBHOOK_RESPONSE"
  | "ROTATION_REQUEST_TRANSACTIONS_EXCEEDED"
  | "ALREADY_REJECTED_ROTATION_REQUEST"
  | "OTHER";

export type MerchantGroups =
  | "SHELL"
  | "BP"
  | "ENI"
  | "TOTAL_ENERGIES"
  | "AVIA"
  | "ESSO"
  | "CASINO"
  | "CARREFOUR"
  | "INTERMARCHE"
  | "AUCHAN"
  | "SYSTEME_U"
  | "LECLERC";

export const MerchantGroupsList = [
  "SHELL",
  "BP",
  "ENI",
  "TOTAL_ENERGIES",
  "AVIA",
  "ESSO",
  "CASINO",
  "CARREFOUR",
  "INTERMARCHE",
  "AUCHAN",
  "SYSTEME_U",
  "LECLERC",
];
