import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  ButtonDS,
  DownloadOutlined,
  EmptyTableComponent,
  LaunchPage,
  SortByAlphabeticalOrderType,
  SortByDateType,
  SortByNumericType,
  SortingDirectionType,
  Spacer,
  TableDS,
  TextCapitalized,
  dateFormatterDayMonthYearLong2Digits,
  sortByAlphabeticalOrder,
  sortByDateTime,
  sortByNumeric,
  triggerToast,
} from "@qivia/ui";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import { selectCompany } from "../homeSlice";
import { PublicAsfInvoices, PublicAsfInvoicesDisplayed } from "./billingsAPI";
import {
  asfInvoicesExportAsync,
  asfInvoicesListAsync,
  documentDownloadAsync,
  selecAsfInvoicesExportLink,
  selectAsfInvoicesExportStatus,
  selectAsfInvoicesList,
  selectAsfInvoicesListStatus,
} from "./billingsSlice";

export const AsfInvoicesTab = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const params = useParams();
  const company = useAppSelector(selectCompany);
  const navigate = useNavigate();

  const [sortingDirection, setSortingDirection] =
    useState<SortingDirectionType>("desc");

  const [asfInvoicesListSorted, setAsfInvoicesListSorted] = useState<
    PublicAsfInvoicesDisplayed[] | null
  >([]);
  const asfInvoicesListStatus = useAppSelector(selectAsfInvoicesListStatus);
  const asfInvoicesListInit: PublicAsfInvoices[] = useAppSelector(
    selectAsfInvoicesList,
  );
  const asfInvoicesExportLink = useAppSelector(selecAsfInvoicesExportLink);
  const asfInvoicesExportStatus = useAppSelector(selectAsfInvoicesExportStatus);

  const asfInvoicesList: PublicAsfInvoices[] = useMemo(() => {
    return sortByDateTime(asfInvoicesListInit, "desc");
  }, [asfInvoicesListInit]);

  useEffect(() => {
    if (company && !company.canOrderBadges && params.tab === "asfInvoices") {
      navigate(`/home`);
    }
  }, [company, navigate, params.tab]);

  useEffect(() => {
    if (company) {
      void dispatch(asfInvoicesListAsync(company.uuid));
    }
  }, [dispatch, company]);

  useEffect(() => {
    if (asfInvoicesExportStatus === "success") {
      triggerToast(t("billings.asfInvoices.upload.success") || "", "valid");
    } else if (asfInvoicesExportStatus === "failed") {
      triggerToast(t("billings.asfInvoices.upload.failure") || "", "error");
    }
  }, [asfInvoicesExportStatus, t]);

  useEffect(() => {
    if (asfInvoicesList && asfInvoicesListStatus === "success") {
      const asfInvoicesListSorted = sortByDateTime(asfInvoicesList, "desc");
      setAsfInvoicesListSorted(asfInvoicesListSorted);
    }
  }, [asfInvoicesListStatus, asfInvoicesList]);

  useEffect(() => {
    if (asfInvoicesExportLink.presignedUrl && asfInvoicesExportLink.fileName) {
      void dispatch(documentDownloadAsync(asfInvoicesExportLink));
    }
  }, [asfInvoicesExportLink, dispatch]);

  const sortByAsfInvoicesDate = useCallback(
    (column?: string) => {
      if (!asfInvoicesListSorted) return;
      const asfInvoicesDateTimeSorted = sortByDateTime(
        asfInvoicesListSorted,
        sortingDirection,
        column as keyof SortByDateType,
      );
      setAsfInvoicesListSorted(asfInvoicesDateTimeSorted);
      setSortingDirection(sortingDirection === "asc" ? "desc" : "asc");
    },
    [asfInvoicesListSorted, sortingDirection],
  );

  const sortAsfInvoicesByAlphabeticalOrder = useCallback(
    (column: string) => {
      if (!asfInvoicesListSorted) return;
      const vehiclesSorted = sortByAlphabeticalOrder(
        asfInvoicesListSorted,
        sortingDirection,
        column as keyof SortByAlphabeticalOrderType,
      );
      setAsfInvoicesListSorted(vehiclesSorted);
      setSortingDirection(sortingDirection === "asc" ? "desc" : "asc");
    },
    [asfInvoicesListSorted, sortingDirection],
  );

  const sortAsfInvoicesByAmount = useCallback(
    (column: string) => {
      if (!asfInvoicesListSorted) return;

      const transactionsAmountSorted = sortByNumeric(
        asfInvoicesListSorted,
        sortingDirection,
        column as keyof SortByNumericType,
      );

      setAsfInvoicesListSorted(transactionsAmountSorted);
      setSortingDirection(sortingDirection === "asc" ? "desc" : "asc");
    },
    [asfInvoicesListSorted, sortingDirection],
  );

  const headers = {
    beginningDate: {
      text: t("billings.asfInvoices.table.column.beginningDate"),
      sortAction: () => sortByAsfInvoicesDate("beginningDate"),
    },
    endDate: {
      text: t("billings.asfInvoices.table.column.endDate"),
      sortAction: () => sortByAsfInvoicesDate("endDate"),
    },
    transactionsAmountTtc: {
      text: t("billings.asfInvoices.table.column.transactionsAmountTtc"),
      sortAction: () => sortAsfInvoicesByAmount("transactionsAmountTtc"),
    },
    transactionsNumber: {
      text: t("billings.asfInvoices.table.column.transactionsNumber"),
      sortAction: () => sortAsfInvoicesByAmount("transactionsNumber"),
    },
    fileName: {
      text: t("billings.asfInvoices.table.column.fileName"),
      sortAction: () => sortAsfInvoicesByAlphabeticalOrder("fileName"),
    },
    createdAt: {
      text: t("billings.asfInvoices.table.column.createdAt"),
      sortAction: () => sortByAsfInvoicesDate("createdAt"),
    },
    bucketFileName: {
      text: t("billings.asfInvoices.table.column.download"),
      sortAction: () => sortAsfInvoicesByAlphabeticalOrder("bucketFileName"),
    },
  };

  if (params.tab !== "asfInvoices") {
    return;
  }
  if (!asfInvoicesListSorted) return <LaunchPage hasBorderRadius={true} />;

  const render =
    (row: PublicAsfInvoicesDisplayed) =>
    (key: keyof PublicAsfInvoicesDisplayed) => {
      switch (key) {
        case "beginningDate":
        case "endDate":
        case "createdAt":
          return (
            <TextCapitalized>
              {dateFormatterDayMonthYearLong2Digits(new Date(row[key]))}
            </TextCapitalized>
          );
        case "transactionsAmountTtc":
          return (
            <StyledAmount>
              {Number(row[key] / 100).toString() + " € TTC"}
              <Spacer x={0.75} />
            </StyledAmount>
          );
        case "bucketFileName":
          return (
            <ButtonDS
              sizeButton={"S"}
              format={"hug"}
              buttonType={"secondary"}
              singleIcon={{ icon: <DownloadOutlined />, size: "S" }}
              onClick={() => {
                void dispatch(
                  asfInvoicesExportAsync({
                    bucketFileName: row[key] ?? "",
                    fileName: row["fileName"],
                  }),
                );
              }}
            />
          );
        default:
          return row[key];
      }
    };

  return (
    <TableDS<keyof PublicAsfInvoicesDisplayed, PublicAsfInvoicesDisplayed>
      data={asfInvoicesListSorted}
      headers={headers}
      render={render}
      emptyContent={<EmptyTableComponent pageName={"billings.asfInvoices"} />}
    />
  );
};

const StyledAmount = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
