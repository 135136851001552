import styled from "styled-components";
import { Spacer } from "./Spacer";
import {
  dateFormatterHourMinute,
  dateFormatterWeekDayDayMonthYear,
} from "../../format";
import { TextCapitalized } from "./text/TextCapitalized";
import { colors } from "../../styles/figmaColors";
import { typographies } from "../../styles/figmaTypographies";
import { useTranslation } from "react-i18next";
import { Badge } from "./Tag/Badge";

export interface Trip {
  secondsDuration: number | null;
  distance: number | null;
  ecoDrivingScore: number | null;
  startDate: string;
  endDate: string;
}

export interface DayTripDetailsProps {
  date: Date | null;
  vehicleModel: string | null;
  trips: Trip[];
}

export const DayTripDetails = (props: DayTripDetailsProps) => {
  const { date, trips, vehicleModel } = props;

  return (
    <StyledDayTripDetailsContainer>
      <Spacer x={1.5} />
      <StyledColumn>
        <Spacer y={1.5} />
        <StyledTitle>
          <TextCapitalized>
            {date && dateFormatterWeekDayDayMonthYear(date)}
          </TextCapitalized>
        </StyledTitle>
        {trips.length !== 0 && (
          <>
            <Spacer y={2.5} />
            <StyledOverflow>
              {trips.map((t, index) => {
                const tripDisplay = {
                  distance: t.distance,
                  duration: t.secondsDuration
                    ? Math.round(t.secondsDuration / 60)
                    : null,
                  ecoDrivingScore: t.ecoDrivingScore
                    ? Number(t.ecoDrivingScore.toFixed(0))
                    : null,
                };
                return (
                  <div key={index + "dayTrip"}>
                    {index !== 0 && <StyledLineGrey />}
                    <StyledFlex>
                      <DatesBarTrip
                        startDate={t.startDate}
                        endDate={t.endDate}
                      />
                      <Spacer x={2.5} />
                      <TripDetails
                        tripDisplay={tripDisplay}
                        vehicleModel={vehicleModel}
                      />
                    </StyledFlex>
                  </div>
                );
              })}
            </StyledOverflow>
          </>
        )}
        <Spacer y={1.5} />
      </StyledColumn>
      <Spacer x={1.5} />
    </StyledDayTripDetailsContainer>
  );
};

export interface TripOptionsDisplay {
  distance: number | null;
  duration: number | null;
  ecoDrivingScore: number | null;
}
export interface TripDetailsProps {
  vehicleModel: string | null;
  tripDisplay: TripOptionsDisplay;
}
const tripDetailsOptions = ["distance", "duration", "ecoDrivingScore"];

export const TripDetails = (props: TripDetailsProps) => {
  const { t } = useTranslation();
  const { tripDisplay, vehicleModel } = props;

  return (
    <StyledTripContainer>
      <Spacer x={1.5} />
      <StyledColumn>
        <Spacer y={1.5} />
        {vehicleModel && (
          <>
            <StyledVehicleModel>{vehicleModel}</StyledVehicleModel>
            <Spacer y={1} />
          </>
        )}
        <StyledFlex>
          {tripDetailsOptions.map((option, index) => (
            <div key={index + "trip"}>
              {!!tripDisplay[option as keyof TripOptionsDisplay] && (
                <StyledFlex>
                  <StyledTripDetail>
                    <TextCapitalized>
                      {t(`trips.details.${option}.title`)}
                    </TextCapitalized>
                    <Spacer y={0.5} />
                    <Badge
                      text={
                        <StyledFlex>
                          {tripDisplay[option as keyof TripOptionsDisplay]}
                          <Spacer x={0.25} />
                          {t(`trips.details.${option}.units`)}
                        </StyledFlex>
                      }
                      backgroundColor={
                        option === "ecoDrivingScore"
                          ? colors["colors/system/success/success_veryLight"]
                          : colors[
                              "colors/surfaces/background/background_level0"
                            ]
                      }
                      textColor={colors["colors/text/black"]}
                      allWidth
                      typo={typographies["Body/XS"]}
                    />
                  </StyledTripDetail>
                  <Spacer x={1.5} />
                </StyledFlex>
              )}
            </div>
          ))}
        </StyledFlex>
        <Spacer y={1.5} />
      </StyledColumn>
      <Spacer x={1.5} />
    </StyledTripContainer>
  );
};

export interface DatesBarTripProps {
  startDate: string;
  endDate: string;
}

export const DatesBarTrip = (props: DatesBarTripProps) => {
  return (
    <>
      <StyledDates>
        <div>
          {dateFormatterHourMinute(new Date(props.startDate)).replaceAll(
            ":",
            "h",
          )}
        </div>
        <div>
          {dateFormatterHourMinute(new Date(props.endDate)).replaceAll(
            ":",
            "h",
          )}
        </div>
      </StyledDates>
      <Spacer x={0.5} />
      <StyledBarDates>
        <StyledRound />
        <StyledLineGreen />
        <StyledRound />
      </StyledBarDates>
    </>
  );
};

const StyledDates = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 3rem;
  justify-content: space-between;
`;

const StyledLineGreen = styled.div`
  height: 100%;
  border-right: 1rem solid ${colors["colors/system/success/success_veryLight"]};
`;

const StyledRound = styled.div`
  min-height: 1rem;
  min-width: 1rem;
  border-radius: 1rem;
  background: ${colors["colors/system/success/success_normal"]};
`;

const StyledBarDates = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors["colors/surfaces/background/background_level1"]};
`;

const StyledLineGrey = styled.div`
  height: 4rem;
  width: 4.5rem;
  border-right: 1rem solid
    ${colors["colors/surfaces/background/background_level1"]};
`;

const StyledFlex = styled.div`
  display: flex;
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledDayTripDetailsContainer = styled.div`
  display: flex;
  height: 100%;
  ${typographies["Body/S"]};
`;

const StyledOverflow = styled.div`
  overflow: auto;
`;

const StyledTripContainer = styled.div`
  display: flex;
  width: 100%;
  background-color: ${colors["colors/borders/cells/cells"]};
  border-radius: 0.5rem;
`;

const StyledTripDetail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledTitle = styled.div`
  ${typographies["Title/H1"]};
`;
const StyledVehicleModel = styled.div`
  ${typographies["Header/H2"]};
`;
